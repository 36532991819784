import { useEffect, useState } from "react";

import useQAParam from "./useQAParam";

function useDeliveryEstimate(deliveryTimeframe: string) {
  const [deliveryDate, setDeliveryDate] = useState<string | null>(null);
  const isQAParamPresent  = useQAParam("deliveryDate");

  useEffect(() => {
    // This is out a/b test feature switch. Set by Sitespect.
    const hasTestDeliveryDate =
      document.getElementsByClassName("test-delivery-date").length > 0 || isQAParamPresent;

    if (hasTestDeliveryDate) {
      // If a day rage is set (e.g 1-3 days) get those two numbers and use the second.
      const deliveryRangePattern = /(\d+)\s*-\s*(\d+)|(\d+)/;
      const match = deliveryTimeframe
        .match(deliveryRangePattern)
        ?.filter((value) => value);
      if (match) {
        const maxDeliveryDays = parseInt(match[match.length - 1]);

        const today = new Date();
        let calculatedDeliveryDate = new Date(today);
        let remainingDays = maxDeliveryDays;

        // Iterate the expect date whilst checking if it hits a weekend.
        while (remainingDays > 0) {
          calculatedDeliveryDate.setDate(calculatedDeliveryDate.getDate() + 1);

          // Exclude the weekend.
          if (
            calculatedDeliveryDate.getDay() !== 0 &&
            calculatedDeliveryDate.getDay() !== 6
          ) {
            remainingDays--;
          }
        }

        const options = {
          weekday: "long",
          day: "numeric",
          month: "long",
        } as Intl.DateTimeFormatOptions;
        const formattedDeliveryDate: string = `Delivered by ${calculatedDeliveryDate.toLocaleDateString(
          "en-GB",
          options
        )}`;
        setDeliveryDate(formattedDeliveryDate);
      }
    }
  }, [deliveryTimeframe]);

  return deliveryDate || deliveryTimeframe;
}

export default useDeliveryEstimate;
