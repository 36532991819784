import React, { useState } from "react";
import styles from "./Delivery.module.scss";
import ShippingMethod from "./ShippingMethod";
import {
  IComplimentaryDetails,
  IShippingMethod,
  OrderEstimatedDelivery,
} from "../../utils/types/shipping";

interface IProps {
  shippingOptions: IShippingMethod[];
  currencyCode: string;
  warehouse: string;
  index: number;
  onClick: any;
  isComplimentary: boolean;
  complimentaryDetails?: IComplimentaryDetails;
}

export const ShippingMethods: React.FC<IProps> = ({
  shippingOptions,
  currencyCode,
  warehouse,
  index,
  onClick,
  isComplimentary,
  complimentaryDetails,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  if (shippingOptions === null) return null;

  type LabelValue = {
    Name: string;
    DeliveryTime: string;
  };

  function capitalize(s: string) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  const getLabel = (
    content: string,
    estimatedTime: OrderEstimatedDelivery
  ): LabelValue => {
    let split = content.split(/[()]/);

    if (split.length > 1) {
      return {
        Name: split[0],
        DeliveryTime: capitalize(split[1]),
      };
    } else {
      return {
        Name: content,
        DeliveryTime: "",
      };
    }
  };

  const handleOnClick = (index: number, id: number, warehouse: string) => {
    onClick(id, warehouse);
    setSelectedIndex(index);
  };

  const rate = (option: IShippingMethod): number => {
    const complimentaryPercentage =
      complimentaryDetails?.complimentaryPercentage ?? 100;
    const isOptionEligibleForComplimentary =
      (complimentaryDetails?.restrictedIds ?? []).length === 0 ||
      complimentaryDetails?.restrictedIds.includes(option.shipMethodId);
    let rate = option.rate ?? 0;
    if (isComplimentary && isOptionEligibleForComplimentary) {
      rate -= (complimentaryPercentage / 100) * rate;
    }
    return rate;
  };

  return (
    <div className={styles.deliveryOptionsContainer}>
      {shippingOptions
        .filter((option: IShippingMethod) => !option.isCnC)
        .map((option: IShippingMethod, i: number) => (
          <div key={i} className={styles.deliveryOption}>
            <ShippingMethod
              name={
                index === 0
                  ? "primaryShipMethodId"
                  : `secondaryShipMethodId_${index}`
              }
              label={
                getLabel(
                  option.localizedName?.toString() ?? option.name,
                  option.estimatedDelivery
                ).Name
              }
              deliveryTime={
                getLabel(
                  option.localizedName?.toString() ?? option.name,
                  option.estimatedDelivery
                ).DeliveryTime
              }
              checked={i === selectedIndex}
              rate={rate(option).toString()}
              type="radio"
              key={option.shipMethodId}
              currencyCode={currencyCode}
              shipmentId={option.shipMethodId}
              warehouse={warehouse}
              onClick={(id: number, warehouse: string) =>
                handleOnClick(i, id, warehouse)
              }
            />
          </div>
        ))}
    </div>
  );
};
export default ShippingMethods;
