import { PaymentRequestItem, Stripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useStateValue } from "../state-provider";
import { IItem } from "../types/products";
import { IShippingMethod } from "../types/shipping";

export const useIsMobilePayAvailable = (stripe: Stripe | null) => {
  const [{ profile, data }] = useStateValue();
  const [mobilePaymentRequest, setMobilePaymentRequest] = useState<any>();

  const getDisplayItems = (): PaymentRequestItem[] => {
    let displayItems: PaymentRequestItem[] = [];
    let orderItems: [] = data.summary.items;
    let shipping: [] = data.shipping.selectedShippingOptionMethods;
    if (orderItems && orderItems.length > 0) {
      orderItems.forEach((item: IItem) => {
        displayItems.push({
          label: `${item.name} x${item.quantity} `,
          amount: Math.round(parseFloat(item.price) * 100),
        });
      });
      if (shipping && shipping.length > 0) {
        shipping.forEach((method: IShippingMethod) => {
          let rate = method.rate ? Math.round(method.rate * 100) : 0;
          displayItems.push({ label: `${method.name} x1`, amount: rate });
        });
      }
    }
    return displayItems;
  };

  const isMobileDevice = (): boolean => {
    let userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return true; // Android device
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true; // iOS device
    }

    return false; // Not a mobile device
  };

  const getPaymentRequest = async () => {
    const amount = Math.round(data.orderTotal * 100);

    if (profile && stripe) {
      const pr = stripe.paymentRequest({
        country: data?.billingAddress?.countryCode,
        currency: profile.currencyCode.toLowerCase(),
        total: {
          label: "MOUNTAIN WAREHOUSE",
          amount: amount,
        },
        requestPayerName: false,
        requestPayerEmail: false,
        displayItems: getDisplayItems(),
      });

      let canMakePayment: Record<string, boolean> | null =
        await pr.canMakePayment();

      if (canMakePayment && pr && isMobileDevice()) {
        setMobilePaymentRequest(pr);
      }
    }
  };

  useEffect(() => {
    getPaymentRequest();
  }, [stripe]);

  return { mobilePaymentRequest };
};
