import { useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorComponent from "../../../../components/Forms/PlaceholderComponents/Error";
import LoadWidget from "../../../../components/LoadWidget";
import { PAYMENT_STAGE } from "../../../../utils/constants";
import { useStageRedirect } from "../../../../utils/hooks";
import { logError } from "../../../../utils/logger";
import { useStateValue } from "../../../../utils/state-provider";
import { Stages } from "../../../../utils/types";
import styles from "../Card/CardForm.module.scss";
import { IPaymentFormProps } from "../IPaymentFormProps";

const BlikForm = ({
  paymentIntentHandler,
  submitHandler,
  methods,
}: IPaymentFormProps) => {
  const stripe = useStripe();
  const [{ data }] = useStateValue();
  const [currentCode, setCurrentCode] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(""); // this is unused - add error back if you're going to use it

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { stageRedirect } = useStageRedirect();
  const { t } = useTranslation();
  const contactUs = process.env.REACT_APP_WEBSITE_HOST + t("paths:/help");

  useEffect(() => {
    submitHandler(() => handleSubmit);
  }, [currentCode]);

  const handleSubmit = async () => {
    if (!currentCode || currentCode.length < 6) {
      setFormError(true);
      setErrorMessage(t("error:BlikMissingCode"));
      return;
    }

    let isNumbersOnly = /^\d+$/.test(currentCode);
    if (!isNumbersOnly) {
      setFormError(true);
      setErrorMessage(t("error:BlikCodeNumbersOnly"));
      return;
    }

    if (!stripe) {
      return;
    }
    setIsLoading(true);
    setErrorMessage("");

    const additionalData = new Map<string, string>([
      ["blik_code", currentCode],
    ]);
    const request = { paymentMethod: { id: "", type: methods[0] } };

    try {
      let paymentIntentResponse = await paymentIntentHandler(
        request,
        additionalData
      );

      if (paymentIntentResponse?.data?.requiresAction) {
        const { response, error } = await (stripe as any).confirmBlikPayment(
          paymentIntentResponse?.data.paymentIntentClientSecret,
          {
            payment_method: {
              blik: {},
              billing_details: {
                address: {
                  city: data.billingAddress.city,
                  country: data.billingAddress.countryCode,
                  line1: data.billingAddress.addressLine1,
                  line2: data.billingAddress.addressLine2,
                  postal_code: data.billingAddress.postCode,
                  state: null,
                },
                email: data.owner.email,
                name:
                  data.billingAddress.firstName +
                  " " +
                  data.billingAddress.lastName,
              },
            },
            payment_method_options: {
              blik: {
                code: currentCode,
              },
            },
          }
        );
        if (error) {
          setFormError(true);
          setErrorMessage(error.message);
          // Show error to your customer.
          logError(error, "Blik Payment Error", PAYMENT_STAGE);
        } else if (response?.data?.last_payment_error) {
          setFormError(true);
          setErrorMessage(error.message);
          logError(error, "Blik Payment Error", PAYMENT_STAGE);
        } else {
          stageRedirect(Stages.Receipt);
        }
      }
    } catch (error) {
      logError(error, "Blik Payment Error", PAYMENT_STAGE);
      setFormError(true);
    }

    setIsLoading(false);
  };

  return (
    <>
      <div>
        <div className={styles.blikFormContainer}>
          <div className="input-wrap">
            <span>{t("BlikInstructions")}</span>
            <label htmlFor="code">{t("Code")}:</label>

            <input
              className={styles.blikInput}
              maxLength={6}
              value={currentCode}
              name="code"
              required
              onChange={(e) => {
                setCurrentCode(e.target.value);
              }}
            />
          </div>
        </div>

        {isLoading && <LoadWidget />}
      </div>
      <div>
        {formError && (
          <ErrorComponent
            hasBackground={true}
            contactUs={errorMessage ? "" : contactUs}
          >
            {errorMessage ? errorMessage : t("SomethingWentWrong")}
          </ErrorComponent>
        )}
      </div>
    </>
  );
};

export default BlikForm;
