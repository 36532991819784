import Axios from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { API_URL_WEBSITE } from "../../utils/constants";
import { validatePostcode } from "../../utils/helpers";
import { useStateValue } from "../../utils/state-provider";
import { Option } from "../../utils/types";
import { AddressFinder } from "../AddressFinder";
import AddressPicker from "../AddressPicker";
import ErrorMobile from "../Errors/ErrorMobile";
import CheckboxWrap from "../Forms/CheckboxWrap";
import InputWrap from "../Forms/InputWrap";
import Button from "../Forms/PlaceholderComponents/Button";
import SelectWrap from "../Forms/SelectWrap";
import styles from "./Address.module.scss";
import CountrySelect from "./CountrySelect";
import FocusError from "../Forms/FocusError";
import LinkButton from "../Forms/PlaceholderComponents/LinkButton";
import { IAddress, IGetAddressResponse } from "../../utils/types/address";

export type Props = {
  handleSubmit: (
    formValues: IAddress,
    actions: FormikHelpers<IAddress>
  ) => void;
  billingPage?: boolean;
  firstName: string | undefined;
  lastName: string | undefined;
  phone: string | undefined;
  savedAddresses: IGetAddressResponse[] | null;
  setFirstName: Dispatch<SetStateAction<string | undefined>>;
  setLastName: Dispatch<SetStateAction<string | undefined>>;
  setPhone: Dispatch<SetStateAction<string | undefined>>;
  title: string | undefined;
};

// noinspection JSIgnoredPromiseFromCall
export const Address = ({
  handleSubmit,
  billingPage = false,
  firstName,
  lastName,
  phone,
  savedAddresses,
  setFirstName,
  setLastName,
  setPhone,
  title,
}: Props) => {
  const { t } = useTranslation("address");
  const [pickedAddress, setPickedAddress] = useState<IAddress | null>(null);
  const [{ data, profile }] = useStateValue();
  const [userSavedAddresses, setUserSavedAddresses] = useState<
    IGetAddressResponse[]
  >([]);

  const [currentProvince, setCurrentProvince] = useState<Option>();
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false);
  const location = useLocation();

  const getLocation = () =>
    pickedAddress && pickedAddress?.countryCode
      ? pickedAddress?.countryCode
      : profile?.countryCode ?? "GB";

  const [availableCountries, setAvailableCountries] =
    useState<{ CountryCode: string; Name: string }[]>();

  const [allProvinces, setAllProvinces] = useState<
    { ProvinceCode: string; Name: string }[]
  >([]);
  const [availableProvinces, setAvailableProvinces] =
    useState<{ ProvinceCode: string; Name: string }[]>();

  const [currentCountryCode, setCurrentCountryCode] = useState("");

  useEffect(() => {
    const setAvailableCountriesWithProvinces = async (id: number) => {
      await Axios.get(`${API_URL_WEBSITE}address/countries/${id}`).then(
        function (response) {
          const countries = billingPage
            ? response.data.BillingCountries
            : response.data.DeliveryCountries;

          setAllProvinces(response.data.Provinces);
          setAvailableCountries(countries);
        }
      );
    };

    if (profile) setAvailableCountriesWithProvinces(profile.id);
  }, [profile]);

  useEffect(() => {
    if (allProvinces && currentCountryCode) {
      let provinces = allProvinces.filter(
        (x: any) => x.CountryCode === currentCountryCode
      );
      setAvailableProvinces(provinces);
    }
  }, [allProvinces, currentCountryCode]);

  useEffect(() => {
    if (data.isCnC || data.isCollectFromPoint) {
      if (location.pathname === t("paths:/billing-address"))
        setPickedAddress(data?.billingAddress);
    } else if (location.pathname === t("paths:/billing-address"))
      setPickedAddress(data?.billingAddress);
    else setPickedAddress(data?.deliveryAddress);
  }, []);

  useEffect(() => {
    // intersection of saved addresses and available
    if (availableCountries && savedAddresses) {
      try {
        setUserSavedAddresses(
          savedAddresses.filter(
            (address) =>
              availableCountries
                .map((country) => country.CountryCode)
                .findIndex((x) => x === address.CountryCode) !== -1
          )
        );
      } catch {}
    }
  }, [savedAddresses, availableCountries]);

  const handleAddressPicker = (e: any) => {
    if (e.value && savedAddresses) {
      const address = savedAddresses.find((item) => {
        return item.AddressId === +e.value.AddressId;
      });

      // MWNEW requires address1 but not street, this is a fix to try unwrap that for DE addresses
      if (address && currentCountryCode === "DE") {
        if (address.Address1 === address.Street) {
          address.Address1 = "";
        }
        address.Street ??= address.Address1;
      }

      const newAddress: IAddress = {
        firstName: address?.FirstName ?? "",
        lastName: address?.LastName ?? "",
        companyName: address?.Company ?? "",
        street: address?.Street ?? "",
        addressLine1: address?.Address1 ?? "",
        addressLine2: address?.Address2 ?? "",
        province: address?.CAProvince ?? address?.USState ?? "",
        postCode: address?.Postcode ?? "",
        city: address?.City ?? "",
        countryCode: address?.CountryCode ?? "",
        phone: address?.Phone ?? "",
        billingAddress: true,
      };

      // TODO - set currently selected country to something else
      // setCurrentCountryOption(newAddress.countryCode);
      // setCurrentCountryCode(newAddress.countryCode);
      setCurrentProvinceOption(newAddress.province);
      setPickedAddress(newAddress);
    }
  };

  const setSelectedProvince = (selectedProvince: Option) => {
    setCurrentProvince(selectedProvince);
  };

  const getDefaultProvince = () =>
    availableProvinces?.at(0)?.ProvinceCode ?? "";

  useEffect(() => {
    if (availableProvinces) {
      setCurrentProvince(
        availableProvinces
          .map((item) => {
            return { value: item.ProvinceCode, label: item.Name };
          })
          .at(0)
      );
    }
  }, [availableProvinces]);

  const initialValuesTemplate: IAddress = {
    firstName: "",
    lastName: "",
    companyName: "",
    street: "",
    addressLine1: "",
    addressLine2: "",
    province: getDefaultProvince() ?? "",
    postCode: "",
    city: "",
    countryCode: getLocation() ?? "",
    phone: "",
    billingAddress: true,
  };

  const initialValues: IAddress = pickedAddress
    ? { ...initialValuesTemplate, ...pickedAddress }
    : initialValuesTemplate;

  const validationSchemaShape = {
    firstName: Yup.string().trim().required(t("firstnameRequiredError")),
    lastName: Yup.string().trim().required(t("lastnameRequiredError")),
    countryCode: Yup.string().required(t("translations:FieldRequired")),
    phone: Yup.string()
      .trim()
      .required(t("phoneRequiredError"))
      .max(50, t("phoneInvalidLengthError")),
    street: Yup.string()
      .trim()
      .when("countryCode", ([countryCode], schema) => {
        if ((currentCountryCode ?? countryCode) === "DE") {
          return Yup.string().trim().required(t("translations:FieldRequired"));
        }
        return schema;
      }),
    companyName: Yup.string().max(50, t("maxLengthError", { max: 50 })),
    addressLine1: Yup.string()
      .trim()
      .when("countryCode", ([countryCode], schema) => {
        if ((currentCountryCode ?? countryCode) !== "DE") {
          return Yup.string()
            .trim()
            .required(t("addressLineRequiredError"))
            .max(50, t("maxLengthError", { max: 50 }));
        }
        return schema;
      }),
    addressLine2: Yup.string()
      .trim()
      .max(50, t("maxLengthError", { max: 50 })),
    province: Yup.string(),
    city: Yup.string().trim().required(t("cityRequiredError")),
    postCode: Yup.string()
      .trim()
      .required(t("postcodeRequiredError"))
      .max(15, t("maxLengthError", { max: 15 }))
      .test("postcode-test", t("postcodeInvalidError"), function (value) {
        if (typeof value === "string" && currentCountryCode) {
          return validatePostcode(value, currentCountryCode);
        } else {
          return false;
        }
      }),
    billingAddress: Yup.boolean(),
  };

  useEffect(() => {
    if (pickedAddress?.province) {
      setCurrentProvinceOption(pickedAddress.province);
    }
  }, [pickedAddress, availableProvinces]);

  const setCurrentProvinceOption = (province: string | null) => {
    if (province) {
      let provinceOptions = generateProvinces();
      let option = provinceOptions?.find((o) => o.value === province);
      setCurrentProvince(option);
    }
  };

  const generateProvinces = (): any[] | null => {
    let options = null;

    if (availableProvinces) {
      options = availableProvinces.map((item) => {
        return { value: item.ProvinceCode, label: item.Name };
      });
    }

    if (options) {
      let address = pickedAddress;
      let provinceOption = options.find(
        (province) => province.value === address?.province
      );
      if (!currentProvince)
        currentProvince
          ? setCurrentProvince(provinceOption)
          : setCurrentProvince(options[0]);
    }

    return options;
  };

  useEffect(() => {
    if (pickedAddress) {
      setShowAddressForm(true);
    }
  }, [pickedAddress]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={pickedAddress ?? initialValues}
        validationSchema={() => {
          return Yup.object().shape(validationSchemaShape);
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          values.province = currentProvince?.value;
          values.countryCode = currentCountryCode ?? "";
          handleSubmit(values, actions);
        }}
      >
        {({ isSubmitting, values, errors }) => (
          <Form>
            <div
              className="addressPageContent top "
              style={{
                marginBottom: userSavedAddresses.length === 0 ? "-3rem" : "",
              }}
            >
              <h2>{title}</h2>
            </div>

            <div className="addressPageContent content-padding">
              <CountrySelect
                setCurrentCountryCode={setCurrentCountryCode}
                defaultCountry={getLocation()}
                pickedAddress={pickedAddress}
                locations={availableCountries ?? []}
              />
            </div>

            {userSavedAddresses.length > 0 && (
              <div className="addressFinderContent content-padding">
                <div className={styles.addressGroup}>
                  <>
                    <AddressPicker
                      addresses={userSavedAddresses}
                      handler={handleAddressPicker}
                    />
                  </>
                </div>
              </div>
            )}

            <div className="addressPageContent content-padding">
              <div className={styles.inputGroup}>
                <InputWrap
                  name="firstName"
                  label={t("firstname")}
                  autocomplete="given-name"
                  required={true}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                />

                <InputWrap
                  name="lastName"
                  label={t("lastname")}
                  autocomplete="family-name"
                  required={true}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                />
              </div>

              <InputWrap
                name="phone"
                label={t("phone")}
                autocomplete="tel"
                required={true}
                onChange={(e) => setPhone(e.currentTarget.value)}
                tooltipText={t("translations:PhoneNumberTooltip")}
              />
            </div>

            <div className="addressFinderContent content-padding">
              <div className={styles.addressGroup}>
                <AddressFinder
                  firstName={firstName}
                  lastName={lastName}
                  phone={phone}
                  pickedAddress={pickedAddress}
                  setPickedAddress={(addr) => {
                    setPickedAddress(addr);
                  }}
                  billingPage={billingPage}
                  selectedCountryCode={currentCountryCode}
                />
              </div>
            </div>
            <div
              className={`address-form-test ${!showAddressForm && styles.hide}`}
            >
              <div className="addressPageContent bottom content-padding">
                {currentCountryCode === "DE" && (
                  <InputWrap
                    name="street"
                    label={t("street")}
                    autocomplete="street"
                    required={true}
                  />
                )}
                {currentCountryCode !== "DE" && (
                  <InputWrap
                    name="companyName"
                    label={t("companyName")}
                    autocomplete="organization"
                  />
                )}

                <InputWrap
                  name="addressLine1"
                  label={t("addressLine1")}
                  autocomplete="address-line1"
                  required={currentCountryCode === "DE" ? false : true}
                />

                {currentCountryCode !== "DE" && (
                  <InputWrap
                    name="addressLine2"
                    label={t("addressLine2")}
                    autocomplete="address-line2"
                  />
                )}

                {currentCountryCode !== "DE" &&
                  ["US", "CA", "AU"].includes(currentCountryCode ?? "") && (
                    <SelectWrap
                      value={currentProvince}
                      name="province"
                      label={t("province")}
                      required={true}
                      onChange={setSelectedProvince}
                      options={generateProvinces()}
                    />
                  )}

                <div className={styles.inputGroup}>
                  <InputWrap
                    name="city"
                    label={t("city")}
                    autocomplete="address-level2"
                    required={true}
                  />

                  <InputWrap
                    name="postCode"
                    label={t("postCode")}
                    autocomplete="postal-code"
                    required={true}
                  />
                </div>

                <div className={styles.useBilling}>
                  <div>
                    {!billingPage && (
                      <CheckboxWrap
                        name="billingAddress"
                        label={t("useBillingAddress")}
                      />
                    )}
                  </div>
                  <div className={styles.submit}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      dataTestid="submit-address"
                    >
                      {!billingPage && !values.billingAddress
                        ? t("translations:ContinueToBillingAddress")
                        : t("translations:SubmitAddress")}
                    </Button>
                    <FocusError />
                  </div>
                  {errors && (
                    <MediaQuery maxDeviceWidth={768}>
                      <ErrorMobile errors={errors}></ErrorMobile>
                    </MediaQuery>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`link-button-test ${styles.linkButtonContainer} ${
                showAddressForm && styles.hide
              }`}
            >
              <LinkButton clickCallback={() => setShowAddressForm(true)}>
                {t("address:EnterAddressManually")}
              </LinkButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Address;
