import React from "react";
import { useTranslation } from "react-i18next";
import AddressPage from "../../components/Address/AddressPage";
import DeliveryTabs from "./DeliveryTabs";

const AddressForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <DeliveryTabs activeTab={1} />
      <AddressPage title={t("DeliveryAddress")} />
    </>
  );
};

export default AddressForm;
