import React from "react";
import { useTranslation } from "react-i18next";
import ListOption from "../../components/Forms/PlaceholderComponents/ListOption";

import { useStageRedirect } from "../../utils/hooks";
import { useStateValue } from "../../utils/state-provider";
import useEnabledABTest from "../../utils/hooks/useEnabledABTest";

interface IProps {
  activeTab: number;
}

export const DeliveryTabs = ({ activeTab = 1 }: IProps) => {
  const { t } = useTranslation();
  const { pathRedirect } = useStageRedirect();
  const [{ data }] = useStateValue();

  const isABTestEnabled = useEnabledABTest("eco-friendly", "eco-friendly");

  const goToPage = (tab: number) => {
    switch (tab) {
      case 1:
        pathRedirect(t("paths:/delivery-address"));
        break;
      case 2:
        pathRedirect(`${t("paths:/click-and-collect")}`);
        break;
      case 3:
        pathRedirect(`${t("paths:/collect-from-point")}`);
        break;
    }
  };

  return (
    <>
      {data && data.shipping && (
        <>
          {(data.shipping.cnCEnabled ||
            data.shipping.collectFromPointEnabled) && (
            <ListOption
              name="Home Delivery"
              label={t(
                isABTestEnabled ? "abTest:DeliverToAddress" : "HomeDelivery"
              )}
              type="radio"
              value="Home Delivery"
              checked={activeTab === 1}
              onClick={() => goToPage(1)}
            ></ListOption>
          )}
          {data.shipping.cnCEnabled && (
            <ListOption
              name="Click and Collect"
              label={t(
                isABTestEnabled ? "abTest:DeliverToStore" : "CollectFromStore"
              )}
              type="radio"
              value="Click and Collect"
              checked={activeTab === 2}
              onClick={() => goToPage(2)}
            ></ListOption>
          )}
          <div id={"pudo-test"}>
            {data.shipping.collectFromPointEnabled && (
              <ListOption
                name="Collect from Point"
                label={t(
                  isABTestEnabled
                    ? "abTest:DeliveryToCollectionPoint"
                    : "store:CollectFromPoint"
                )}
                innerLabel={
                  isABTestEnabled ? t("abTest:EcoFriendlyMessage") : ""
                }
                innerLabelPosition={"bottom"}
                type="radio"
                value="Collection Point"
                checked={activeTab === 3}
                onClick={() => goToPage(3)}
                images={
                  isABTestEnabled
                    ? [
                        "https://img.cdn.mountainwarehouse.com/svg/icon-eco-leaf.svg",
                      ]
                    : []
                }
              ></ListOption>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryTabs;
