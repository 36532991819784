import queryString from "query-string";
import {
  LOQATE_API_KEY,
  LOQATE_BASE_URL,
  LOQATE_FIND_URL,
  LOQATE_RETRIEVE_URL,
} from "./constants";
import { logError } from "./logger";

export interface FindQuery {
  text: string;
  language?: string;
  countries?: string[];
  limit?: number;
  containerId?: string;
}

export interface ILoqateFindResponse {
  Items: [
    {
      Text: string;
      Description: string;
    }
  ];
}

export interface ILoqateRetrieveResponse {
  Items: ILoqateAddress[];
}

export interface ILoqateAddress {
  Company: string;
  City: string;
  Line1: string;
  Line2: string;
  Street: string;
  PostalCode: string;
  CountryIso2: string;
  ProvinceCode: string;
  Language: string;
}

class Loqate {
  public async retrieve(
    id: string
  ): Promise<ILoqateRetrieveResponse | undefined> {
    let requestOptions: RequestInit = {
      method: "GET",
    };

    let response = await fetch(
      LOQATE_BASE_URL +
        LOQATE_RETRIEVE_URL +
        queryString.stringify({
          Key: LOQATE_API_KEY,
          Id: id,
        }),
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Loqate HTTP Fetch error: ${response.status} ${response.statusText}`
      );
    }
    let result = await response.text();

    return JSON.parse(result);
  }

  public async find(
    query: FindQuery
  ): Promise<ILoqateFindResponse | undefined> {
    const { text, countries = [], containerId } = query;

    let requestOptions: RequestInit = {
      method: "GET",
    };

    try {
      let response = await fetch(
        LOQATE_BASE_URL +
          LOQATE_FIND_URL +
          queryString.stringify({
            Key: LOQATE_API_KEY,
            Text: text,
            Container: containerId,
            Countries: countries,
          }),
        requestOptions
      );

      let result = await response.text();

      return JSON.parse(result);
    } catch (error) {
      logError(error, "Loqate error");
    }
  }
}

export default Loqate;
