import React, { CSSProperties } from "react";
import styles from "./Error.module.scss";

import iconError from "../../../icons/icon-error.svg";
import { useTranslation } from "react-i18next";

interface IProps {
  hasBackground?: boolean;
  contactUs?: string;
  children?: React.ReactNode;
  style?: CSSProperties | undefined;
  isCustomerError?: boolean;
}

export const ErrorComponent = ({
  hasBackground = false,
  children,
  contactUs,
  isCustomerError,
  style,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`form-error ${styles.error} ${
          hasBackground && styles.hasBackground
        }`}
        style={style}
      >
        <img src={iconError} alt="" />
        <span>
          {children}
          {!isCustomerError && contactUs && (
            <a target="_blank" href={contactUs} rel="noreferrer">
              {t("ContactUs")}
            </a>
          )}

          {isCustomerError && <> {t("error:ContactBank")}</>}
        </span>
      </div>
    </>
  );
};

export default ErrorComponent;
