import React, { useEffect } from "react";

export const GoogleReview = ({ surveyOptin, cultureCode }: any) => {
  useEffect(() => {
    if (surveyOptin) {
      const script = document.createElement("script");

      script.src = "https://apis.google.com/js/platform.js?onload=renderOptIn";
      script.async = true;

      document.body.appendChild(script);

      (window as any).renderOptIn = function () {
        (window as any).gapi.load("surveyoptin", function () {
          (window as any).gapi.surveyoptin.render(surveyOptin);
        });
      };

      (window as any).___gcfg = {
        lang: convertCultureCode(cultureCode),
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [surveyOptin]);

  function convertCultureCode(code: string) {
    switch (code.toLowerCase()) {
      case "gb":
      case "engb":
      case "en-gb":
        return "en_gb";
      case "us":
      case "enus":
      case "en-us":
        return "en_us";
      case "au":
      case "enau":
      case "en-au":
        return "en_au";
      case "ca":
      case "enca":
      case "en-ca":
        return "en_us";
      case "fr":
      case "frfr":
      case "fr-fr":
        return "fr";
      case "de":
      case "dede":
      case "de-de":
        return "de";
      case "ie":
      case "enie":
      case "en-ie":
        return "en_gb";
      case "pl":
      case "plpl":
      case "pl-pl":
        return "pl";
      case "nz":
      case "ennz":
      case "en-nz":
        return "en_au";
      default:
        return "en_gb";
    }
  }
  return <></>;
};
export default GoogleReview;
