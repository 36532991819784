import React from "react";
import LinkButton from "../Forms/PlaceholderComponents/LinkButton";
import { useStageRedirect } from "../../utils/hooks";

interface ITrackerLinkProps {
  component: any;
  name: string;
  path: string;
  isActive: boolean;
  isDone: boolean;
}

const TrackerLink = ({
  component,
  name,
  path,
  isActive,
  isDone,
}: ITrackerLinkProps) => {
  const { pathRedirect } = useStageRedirect();

  return (
    <LinkButton
      // className={isDone ? styles.trackerLink : ""}
      fontSize={"0.9rem"}
      fontColour={!isActive && !isDone ? "#64686D" : ""}
      isGreen={isDone}
      isBold={isActive}
      showUnderline={false}
      clickCallback={() => (isDone ? pathRedirect(path) : null)}
    >
      {component}
      <span>{name}</span>
    </LinkButton>
  );
};

export default TrackerLink;
