import { datadogLogs } from "@datadog/browser-logs";
import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Ab2cInstanceProvider from "../../components/Auth/Ab2cInstanceProvider";
import MissingStock from "../../components/Errors/MissingStock";
import OverBasketThreshold from "../../components/Errors/OverBasketThreshold";
import {
  APIS,
  cookieConfig,
  LOGIN_STAGE,
  storageOrderToken,
  targetingGroup,
} from "../../utils/constants";
import {
  getFullPath,
  getOrderToken,
  isAnalyticConsented,
  removeStorageBasketToken,
  removeStorageOrderToken,
  setTotalBasketItems,
  updateDotDigital,
} from "../../utils/helpers";
import { logError, setLogUserContextFromOrder } from "../../utils/logger";
import { useStateValue } from "../../utils/state-provider";
import { Stages } from "../../utils/types";
import Login from "./Login";
import TokenHandler from "./TokenHandler";
import { useStageRedirect } from "../../utils/hooks";

const LoginPage = () => {
  const [isOverThreshold, setIsOverThreshold] = useState(false);
  const { t } = useTranslation();
  const { pathRedirect } = useStageRedirect();
  const [missingProducts, setMissingProducts] = useState<[]>([]);

  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue();

  const successfulLoginHandler = (
    response: AxiosResponse<any>,
    logEventContext: any
  ) => {
    dispatch({
      type: "changeData",
      newData: response.data.order,
    });
    Cookies.set(storageOrderToken, response.data.token, cookieConfig);
    dispatch({
      type: "changeStage",
      newStage: Stages.Address,
    });
    setTotalBasketItems(response.data.order.summary.items);
    if (isAnalyticConsented(targetingGroup)) {
      updateDotDigital();
    }

    const tokenData = getOrderToken(); // Token saved on line 52
    if (tokenData) {
      setLogUserContextFromOrder(tokenData);
    }

    datadogLogs.logger.info("Login success", {
      evt: logEventContext,
      response: response,
    });

    if (response.data.order.isCnC) {
      pathRedirect(t("paths:/click-and-collect"));
    } else if (response.data.order.isCollectFromPoint) {
      pathRedirect(t("paths:/click-from-point"));
    } else {
      pathRedirect(t("paths:/delivery-address"));
    }
  };

  const failureLoginHandler = (error: any, logEventContext: any) => {
    datadogLogs.logger.error("Login failure", {
      evt: logEventContext,
      error: error,
    });

    if (!error.response) {
      logError(error, "Unknown error " + APIS.orderLogin, LOGIN_STAGE);
      console.error(error);
      throw error; //Could pass in an error message here to login page to display.
    } else if (error.response.status === 404 || error.response.status === 400) {
      removeStorageOrderToken();
      localStorage.clear();
      window.location.href = getFullPath(t, "redirectToBasket");
    } else if (error.response.status === 406) {
      setIsOverThreshold(true);
      removeStorageOrderToken();
      localStorage.clear();
    } else if (error.response.status === 410) {
      setMissingProducts(error.response.data);
      removeStorageOrderToken();
      localStorage.clear();
    } else if (error.response.status === 500) {
      logError(error, "Server error " + APIS.orderLogin, LOGIN_STAGE);
      removeStorageOrderToken();
      removeStorageBasketToken();
      localStorage.clear();
      window.location.href = getFullPath(t, "redirectToBasket");
    } else {
      logError(error, "Unhandled " + APIS.orderLogin, LOGIN_STAGE);
      console.error("[APP LOG]: error", error);
      throw error; //Could pass in an error message here to login page to display.
    }
  };

  return (
    <>
      {isOverThreshold && (
        <OverBasketThreshold isOverThreshold={isOverThreshold} />
      )}
      {missingProducts.length > 0 && (
        <MissingStock products={missingProducts} />
      )}
      <Ab2cInstanceProvider>
        <TokenHandler
          successfulLoginHandler={successfulLoginHandler}
          failureLoginHandler={failureLoginHandler}
        >
          <Login
            successfulLoginHandler={successfulLoginHandler}
            failureLoginHandler={failureLoginHandler}
          />
        </TokenHandler>
      </Ab2cInstanceProvider>
    </>
  );
};
export default LoginPage;
