import { datadogLogs } from "@datadog/browser-logs";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import CheckboxWrap from "../../components/Forms/CheckboxWrap";
import InputWrap from "../../components/Forms/InputWrap";
import Button from "../../components/Forms/PlaceholderComponents/Button";
import { WebLoginApiClient } from "../../utils/api";
import { APIS, ZENDESK_URL } from "../../utils/constants";
import styles from "./Form.module.scss";

type FormValues = {
  email: string;
  emailSignUp: boolean;
  signUpText: string;
};

interface IProps {
  activeTab: number;
  signInHandler: any;
  successfulLoginHandler: any;
  failureLoginHandler: any;
}

// See https://github.com/jquense/yup/issues/386#issuecomment-450428912 for why we don't use Yup's email validation
const emailRegex: RegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export const GuestForm = ({
  successfulLoginHandler,
  failureLoginHandler,
  signInHandler,
}: IProps) => {
  const { t } = useTranslation();

  const optInText = t("login:OptIn");

  const initialValues: FormValues = {
    email: "",
    emailSignUp: false,
    signUpText: optInText,
  };

  const handleLogin = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    actions.setSubmitting(true);

    datadogLogs.logger.info("Login attempt", { evt: { name: "guest" } });

    WebLoginApiClient.post(
      APIS.orderGuest,
      {
        email: values.email,
        emailSignUp: !values.emailSignUp,
        signUpText: values.signUpText,
      },
      {
        params: { force: true },
      } //TODO: Disable force.
    )
      .then(function (response) {
        successfulLoginHandler(response, { name: "guest", outcome: "success" });
        actions.setSubmitting(false);
      })
      .catch(function (error: any) {
        actions.setSubmitting(false);
        failureLoginHandler(error, { name: "guest", outcome: "failure" });
      });
  };

  return (
    <div className={styles.container}>
      <div className={`content`}>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .matches(emailRegex, t("SignInInvalidError"))
              .required(t("SignInRequiredError")),
            emailSignUp: Yup.bool(),
          })}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            handleLogin(values, actions);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={styles.title}>{t("login:GuestCheckout")}</div>
              <InputWrap
                name="email"
                id="guestEmail"
                label={t("login:Email")}
                type="email"
                autocorrect="off"
                autoCapitalize="none"
                required={true}
                autocomplete="email"
                placeholder={t("login:EnterEmail")}
              />

              <div className={styles.optInContainer}>
                <div>{t("login:OptIn")}</div>

                <div className={styles.signUp}>
                  <CheckboxWrap
                    name="emailSignUp"
                    label={""}
                    type="checkbox"
                    autocomplete="off"
                  />
                </div>

                <div>
                  <Trans i18nKey="login:Unsubscribe">
                    <a
                      className={styles.unsubscribe}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={ZENDESK_URL + t("paths:/privacy")}
                    >
                      click here
                    </a>
                  </Trans>
                </div>
              </div>

              <div className="is-centered">
                <Button
                  className={styles.button}
                  disabled={isSubmitting}
                  type="submit"
                  dataTestid="continue-as-guest"
                >
                  {t("login:ContinueGuest")}
                </Button>
              </div>

              <div className={styles.signInContainer}>
                <span className={styles.haveAccount}>
                  {t("login:HaveAnAccount")}
                </span>

                <Button
                  className={styles.button}
                  disabled={isSubmitting}
                  onClick={signInHandler}
                  data-testid="sign-in"
                >
                  {t("login:SignIn")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GuestForm;
