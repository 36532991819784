import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import routeConfig from "../route-config";
import { useStateValue } from "../state-provider";
import { Stages } from "../types";

const safeParams = ["qa"];

export const useStageRedirect = () => {
  const nav = useNavigate();
  const loc = useLocation();
  const [, dispatch] = useStateValue();

  const { t } = useTranslation("paths");

  /**
   * Redirects the user to a specific page, keeping safe params like ?qa=
   * Also dispatches the changeState action
   */
  const stageRedirect = (
    stage: Stages,
    state?: any,
    dispatchChangeState: boolean = true,
    replace: boolean = false
  ) => {
    let params = new URLSearchParams(loc.search);
    params.forEach((v: string, key: string) => {
      if (!safeParams.includes(key)) {
        params.delete(key);
      }
    });

    const path = routeConfig.find((r) => r.stage === stage);
    if (path) {
      if (dispatchChangeState) {
        dispatch({
          type: "changeStage",
          newStage: stage,
        });
      }
      nav(t(path.path) + "?" + params.toString(), { state, replace });
    }
  };

  const pathRedirect = (rawPath: string, state?: any) => {
    let params = new URLSearchParams(loc.search);
    params.forEach((v: string, key: string) => {
      if (!safeParams.includes(key)) {
        params.delete(key);
      }
    });

    nav(rawPath + "?" + params.toString(), { state });
  };

  return { stageRedirect, pathRedirect };
};
