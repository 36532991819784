import * as React from "react";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styles from "./Tooltip.module.scss";

interface ITooltip {
  text: string
}

const Tooltip = ({ text }: ITooltip) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const openTooltip = () => {
    setShowTooltip(true);
  }

  const closeTooltip = () => {
    setShowTooltip(false);
  }

  useEffect(() => {
    if(isMobile) document.body.style.overflow = showTooltip ? 'hidden' : 'auto'
  }, [showTooltip])

  if (isMobile) {
    return (
      <>
        <div
          role={"button"}
          tabIndex={0}
          className={styles.tooltipContainer}
          onTouchStart={openTooltip}
          onKeyDown={openTooltip}>
          <img
            className={styles.tooltipIcon}
            src={"https://img.cdn.mountainwarehouse.com/svg/icon-tooltip.svg"}
            alt={"Question Mark Icon"} />
        </div>
        {showTooltip &&
          <div>
            <div
              className={(styles.showMobile)}
              onTouchStart={() => closeTooltip()}
              role={"button"}
              tabIndex={0}
              onKeyDown={openTooltip}>
            </div>
            <div className={styles.mobileTooltipText} >
              {text}
              <div
                className={styles.mobileCloseIcon}
                onClick={() => closeTooltip()}
                role={"button"}
                tabIndex={0}
                onKeyDown={openTooltip}>
                <img
                  src={"https://img.cdn.mountainwarehouse.com/common/site-icons/icon-small-cross.svg"}
                  alt={"Cross Icon"} />
              </div>
            </div>
          </div>
        }
      </>
    );
  }
  else {
    return (
      <>
        <div className={styles.tooltipContainer} onMouseEnter={openTooltip} onMouseLeave={() => closeTooltip()}>
          <img className={styles.tooltipIcon} src={"https://img.cdn.mountainwarehouse.com/svg/icon-tooltip.svg"} alt={"Question Mark Icon"} />
        </div>
        {showTooltip &&
          <div className={styles.showContainer}>
            <div className={styles.show}>
              <div className={styles.tooltipText}>{text}</div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default Tooltip;