import { datadogLogs } from "@datadog/browser-logs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Auth from "./components/Auth";
import Completed from "./components/Completed";
import Footer from "./components/Footer";
import LoadWidget from "./components/LoadWidget";
import OrderSummary from "./components/OrderSummary";
import SiteWrap from "./components/SiteWrap";
import Error from "./pages/Errors/Error";
import NotFound from "./pages/Errors/NotFound";
import LoginPage from "./pages/Login";
import "./styles/app.scss";
import { ZENDESK_URL, profilesMap } from "./utils/constants";
import {
  compareTokens,
  getBasketToken,
  getConsentCookie,
  getFullPath,
  getOrderToken,
  isLocalStorageAvailable,
} from "./utils/helpers";
import routeConfig from "./utils/route-config";
import { useStateValue } from "./utils/state-provider";
import { Stages } from "./utils/types";

export const App = () => {
  const { t } = useTranslation("paths");
  const [isLoading, setIsLoading] = useState(false);
  const [{ data, profile }] = useStateValue();
  const [countryCode, setCountryCode] = useState(
    () =>
      profile?.countryCode.toLowerCase() ??
      window.location.pathname.split("/")[1].replace(/\s/g, "")
  );

  useEffect(() => {
    if (profile) {
      setCountryCode(profile?.countryCode.toLowerCase());
    } else {
      const firstPath = window.location.pathname
        .split("/")[1]
        .replace(/\s/g, "");
      setCountryCode(firstPath.toLowerCase());
    }
  }, [profile]);

  useEffect(() => {
    if (!isLocalStorageAvailable()) {
      window.location.replace(ZENDESK_URL + t("/help") + t("/cookies"));
    } else {
      setIsLoading(true);

      datadogLogs.clearUser();
      datadogLogs.logger.warn("App start, user cleared.");

      // TODO - Do it on the backend side
      const basketToken = getBasketToken();
      const orderToken = getOrderToken();

      compareTokens(basketToken, orderToken);

      if (basketToken) {
        let item = profilesMap.get(basketToken.profileId);

        // redirect to the correct profile if not matching the profile in the basket
        if (item) {
          let profileName =
            item.countryCode.toLowerCase() !== "gb"
              ? item.countryCode.toLowerCase()
              : "";

          if (
            profileName &&
            !window.location.pathname.split("/")[1].includes(profileName)
          ) {
            window.location.pathname = item.countryCode.toLowerCase();
            setIsLoading(false);
            return;
          }
        }

        if (!item || !item.isSupported) {
          datadogLogs.logger.error(
            "Profile not found or is not supported! User is being shown an error page.",
            { item }
          );
          window.location = t("/error");
        }

        if (basketToken.brand === "NeonSheep") {
          let nsFavicon = `${process.env.PUBLIC_URL}/faviconNS.ico`;
          let faviconLink: HTMLLinkElement | null =
            document.querySelector("link[rel*='icon']");
          if (faviconLink) {
            faviconLink.href = nsFavicon;
          }
          let iPhonefaviconLink: HTMLLinkElement | null =
            document.querySelector("link[rel*='apple-touch-icon']");
          if (iPhonefaviconLink) {
            iPhonefaviconLink.href = nsFavicon;
          }
        }
      }

      if (!orderToken && !basketToken) {
        datadogLogs.logger.warn(
          "No token present! User will be redirected to Basket Page."
        );
        window.location.replace(getFullPath(t, "redirectToBasket"));
      }

      setIsLoading(false);
    }
  }, []); // Run just on first load

  useEffect(() => {
    getConsentCookie();
  }, [data]);

  return (
    <Router basename={t("/baseName")}>
      <Routes>
        {isLoading && <LoadWidget />}

        <Route
          path="/"
          element={
            <SiteWrap showTracker={false}>
              <LoginPage />
            </SiteWrap>
          }
        />

        {routeConfig.map((route) => {
          const ChildComponent = route.component;
          if (route.authRequired === true) {
            return (
              <Route
                key={route.path}
                path={t(route.path)}
                element={
                  <>
                    <SiteWrap showTracker={route.stage !== Stages.Receipt}>
                      <Auth routeStage={route.stage}>
                        {route.stage <= Stages.Payment ? (
                          <div className="content-container">
                            <div className="content-main">
                              <Completed
                                routePageState={route.stage}
                                childComponent={<ChildComponent />}
                              />
                            </div>
                            <div className="content-side">
                              <OrderSummary />
                            </div>
                          </div>
                        ) : (
                          <ChildComponent />
                        )}
                      </Auth>
                    </SiteWrap>
                    {countryCode === "de" ? <Footer /> : null}
                  </>
                }
              />
            );
          }

          return (
            <Route
              key={route.path}
              path={route.path}
              element={<SiteWrap>{<ChildComponent />}</SiteWrap>}
            />
          );
        })}

        <Route
          path={t("/error")}
          element={
            <SiteWrap>
              <Error />
            </SiteWrap>
          }
        />

        <Route
          path="*"
          element={
            <SiteWrap>
              <NotFound />
            </SiteWrap>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
