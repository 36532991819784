import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectWrap from "../Forms/SelectWrap";
import { Option } from "../../utils/types";
import { IGetAddressResponse } from "../../utils/types/address";

interface IProps {
  addresses: IGetAddressResponse[] | null;
  handler: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export const AddressPicker = ({ addresses, handler }: IProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<Option>();

  const generateDeliveryString = (d: IGetAddressResponse) => {
    const addressLine1 = d.Address1 ? `${d.Address1}, ` : "";
    const city = d.City ? `${d.City}, ` : "";
    const postCode = d.Postcode ? `${d.Postcode}` : "";

    return `${addressLine1} ${city} ${postCode}`;
  };

  const generateAddressOptions = (): any[] => {
    let options: { value: IGetAddressResponse; label: string }[] = [];
    addresses?.forEach((address) => {
      options.push({ value: address, label: generateDeliveryString(address) });
    });
    return options;
  };

  const updateValue = (e: any) => {
    setValue(e);
    handler(e);
  };

  if (!addresses || addresses.length === 0 || !handler) return null;

  return (
    <SelectWrap
      name="addressPicker"
      label={t("SavedAddresses")}
      options={generateAddressOptions()}
      onChange={updateValue}
      value={value}
      placeholder={t("address:SelectAddress")}
    />
  );
};

export default AddressPicker;
