import { storageStage } from "./constants";
import { getCurrentProfileById } from "./helpers";
import { Stages } from "./types";

export type Action =
  | { type: "changeStage"; newStage: Stages }
  | { type: "changeOrderTotal"; orderTotal: number }
  | { type: "changeShipping"; shipping: any; force: boolean }
  | { type: "changeData"; newData: any };

/* eslint-disable */
export default (state: any, action: Action) => {
  switch (action.type) {
    case "changeOrderTotal":
      return {
        ...state,
        orderTotal: action.orderTotal,
      };

    case "changeShipping":
      // if shipemnt exists, don't override unless you have to
      if (action.shipping && state.shipping && !action.force) {
        return {
          ...state,
          lastShipping: state.shipping,
          shipping: state.shipping,
        };
      }
      return {
        ...state,
        lastShipping: state.shipping,
        shipping: action.shipping,
      };

    case "changeStage":
      localStorage.setItem(storageStage, action.newStage.toString());
      return {
        ...state,
        stage: action.newStage,
      };

    case "changeData":
      return {
        ...state,
        data: action.newData,
        profile: getCurrentProfileById(action.newData.profileId),
      };
    default:
      return state;
  }
};
