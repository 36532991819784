import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { currency, isMiraklOrder } from "../../utils/helpers";
import { useStateValue } from "../../utils/state-provider";
import { SelectedShipmentViewModel } from "../../utils/types/shipping";
import styles from "./Totals.module.scss";
import { shippingRateCost } from "../../utils/shipping";

export const ShippingsSummary = () => {
  const { t } = useTranslation();

  const [{ data, profile, shipping }] = useStateValue(); // eslint-disable-line  @typescript-eslint/no-unused-vars
  const [shippingFlattened, setShippingFlattened] = useState<any[]>();

  useEffect(() => {
    let shippingSummary = new Map<string, number>();
    if (shipping?.selectedShippingOptionMethods) {
      shipping.selectedShippingOptionMethods.forEach(
        (item: SelectedShipmentViewModel) => {
          let existing = shippingSummary.get(item.storeName) ?? 0;
          let sum = existing + shippingRateCost(item);
          shippingSummary.set(item.storeName, sum);
        }
      );

      let items = Array.from(shippingSummary).map(([name, value]) => ({
        name,
        value,
      }));

      setShippingFlattened(items);
    }
  }, [shipping]);

  function getBasketSubTotal(items: any[]) {
    let values = items.map((item) => item.value);
    if (values && values.length > 0) {
      let result = values.reduce((a, b) => a + b) ?? 0;
      if (result === 0) return t("Free");

      let tax = data?.summary?.deliveryTax ?? 0;
      result += tax;

      return currency(
        result,
        data?.summary?.currencyCode,
        profile?.name ?? "enGB"
      );
    }
    return t("Free");
  }

  if (!shipping || shipping.length === 0) return <></>;

  return (
    <div className={styles.prices}>
      <dl>
        {shippingFlattened &&
          shippingFlattened.length > 1 &&
          shippingFlattened.map((item) => (
            <div>
              <dt>{item.name}</dt>
              <dd>
                {item.value !== 0
                  ? currency(
                      item.value,
                      data?.summary?.currencyCode,
                      profile?.name ?? "enGB"
                    )
                  : t("Free")}
              </dd>
            </div>
          ))}

        {data?.summary?.deliveryTax && (
          <div>
            <dt>{t("DeliveryTax")}</dt>
            <dd>
              {currency(
                data?.summary?.deliveryTax,
                data?.summary?.currencyCode,
                profile?.name ?? "enGB"
              )}
            </dd>
          </div>
        )}

        <div>
          <dt>
            <strong>
              {data.summary.items.length > 1 && isMiraklOrder(data.orderType)
                ? t("ShippingSubtotal")
                : t("Shipping")}{" "}
            </strong>
          </dt>
          <dd>
            <strong>{getBasketSubTotal(shippingFlattened ?? [])}</strong>
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default ShippingsSummary;
