import { IShippingOption, SelectedShipmentViewModel } from "../types/shipping";

const getSelectedShipmentViewModel = (
  option: IShippingOption,
  id: number
): SelectedShipmentViewModel | null => {
  let method = option.methods.find((x) => x.shipMethodId === id);
  if (method) {
    let result: SelectedShipmentViewModel = {
      shipmentId: method.shipMethodId,
      warehouse: option.warehouse,
      localizedName: method.localizedName,
      name: method.name,
      rate: method.rate || 0,
      storeName: option.name,
      isComplimentary: option.isComplimentary,
      complimentaryPercentage:
        option.complimentaryAvailable || option.complimentaryDetails?.available
          ? option.complimentaryDetails?.complimentaryPercentage ?? 100
          : 0,
    };
    return result;
  }
  return null;
};

export { getSelectedShipmentViewModel };
