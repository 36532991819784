import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, PaymentMethodResult, Stripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PaymentMethod } from "..";
import API from "../../../utils/api";
import { APIS } from "../../../utils/constants";
import { logError, Severity } from "../../../utils/logger";
import { useStateValue } from "../../../utils/state-provider";
import { IAddressResponse } from "../../../utils/types/address";
import { paymentIntentStatus } from "../../../utils/types/payments";
import StripePaymentMethods from "./StripePaymentMethods";

interface StripeFormProps {
  billingAddress: IAddressResponse;
  onSubmit: React.Dispatch<any>;
  csrfToken: string;
  errorHandler: () => void;
  method: PaymentMethod;
  index: number;
  selectedPaymentMethod: string | null;
  setSelectedPaymentMethod: (option: string) => void;
}

let stripePromise: Stripe | null;
const getStripe = async (errorHandler: () => void, key: string) => {
  if (!stripePromise) {
    try {
      stripePromise = await loadStripe(key);
    } catch (error: any) {
      logError(error, "Failed loading Stripe", Severity.Warning);
      errorHandler();
    }
  }
  return stripePromise;
};

const StripeForm = ({
  billingAddress,
  onSubmit,
  csrfToken,
  errorHandler,
  method,
  index,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}: StripeFormProps) => {
  const { t } = useTranslation();
  const [{ profile }] = useStateValue();

  const stripePromise: Promise<Stripe | null> = useMemo(() => {
    if (!profile) return new Promise(() => {});
    let key = profile?.stripePublicKey ?? "";
    return getStripe(errorHandler, key);
  }, [profile]);

  const getRedirectUrl = (): string => {
    const paymentPath =
      profile?.countryCode === "GB"
        ? t("paths:/redirect").replace("/", "")
        : t("paths:/redirect");

    return (
      process.env.REACT_APP_CHECKOUT_HOST +
      t("paths:/baseName").replace("/", "") +
      paymentPath
    );
  };

  const createPaymentIntent = (
    paymentMethod: PaymentMethodResult,
    additionalData?: Map<string, string>,
    csrf?: string
  ) => {
    if (paymentMethod.error) {
      return Promise.reject(paymentMethod.error);
    }
    const uri = APIS.paymentIntent.replace("{1}", "Stripe");

    // Creates additionalData if necessary, and adds the redirect url if not added already
    if (!additionalData || !additionalData.get("redirect")) {
      const updatedMap = additionalData ?? new Map<string, string>();
      if (!updatedMap.get("redirect")) {
        updatedMap.set("redirect", getRedirectUrl());
      }
      additionalData = updatedMap;
    }

    // Maps are not stringified properly
    const additionalDataObject: { [key: string]: any } = {};
    additionalData.forEach((val, key) => {
      additionalDataObject[key] = val;
    });

    const data = {
      paymentMethodId: paymentMethod ? paymentMethod.paymentMethod?.id : "",
      paymentIntentId: "",
      paymentMethods: [paymentMethod.paymentMethod?.type],
      additionalData: additionalDataObject,
    };

    let headers = {};
    if (csrf) {
      headers = { "x-csrf": csrf };
    }

    let response = API.post<paymentIntentStatus>(uri, data, {
      headers: headers,
    });

    return response;
  };

  if (!profile || !stripePromise) return null;

  return (
    <Elements stripe={stripePromise}>
      <StripePaymentMethods
        billingAddress={billingAddress}
        onSubmit={onSubmit}
        csrfToken={csrfToken}
        method={method}
        index={index}
        createPaymentIntent={createPaymentIntent}
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
      />
    </Elements>
  );
};
export default StripeForm;
