import { FormikHelpers } from "formik";
import React, { useState } from "react";
import API from "../../utils/api";
import { APIS, CNC_STAGE } from "../../utils/constants";
import { logError } from "../../utils/logger";
import DeliveryTabs from "../DeliveryAddress/DeliveryTabs";
import PickupDelivery from "../../components/PickupDelivery";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../utils/state-provider";
import {
  getRangeInKmPerProfile,
  getRangeTextPerProfile,
} from "../../utils/helpers";

import {
  IShipping,
  SelectedShipmentViewModel,
} from "../../utils/types/shipping";
import { APIProvider } from "@vis.gl/react-google-maps";

type FormValues = {
  address: string;
};

//TODO: Consider loading google maps script dynamically instead of in the index.html. So that it is only loaded when needed.

export const ClickCollect = () => {
  const { t } = useTranslation();
  const [{ data, profile }, dispatch] = useStateValue();
  const [storeSearchError, setStoreSearchError] = useState("");

  const postStoreSearch = async (
    latitude: number,
    longitude: number,
    actions: FormikHelpers<FormValues> | null,
    setStoresWithDistances: any,
    setSearching: any | null
  ) => {
    setStoreSearchError("");
    API.get(APIS.storeLocations, {
      params: { latitude, longitude, range: getRangeInKmPerProfile(profile) },
    })
      .then(function (response) {
        if (response.data.length > 0) {
          // trying to do shallow copy fails, please use JSON.parse for safety
          let newShipping: IShipping = JSON.parse(
            JSON.stringify(data.shipping)
          );

          let selectedCnC: SelectedShipmentViewModel = {
            localizedName: t("CollectFromStore"),
            name: t("CollectFromStore"),
            rate: Number.parseFloat(response.data[0].rate) ?? 0,
            warehouse: response.data[0].warehouse ?? "",
            storeName: "",
            shipmentId: 0,
            isComplimentary: false,
          };

          newShipping.selectedShippingOptionMethods[0] = selectedCnC;

          dispatch({
            type: "changeShipping",
            shipping: newShipping,
            force: true,
          });
        } else {
          // no stores found within range
          setStoreSearchError(
            t("store:NoStoresFound", { range: getRangeTextPerProfile(profile) })
          );
        }

        setStoresWithDistances(latitude, longitude, response.data);

        setSearching && setSearching(false);
        if (actions) {
          actions?.setSubmitting(false);
        }
      })
      .catch(function (error) {
        setStoresWithDistances(latitude, longitude, []);
        setStoreSearchError(
          t("store:NoStoresFound", { range: getRangeTextPerProfile(profile) })
        );
        setSearching && setSearching(false);

        if (actions) {
          console.log(actions);

          actions?.setSubmitting(false);
        }
        logError(error, "GET " + APIS.storeLocations, CNC_STAGE);
      });
  };

  return (
    <>
      <h1>{t("DeliveryMethod")}</h1>
      <DeliveryTabs activeTab={2} />
      <APIProvider apiKey={process.env.REACT_APP_GAPI_KEY!}>
        <PickupDelivery
          storeSearchCallback={postStoreSearch}
          isParcelShop={false}
          errorMessage={storeSearchError}
        ></PickupDelivery>
      </APIProvider>
    </>
  );
};

export default ClickCollect;
