import React from "react";
import styles from "./withdrawalnotice.module.scss";
import { Trans } from "react-i18next";

const WithdrawalNotice = () => {
  // leaving as is for now, atm only in DE checkout. Would take a small amount of work to get this working for other
  // profiles but it's not required for the time being
  return (
    <p className={styles.smallParagraph}>
      <Trans i18nKey="WithdrawalNotice">
        <a href="https://support.mountainwarehouse.com/hc/de/sections/360004441037-Privacy-Policy">
          Datenschutzbestimmungen
        </a>
        <a href="https://support.mountainwarehouse.com/hc/de/articles/360015539757-Allgemeine-Gesch%C3%A4ftsbedingungen">
          AGB
        </a>
        <a href="https://support.mountainwarehouse.com/hc/de/articles/360015539757-Allgemeine-Gesch%C3%A4ftsbedingungen">
          Widerrufsbestimmungen
        </a>
      </Trans>
    </p>
  );
};

export default WithdrawalNotice;
