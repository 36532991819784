import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Location.module.scss";
import { currency } from "../../../../utils/helpers";
import { useStateValue } from "../../../../utils/state-provider";
import { BranchTime } from "../../../../utils/types/branches";
import Skeleton from "react-loading-skeleton";
import { OrderEstimatedDelivery } from "../../../../utils/types/shipping";

interface IStore {
  storeName: string;
  address: string;
  branchTimes: BranchTime[];
  estimatedDelivery?: OrderEstimatedDelivery;
}

const Location = ({
  storeName,
  address,
  branchTimes,
  estimatedDelivery,
}: IStore) => {
  const { t } = useTranslation();
  const [{ profile, shipping }] = useStateValue();

  const getFriendlyFulfilmentString = (): string => {
    if (estimatedDelivery?.deliveryDays) {
      let dayValue = "";
      if (estimatedDelivery.deliveryDaysMax) {
        dayValue += `${estimatedDelivery.deliveryDays}-${estimatedDelivery.deliveryDaysMax}`;
      } else {
        dayValue += `${estimatedDelivery.deliveryDays}`;
      }

      return `${dayValue} ${
        estimatedDelivery.workDaysOnly ? t("WorkingDays") : t("Days")
      }`;
    }

    return t("EstimatedCnCFullfilmentTimeFallback");
  };

  return (
    <div className={styles.selected}>
      <div className={styles.store}>
        {t("StoreSelected")}: <strong>{storeName}</strong>
      </div>
      <div className={styles.address}>{address}</div>
      {branchTimes.map((branchTime: BranchTime) => {
        return (
          <div className={styles.branchTime}>
            <div className={styles.day}>{branchTime.dayName}</div>
            <strong>
              {branchTime.open ? branchTime.open.substring(0, 5) : "00:00"} -{" "}
              {branchTime.close ? branchTime.close.substring(0, 5) : "00:00"}
            </strong>
          </div>
        );
      })}
      <div className={styles.estimate}>
        {t("EstimatedCnCFullfilmentTime")}:{" "}
        <strong>{getFriendlyFulfilmentString()}</strong>
      </div>
      <div>
        {t("store:CollectionCost")}:{" "}
        {shipping.selectedShippingOptionMethods && (
          <>
            <strong>
              {profile && shipping.selectedShippingOptionMethods[0].rate
                ? currency(
                    shipping.selectedShippingOptionMethods[0]?.rate,
                    profile.currencyCode,
                    profile.name
                  )
                : t("Free")}
            </strong>
          </>
        )}
        {!shipping.selectedShippingOptionMethods && (
          <>
            <Skeleton />
          </>
        )}
      </div>
    </div>
  );
};

export default Location;
