import {
  AdvancedMarker,
  Map,
  MapEvent,
  useMap,
} from "@vis.gl/react-google-maps";
import React, { useEffect, useRef } from "react";
import { IStore } from "../../../../utils/types/branches";
import styles from "./LocationsMap.module.scss";
import { Circle } from "./Circle";

interface IProps {
  storeList: IStore[];
  focusedItem?: IStore;
  onItemFocus: Function;
  isParcelShop: boolean;
  latLong: { lat: number; lng: number } | null;
  range: number;
}

export const LocationsMap = ({
  storeList,
  focusedItem,
  onItemFocus,
  isParcelShop,
  latLong,
  range,
}: IProps) => {
  const map = useMap("LocationsMap");
  const circleRef = useRef<google.maps.Circle | null>(null);

  const setCircleBounds = () => {
    if (circleRef.current && latLong) {
      const centerPoint: google.maps.LatLngLiteral = {
        lat: +(latLong?.lat ?? 0),
        lng: +(latLong?.lng ?? 0),
      };

      circleRef.current.setCenter(centerPoint);
      circleRef.current.setRadius(range * 1000);

      const bounds = circleRef.current.getBounds();
      if (bounds) {
        map?.fitBounds(bounds);
      }
    }
  };

  useEffect(() => {
    if (focusedItem) {
      const centerPoint: google.maps.LatLngLiteral = {
        lat: +focusedItem.latitude,
        lng: +focusedItem.longitude,
      };
      map?.setCenter(centerPoint);
    }
  }, [focusedItem]);

  useEffect(() => {
    setCircleBounds();
  }, [map, latLong]);

  const mapContainerStyle = {
    height: "320px",
    width: "100%",
  };

  const GetMarkerIcon = (store: any) => {
    if (isParcelShop) {
      switch (store.locationType) {
        case "SHOP":
          return "https://img.cdn.mountainwarehouse.com/svg/icon-pin-drop-pudo-store.svg";
        case "LOCKER":
          return "https://img.cdn.mountainwarehouse.com/svg/icon-pin-drop-pudo-locker.svg";
        default:
          return "https://img.cdn.mountainwarehouse.com/svg/icon-pin-drop.svg";
      }
    }
    return "https://img.cdn.mountainwarehouse.com/svg/icon-pin-drop.svg";
  };

  return (
    <div className={styles.container}>
      <Map
        style={mapContainerStyle}
        id="LocationsMap"
        mapId="ea0765edc2b0a879"
        onMapCapabilitiesChanged={(ev: MapEvent) => {
          const capabilites = ev.map.getMapCapabilities();
          if (!capabilites.isAdvancedMarkersAvailable) {
            console.error("Advanced markers couldn't load on map");
          }
        }}
      >
        {latLong && (
          <Circle
            ref={circleRef}
            center={latLong}
            radius={range * 1000}
            strokeWeight={0.8}
            fillColor="#077801"
            fillOpacity={0.2}
            strokeColor="#055701"
          />
        )}
        {storeList.map((item: IStore) => {
          return (
            <AdvancedMarker
              key={item.code}
              position={{ lat: +item.latitude, lng: +item.longitude }}
              onClick={() => onItemFocus(item)}
            >
              <img src={GetMarkerIcon(item)} alt="Map marker" />
            </AdvancedMarker>
          );
        })}
      </Map>
    </div>
  );
};

export default LocationsMap;
