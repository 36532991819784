import React from "react";

import styles from "./LinkButton.module.scss";

//! This component will be replaced by an Input in the component library (seperate project). Proof of concept.

interface IProps {
  disabled?: boolean;
  clickCallback: () => void;
  children?: React.ReactNode;
  fontColour?: string;
  fontSize?: string;
  isGreen?: boolean;
  isBold?: boolean;
  showUnderline?: boolean;
}

const LinkButton = ({
  disabled = false,
  clickCallback,
  children,
  fontColour,
  fontSize,
  isGreen = true,
  isBold = false,
  showUnderline = true,
}: IProps) => (
  <button
    onClick={() => clickCallback()}
    className={`${styles.linkButton} ${isGreen && styles.isGreen}`}
    style={{
      color: fontColour,
      fontSize: fontSize ?? "",
      fontWeight: isBold ? "bold" : "",
      textDecoration: showUnderline ? "underline" : "",
    }}
    disabled={disabled}
    type="button"
  >
    {children}
  </button>
);

export default LinkButton;
