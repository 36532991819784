export class Endpoint {
  /**
   * The formatted endpoint with data
   */
  public readonly endpoint: string;

  private constructEndpoint = (path: string, ...data: string[]): string => {
    return path.replace(/{([1-9]+)}/g, function (match, index) {
      return typeof data[index - 1] == "undefined" ? match : data[index - 1];
    });
  };

  public constructor(
    public readonly unformattedPath: string,
    public readonly data: string[] = [],
    public readonly regexPath: string,
    public readonly retryPolicy: { GET: boolean; POST: boolean }
  ) {
    this.endpoint = this.constructEndpoint(unformattedPath, ...data);
  }
}

export interface APIConfig {
  baseURL: string;
  endpoints: { [key: string]: (...args: string[]) => Endpoint };
}

const makeEndpoint = (
  path: string,
  data: string[] = [],
  getRetry: boolean,
  postRetry: boolean
): Endpoint => {
  const regexPath = path.replace(/\{[^}]+}/g, "[^/]+") + ".*$";
  return new Endpoint(path, data, regexPath, {
    GET: getRetry,
    POST: postRetry,
  });
};

const APIS = {
  WEBSITE_API: {
    baseURL: getEnvVariable("REACT_APP_WEBSITE_API_URL"),
    endpoints: {
      userAddresses: () => makeEndpoint("/User/Addresses", [], true, false),
      addressCountries: () =>
        makeEndpoint("/address/countries", [], true, true),
    },
  },
  APP_API: {
    baseURL: getEnvVariable("REACT_APP_API_URL"),
    endpoints: {
      paymentCapture: (gateway: string) =>
        makeEndpoint(`/Payment/{1}/capture`, [gateway], false, true),
      paymentCancel: (gateway: string) =>
        makeEndpoint(`/Payment/{1}/cancel`, [gateway], false, true),
      paymentComplete: (intentId: string) =>
        makeEndpoint(`/Payment/complete/{1}`, [intentId], false, true),
      orderExport: () => makeEndpoint(`/Order/export`, [], false, true),
      orderSummary: () => makeEndpoint(`/Order/summary`, [], true, false),
    },
  },
};

interface RetryPolicy {
  GET: boolean;
  POST: boolean;
}

interface LookupEntry {
  pattern: RegExp;
  retryPolicy: RetryPolicy;
}

interface EndpointLookup {
  direct: Record<string, RetryPolicy>;
  patterns: LookupEntry[];
}

const initialiseEndpointLookup = (apis: typeof APIS): EndpointLookup => {
  const lookup: EndpointLookup = { direct: {}, patterns: [] };

  for (const api of Object.values(apis)) {
    for (const [, endpointFactory] of Object.entries(api.endpoints)) {
      const endpoint = endpointFactory("{1}");
      lookup.direct[endpoint.unformattedPath] = endpoint.retryPolicy;
      lookup.patterns.push({
        pattern: new RegExp(endpoint.regexPath, "i"),
        retryPolicy: endpoint.retryPolicy,
      });
    }
  }

  return lookup;
};

function getEnvVariable(key: string): string {
  const value = process.env[key];
  if (value === undefined) {
    throw new Error(`Environment variable ${key} is not set`);
  }
  return value;
}
const endpointLookup = initialiseEndpointLookup(APIS);

export { APIS, endpointLookup };
