import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { invokeShippingInfoGA4Event } from "../../../../utils/analytics/ga4";
import API from "../../../../utils/api";
import { APIS, ZENDESK_URL } from "../../../../utils/constants";
import { handleSubmitError } from "../../../../utils/helpers";
import { useStageRedirect } from "../../../../utils/hooks";
import { useStateValue } from "../../../../utils/state-provider";
import { Stages } from "../../../../utils/types";
import { IStore } from "../../../../utils/types/branches";
import Button from "../../../Forms/PlaceholderComponents/Button";
import ErrorComponent from "../../../Forms/PlaceholderComponents/Error";
import LocationOption from "./LocationOption";
import styles from "./LocationPicker.module.scss";

interface IProps {
  storeList: IStore[];
  focusedItem: any;
  onItemFocus: Function;
  isParcelShop: boolean;
  setStoreList: any;
  searching: boolean;
}

type FormValues = {
  code: string;
  address: any;
};

export const LocationPicker = ({
  storeList,
  focusedItem,
  onItemFocus,
  isParcelShop,
  setStoreList,
  searching,
}: IProps) => {
  const { t } = useTranslation();
  const [{ profile }, dispatch] = useStateValue();
  const [error, setError] = useState(null); // always null?
  const { stageRedirect } = useStageRedirect();

  const contactUs = ZENDESK_URL + t("paths:/help");

  const initialValues = {
    code: "",
    address: null,
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    if (focusedItem.code === null) {
      return;
    }
    actions.setSubmitting(true);

    let uri = isParcelShop
      ? APIS.deliveryCollectionPoint
      : `${APIS.deliveryCNC}/${focusedItem.code}`;
    let request = isParcelShop
      ? {
          storeName: values.address.storeName,
          address1: values.address.address1,
          postalCode: values.address.postalCode,
          countryCode: profile?.countryCode,
          bookingCode: values.code,
          city: values.address.city,
        }
      : {};

    API.post(uri, request)
      .then(function (response) {
        dispatch({
          type: "changeData",
          newData: response.data,
        });

        if (response?.data?.shipping) {
          dispatch({
            type: "changeShipping",
            shipping: response.data.shipping,
            force: true,
          });
        }

        invokeShippingInfoGA4Event(
          response?.data.summary.items,
          response?.data.summary.currencyCode,
          response?.data.orderTotal,
          response?.data.shipping?.selectedShippingOptionMethods
        );

        if (!response.data.billingAddress) {
          stageRedirect(Stages.BillingAddress);
        } else {
          stageRedirect(Stages.Payment);
        }

        actions.setSubmitting(false);
      })
      .catch(function (error) {
        setError(t("SomethingWentWrong"));
        handleSubmitError(
          error,
          t,
          window.history,
          "POST " + APIS.deliveryCNC + "/{code}",
          null,
          actions
        );
      });
  };

  const selectFirstStore = () => {
    let firstStore: IStore = storeList[0];
    onItemFocus(firstStore);
  };

  const getPillComponentInfo = (store: any): any | undefined => {
    if (isParcelShop) {
      switch (store.locationType) {
        case "SHOP":
          return {
            label: "Store",
            backgroundColour: "#009cde",
            colour: "#efe",
          };
        case "LOCKER":
          return {
            label: "Locker",
            backgroundColour: "#fe7223",
            colour: "#efe",
          };
        default:
          return null;
      }
    }
    return null;
  };

  useEffect(() => {
    selectFirstStore();
  }, [storeList]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={focusedItem ?? initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <ul className={styles.stores}>
              {storeList.length === 0 && searching && (
                <>
                  <li key="sk1" className={styles.store}>
                    <LocationOption
                      value=""
                      name="branchCode"
                      label=""
                      address=""
                      miles=""
                      branchTimes={[]}
                    />
                  </li>
                  <li key="sk2" className={styles.store}>
                    <LocationOption
                      value=""
                      name="branchCode"
                      label=""
                      address=""
                      miles=""
                      branchTimes={[]}
                    />
                  </li>
                </>
              )}
              {storeList.map((item: IStore) => {
                if (
                  item.address.address1 &&
                  item.address.postalCode &&
                  (item.address.city || isParcelShop)
                ) {
                  return (
                    <li
                      key={item.code}
                      className={styles.store}
                      onChange={() => onItemFocus(item)}
                    >
                      <LocationOption
                        isSelected={item.code === focusedItem?.code}
                        value={item.code}
                        name="branchCode"
                        label={item.displayName}
                        branchTimes={item.branchTimes}
                        address={`${item.address.address1}, ${
                          `${item.address.city},` ??
                          `${item.address.address2},` ??
                          ""
                        } ${item.address.postalCode}`}
                        miles={item.miles}
                        pillComponent={getPillComponentInfo(item)}
                        estimatedDelivery={item.estimatedDelivery}
                      />
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>

            {focusedItem && focusedItem.code && (
              <div className={styles.button}>
                <Button disabled={isSubmitting} type="submit">
                  {t("Continue")}
                </Button>
              </div>
            )}

            {error && (
              <div className={styles.error}>
                <ErrorComponent hasBackground={true} contactUs={contactUs}>
                  {error}
                </ErrorComponent>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LocationPicker;
