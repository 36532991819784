import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddressPage from "../../components/Address/AddressPage";
import { performanceGroup } from "../../utils/constants";
import { isAnalyticConsented } from "../../utils/helpers";

export const BillingAddress = () => {
  const { t } = useTranslation();
  useEffect(() => {
    if (isAnalyticConsented(performanceGroup)) {
      ga("send", "pageview", window.location.pathname);
    }
  }, []);
  return <AddressPage billingPage={true} title={t("BillingAddress")} />;
};

export default BillingAddress;
