import * as Sentry from "@sentry/react";
import { getBasketToken } from "./helpers";
import { datadogLogs, HandlerType, StatusType } from "@datadog/browser-logs";
import { IBasketToken, IOrderToken, IUserToken } from "./types/auth";

export enum Severity {
  Fatal = "fatal",
  Error = "error",
  Warning = "warning",
  Log = "log",
  Info = "info",
  Debug = "debug",
}

export function logError(
  error: any,
  name: string,
  tag: string | null = null,
  level: Sentry.SeverityLevel = Severity.Error
) {
  // logging via console is disabled in production. Logging here to maintain the
  // logs in local/staging
  console.log(name, { error }); // TODO Should this be console.error(...) ?

  // Log everything in DataDog
  logErrorToDataDog(error, tag, name, level);

  // Filter the rest for Sentry
  if (
    error?.response?.status === 400 ||
    error?.response?.status === 401 ||
    error?.response?.status === 404
  ) {
    return;
  }

  // Log what's left to Sentry
  logErrorToSentry(error, tag, name, level);
}

function logErrorToSentry(
  error: any,
  tag: string | null,
  name: string,
  level: Sentry.SeverityLevel
) {
  Sentry.configureScope(function (scope) {
    if (tag) scope.setTag("stage", tag);
    if (name) scope.setTransactionName(name);
    if (level) scope.setLevel(level);
    const basketToken = getBasketToken();
    if (basketToken) {
      scope.setUser({
        // !!!!!!!!! DO NOT SEND ANY PII !!!!!!!!!
        id: basketToken.userId.toString(),
        profileId: basketToken.profileId,
        basketId: basketToken.basketId,
      });
    }

    if (
      level === null ||
      level === Severity.Error ||
      level === Severity.Fatal
    ) {
      Sentry.captureException(error);
    }
  });
}

function logErrorToDataDog(
  error: any,
  tag: string | null,
  name: string,
  level: Sentry.SeverityLevel
) {
  let context: any = {
    name,
    transactionName: name,
    error: error,
  };

  if (tag) {
    context = { ...context, stage: tag };
  }

  let ddLevel: StatusType = StatusType.error;
  switch (level) {
    case Severity.Warning:
      ddLevel = StatusType.warn;
      break;

    default:
      break;
  }

  datadogLogs.logger.log(name, context, ddLevel);
}

const expectedErrors = new RegExp(
  `Request failed with status code 401|Request failed with status code 410|Request failed with status code 409`,
  "mi"
);

const ignoreErrors = [
  /Can't find variable: instantSearchSDKJSBridgeClearHighlight/, // https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
  /^No error$/,
  /__show__deepen/,
  /_avast_submit/,
  /Access is denied/,
  /anonymous function: captureException/,
  /Blocked a frame with origin/,
  /can't redefine non-configurable property "userAgent"/,
  /change_ua/,
  /console is not defined/,
  /cordova/,
  /DataCloneError/,
  /Error: AccessDeny/,
  /event is not defined/,
  /feedConf/,
  /ibFindAllVideos/,
  /myGloFrameList/,
  /SecurityError/,
  /MyIPhoneApp/,
  /snapchat.com/,
  /vid_mate_check is not defined/,
  /win\.document\.body/,
  /window\._sharedData\.entry_data/,
  /window\.regainData/,
  /ztePageScrollModule/, // https://gist.github.com/pioug/b006c983538538066ea871d299d8e8bc
  /Network Error/,
  /The network connection was lost/,
  /Could not connect to the server/,
  /Script error\./, // CHKUI-414
  // Don't log errors where tracking is being blocked
  /XHR error POST https:\/\/.*?\.clarity\.ms\/collect/,
  /XHR error POST https:\/\/stats\.g\.doubleclick\.net\/j\/collect.*/,
  /XHR error POST https:\/\/bat\.bing\.com\/p\/insights\/c\/.*/,
  /XHR error POST https:\/\/www\.google-analytics\.com\/j\/collect.*/,
  /XHR error POST https:\/\/.\.trackedweb\.net\/cartInsight./,
  expectedErrors,
];

export function initializeSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN ?? "",
    ignoreErrors: ignoreErrors,
    integrations: [
      /*new Integrations.BrowserTracing()*/
    ],
    denyUrls: [
      "/gtm",
      "/gtm.js",
      "checkout-ui.local.mountainwarehouse.com",
      "local.checkout.ui.mountainwarehouse.com",
    ],
    environment: process.env.REACT_APP_CUSTOM_NODE_ENV ?? "Development",
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production
    autoSessionTracking: false,
    tracesSampleRate: undefined, // Disable Sentry transaction monitoring
    release: process.env.REACT_APP_VERSION ?? "debug-local",
  });
}

export function initialiseDatadog() {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["error", "warn", "info", "log"],
    sessionSampleRate: 100,
    env: (process.env.REACT_APP_CUSTOM_NODE_ENV ?? "Development").toLowerCase(),
    version: process.env.REACT_APP_VERSION ?? "debug-local",
    service: "checkout-ui",
    site: "datadoghq.eu",
    beforeSend: (evt) => {
      //evt.error.config.url = evt.view.url.replace(/email=[^&]*/, "email=REDACTED")

      if (
        (evt.http && [404].includes(evt.http.status_code)) ||
        ignoreErrors.some((rx) => rx.test(evt.message))
      ) {
        return false;
      }

      return true;
    },
  });

  datadogLogs.logger.setContextProperty("Properties", {
    Team: "web",
  });

  datadogLogs.logger.setLevel("info"); // TODO Adjust?

  if (process.env.REACT_APP_CUSTOM_NODE_ENV === "Development") {
    // Pass all DataDog logs to Console in Development mode
    datadogLogs.logger.setHandler([HandlerType.console]);
  }

  datadogLogs.createLogger("msal", {
    level: "warn",
    handler:
      process.env.REACT_APP_CUSTOM_NODE_ENV === "Production"
        ? undefined
        : "http", // Undefined => => default => DataDog | 'http' => DataDog+Console
  });
}

function setLogUserContextFromToken(token: IUserToken) {
  datadogLogs.setUserProperty("id", token.userId);
  datadogLogs.setUserProperty("ProfileId", token.profileId);
  datadogLogs.setUserProperty("IsLoggedIn", token.isLoggedIn);
}

export function setLogUserContextFromOrder(token: IOrderToken) {
  setLogUserContextFromToken(token);
  datadogLogs.setUserProperty("hasOrderToken", true);
  datadogLogs.setUserProperty("BasketId", token.basketId);
  datadogLogs.setUserProperty("OrderId", token.orderId);
  datadogLogs.setUserProperty("OrderTokenExpires", token.exp);

  datadogLogs.logger.info("Order Token detected!");
}

export function setLogUserContextFromBasket(token: IBasketToken) {
  setLogUserContextFromToken(token);
  datadogLogs.setUserProperty("hasBasketToken", true);
  datadogLogs.setUserProperty("BasketId", token.basketId);
  datadogLogs.setUserProperty("BasketTokenExpires", token.exp);

  datadogLogs.logger.info("Basket Token detected!");
}

const logger = datadogLogs.logger;

export { logger };
