import { CookieAttributes } from "js-cookie";

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_WEBSITE = process.env.REACT_APP_WEBSITE_API_URL;

export const APIS = {
  orderAuto: "/Order",
  orderGuest: "/Order/guest",
  orderB2C: "/Order/login",
  dotDigitalTracking: "/Tracking/DotDigital",
  orderLogin: "/Order/login",
  thankYou: "/Order/thankyou",
  export: "/Order/export",
  addressDelivery: "/Address/delivery",
  addressBilling: "/Address/billing",
  delivery: "/Delivery",
  deliveryCNC: "/Delivery/cnc",
  deliveryCollectionPoint: "/Delivery/collectionPoint",
  deliveryOptions: "/Delivery/options",
  paymentOptions: "/Payment/options",
  paymentAuthorize: "/Payment/{1}/authorize",
  paymentCapture: "/Payment/{1}/capture",
  paymentIntent: "/Payment/{1}/paymentIntent",
  paymentSubmit: "/Payment/{1}/submit",
  paymentMobile: "/Payment/mobile/{1}/",
  paymentPayPalCreateOrder: "/Payment/paypal/create/",
  paymentComplete: "/Payment/complete/{1}/",
  userAddresses: "/user/addresses",
  giftcardAdd: "/GiftCard/add",
  giftcardRemove: "/GiftCard/remove",
  giftCardDebit: "/GiftCard/debit",
  storeLocations: "/Proxy/stores/locations",
  findCollectionPoints: "/Proxy/collectionPoints/find",
  paymentMethods: "/Payment/methods",
};

export const storageOrderToken = "order-token";
export const storageBasketToken = "MW.BASKET_TOKEN";
export const storageStage = "stage";
export const storagePayment = "payment";

export const consentCookie = "OptanonConsent";
export const performanceGroup = "C0002";
export const functionalGroup = "C0003";
export const targetingGroup = "C0004";

export const profilesMap = new Map<number, Profile>([
  [
    1,
    {
      id: 1,
      countryCode: "GB",
      currencyCode: "GBP",
      name: "enGB",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_GBP"] as string,
      locale: "en-GB",
      storeLocatorRange: 25,
      distanceUnit: "m",
      countryName: "United kingdom",
    },
  ],
  [
    2,
    {
      id: 2,
      countryCode: "US",
      currencyCode: "USD",
      name: "enUS",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_USD"] as string,
      locale: "en-US",
      storeLocatorRange: 25,
      distanceUnit: "m",
      countryName: "USA",
    },
  ],
  [
    3,
    {
      id: 3,
      countryCode: "AU",
      currencyCode: "AUD",
      name: "enAU",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_AUD"] as string,
      locale: "en-AU",
      storeLocatorRange: 25,
      distanceUnit: "k",
      countryName: "Australia",
    },
  ],
  [
    4,
    {
      id: 4,
      countryCode: "CA",
      currencyCode: "CAD",
      name: "enCA",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_CAD"] as string,
      locale: "en-CA",
      storeLocatorRange: 100,
      distanceUnit: "k",
      countryName: "Canada",
    },
  ],
  [
    5,
    {
      id: 5,
      countryCode: "FR",
      currencyCode: "EUR",
      name: "frFR",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_EUR"] as string,
      locale: "fr-FR",
      storeLocatorRange: 25,
      distanceUnit: "k",
      countryName: "France",
    },
  ],
  [
    6,
    {
      id: 6,
      countryCode: "DE",
      currencyCode: "EUR",
      name: "deDE",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_EUR"] as string,
      locale: "de-DE",
      storeLocatorRange: 25,
      distanceUnit: "k",
      countryName: "Germany",
    },
  ],
  [
    8,
    {
      id: 8,
      countryCode: "EU",
      currencyCode: "EUR",
      name: "enIE",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_EUR"] as string,
      locale: "en-IE",
      storeLocatorRange: 25,
      distanceUnit: "k",
      countryName: "Ireland",
    },
  ],
  [
    9,
    {
      id: 9,
      countryCode: "PL",
      currencyCode: "PLN",
      name: "plPL",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_PLN"] as string,
      locale: "pl-PL",
      storeLocatorRange: 25,
      distanceUnit: "k",
      countryName: "Poland",
    },
  ],
  [
    10,
    {
      id: 10,
      countryCode: "NZ",
      currencyCode: "NZD",
      name: "enNZ",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_NZD"] as string,
      locale: "en-NZ",
      storeLocatorRange: 100,
      distanceUnit: "k",
      countryName: "New Zealand",
    },
  ],
  [
    11,
    {
      id: 11,
      countryCode: "ES",
      currencyCode: "EUR",
      name: "esES",
      isSupported: true,
      stripePublicKey: process.env["REACT_APP_STRIPE_EUR"] as string,
      locale: "es-ES",
      storeLocatorRange: 25,
      distanceUnit: "k",
      countryName: "Spain",
    },
  ],
]);

export enum State {
  Created = 1,
  DeliveryAddress = 2,
  BillingAddress = 3,
  DeliveryQuery = 4,
  DeliveryOption = 5,
  TaxCalculated = 6,
  NumberAssigned = 7,
  PaymentOptions = 8,
  PaymentInProgress = 9,
}

export const miraklEnabledCountries = ["GB"];

//Isle of Man, Guernsey, Jersey
export const areaCodes: { code: string }[] = [
  { code: "IM" },
  { code: "GY" },
  { code: "JE" },
];

export const cookieConfig: CookieAttributes = {
  expires: 1,
  domain: "mountainwarehouse.com",
  secure: true,
  sameSite: "strict",
};

export const VALIDATION_STAGE = "validation";
export const LOGIN_STAGE = "login";
export const DELIVERY_ADDRESS_STAGE = "delivery-address";
export const BILLING_ADDRESS_STAGE = "billing-address";
export const CNC_STAGE = "click-and-collect";
export const COLLECTFROMPOINT_STAGE = "collect-from-point";
export const SHIPPING_STAGE = "shipping";
export const PAYMENT_STAGE = "payment";
export const THANKYOU_STAGE = "thankyou";

export const LOQATE_BASE_URL = "https://api.addressy.com/Capture/Interactive/";
export const LOQATE_RETRIEVE_URL = "Retrieve/v1/json3.ws?";
export const LOQATE_FIND_URL = "Find/v1.1/json3.ws?";
export const LOQATE_API_KEY = "ND52-YU95-TW99-XF73";
export const ZENDESK_URL = "https://support.mountainwarehouse.com/hc/";

export class Profile {
  id: number;
  name: string;
  countryCode: string;
  currencyCode: string;
  isSupported: boolean;
  stripePublicKey: string;
  locale: string;
  storeLocatorRange: number;
  distanceUnit: "k" | "m";
  countryName: string;
  constructor(
    id: number,
    name: string,
    countryCode: string,
    currencyCode: string,
    isSupported: boolean,
    stripePublicKey: string,
    locale: string,
    storeLocatorRange: number,
    distanceUnit: "k" | "m",
    countryName: string
  ) {
    this.id = id;
    this.name = name;
    this.countryCode = countryCode;
    this.currencyCode = currencyCode;
    this.isSupported = isSupported;
    this.stripePublicKey = stripePublicKey;
    this.locale = locale;
    this.storeLocatorRange = storeLocatorRange;
    this.distanceUnit = distanceUnit;
    this.countryName = countryName;
  }
}
