import React from "react";
import { useTranslation } from "react-i18next";
import { currency } from "../../utils/helpers";
import { useStateValue } from "../../utils/state-provider";
import styles from "./Totals.module.scss";

export default function ItemsSummary() {
  const { t } = useTranslation();
  const [{ data, profile }] = useStateValue();

  type taxItem = {
    name: string;
    amount: number;
  };

  return (
    <div className={styles.prices}>
      <dl>
        <div>
          <dt>{t("Items")}</dt>
          <dd>
            {currency(
              data?.summary?.itemsPrice,
              data?.summary?.currencyCode,
              profile?.name ?? "enGB"
            )}
          </dd>
        </div>
        {data?.itemSavings && (
          <div>
            <dt>{t("ItemSavings")}</dt>
            <dd>
              {currency(
                -data?.itemSavings,
                data?.summary?.currencyCode,
                profile?.name ?? "enGB"
              )}
            </dd>
          </div>
        )}
        {data?.summary?.itemsTax &&
          data?.summary?.taxes.map((tax: taxItem) => {
            return (
              <div>
                <dt>{tax.name}</dt>
                <dd>
                  {currency(
                    tax.amount,
                    data?.summary?.currencyCode,
                    profile?.name ?? "enGB"
                  )}
                </dd>
              </div>
            );
          })}
        {data?.summary?.couponDiscount && (
          <div>
            <dt>{t("CouponDiscount")}</dt>
            <dd>
              {currency(
                data?.summary?.couponDiscount,
                data?.summary?.currencyCode,
                profile?.name ?? "enGB"
              )}
            </dd>
          </div>
        )}
        <div>
          <dt>
            <strong>{t("BasketSubtotal")}</strong>
          </dt>
          <dd>
            <strong>
              {currency(
                data?.summary.itemsPrice -
                  (data?.totalSavings ?? 0) +
                  (data?.summary.itemsTax ?? 0),
                data?.summary?.currencyCode,
                profile?.name ?? "enGB"
              )}
            </strong>
          </dd>
        </div>
      </dl>
    </div>
  );
}
