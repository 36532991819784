import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ZENDESK_URL } from "../../../../../utils/constants";

export const ClearpayAfterpay = ({ totalAmount, locale, currency }) => {
  const { t } = useTranslation();
  const contactUs = ZENDESK_URL + t("paths:/help");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.afterpay.com/afterpay-1.x.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <afterpay-placement
        data-intro-text="Pay in"
        data-locale={locale}
        data-currency={currency}
        data-amount={totalAmount}
        data-size="md"
        data-modal-theme="mint"
      ></afterpay-placement>
      <afterpay-placement
        data-type="price-table"
        data-locale={locale}
        data-currency={currency}
        data-amount={totalAmount}
      ></afterpay-placement>
      {/* TODO: Translations to other languages can be done, when required in future as its only available in AU NZ and GB*/}

      <p>
        You will be redirected to the Afterpay website to complete your order.
        <br />
        <a href={contactUs} target="_blank" rel="noreferrer">
          Terms &amp; Conditions
        </a>
      </p>
      <p>&nbsp;</p>
    </>
  );
};

export default ClearpayAfterpay;
