import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Button from "../../components/Forms/PlaceholderComponents/Button";
import ErrorComponent from "../../components/Forms/PlaceholderComponents/Error";
import Totals from "../../components/OrderSummary/Totals";
import WithdrawalNotice from "../../components/WithdrawalNotice/index";
import { invokePaymentInfoGA4Event } from "../../utils/analytics/ga4";
import { API } from "../../utils/api";
import {
  APIS,
  PAYMENT_STAGE,
  ZENDESK_URL,
  performanceGroup,
  storagePayment,
} from "../../utils/constants";
import { isAnalyticConsented } from "../../utils/helpers";
import { useStageRedirect } from "../../utils/hooks";
import useEnabledABTest from "../../utils/hooks/useEnabledABTest";
import { logError } from "../../utils/logger";
import { useStateValue } from "../../utils/state-provider";
import { Stages } from "../../utils/types";
import GiftCard from "./Giftcard";
import PDQ from "./PDQ";
import PayPal from "./PayPal";
import styles from "./Payment.module.scss";
import PaymentMethods from "./PaymentMethods";

export type PaymentMethod = {
  name: string;
  type: string;
};

export const Payment = () => {
  const { t } = useTranslation();
  const [{ data, profile }, dispatch] = useStateValue();
  const [giftCardAllowed, setGiftCardAvailable] = useState(false);
  const [pdqAllowed, setPdqAllowed] = useState<boolean>(false);
  const [isPaymentOptionsLoading, setIsPaymentOptionsLoading] =
    useState<boolean>(true);
  const [csrfToken, setCsrfToken] = useState("");
  const [error, setError] = useState<string | null>(null);
  const { stageRedirect } = useStageRedirect();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >("card");
  const [handlePayment, setHandlePayment] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const contactUs = ZENDESK_URL + t("paths:/help");

  const isABTestEnabled = useEnabledABTest(
    "paypal-test",
    "show-paypal-button-test"
  );

  const getPaymentOptions = async () => {
    setIsLoading(true);
    setIsPaymentOptionsLoading(true);
    await API.get(APIS.paymentOptions, { timeout: 60000 })
      .then(function (response) {
        setGiftCardAvailable(response.data.payment.giftCardAllowed);
        const pdqOption = response.data.payment.availableOptions.find(
          (p: any) => p.name === "Phone"
        );

        let csrfToken = response.headers["x-csrf-token"];
        setCsrfToken(csrfToken);

        if (pdqOption) {
          setPdqAllowed(true);
        }
        dispatch({
          type: "changeData",
          newData: response.data,
        });
        setIsPaymentOptionsLoading(false);
        setError(null);
      })
      .catch(function (error) {
        setError(t("SomethingWentWrong"));
        logError(error, "GET APIS.paymentOptions", PAYMENT_STAGE);
        if (error?.response?.status === 500) {
          stageRedirect(Stages.Error);
        }
        if (error?.response?.status === 400) {
          dispatch({
            type: "changeStage",
            newStage: Stages.Login,
          });
          stageRedirect(Stages.Login);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGiftCardPayment = async () => {
    try {
      setIsLoading(true);
      await API.post(APIS.paymentSubmit.replace("{1}", "GiftCard"), null, {
        headers: { "x-csrf-token": csrfToken },
      });

      stageRedirect(Stages.Receipt);
    } catch (error) {
      logError(
        error,
        "POST" + APIS.paymentSubmit.replace("{1}", "GiftCard"),
        PAYMENT_STAGE
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAnalyticConsented(performanceGroup)) {
      ga("send", "pageview", window.location.pathname);
    }
    getPaymentOptions();
  }, []);

  const isFullyPaidWithGiftCards = () => {
    return data.orderTotal - data.summary.giftCardsTotal <= 0;
  };

  useEffect(() => {
    if (!selectedPaymentMethod) return;

    let updatedData = data;
    updatedData.payment.paymentMethod = selectedPaymentMethod;
    dispatch({
      type: "changeData",
      newData: updatedData,
    });
    localStorage.setItem(storagePayment, selectedPaymentMethod);
  }, [selectedPaymentMethod]);

  return (
    <>
      <div className="content">
        <h2 className={styles.paymentTitle}>{t("Payment")}</h2>

        {isMobile && <Totals showTotalSavings={false} />}

        {data.billingAddress && (
          <>
            {giftCardAllowed && (
              <GiftCard
                csrfToken={csrfToken}
                isLoading={isPaymentOptionsLoading}
              />
            )}

            <PaymentMethods
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              csrfToken={csrfToken}
              setError={setError}
              setHandlePayment={setHandlePayment}
            />

            {selectedPaymentMethod !== "paypal" ? (
              <div className="is-centered">
                <Button
                  disabled={isLoading}
                  dataTestid="place-order-and-pay"
                  onClick={async () => {
                    try {
                      invokePaymentInfoGA4Event(
                        data.summary.items,
                        data.summary.currencyCode,
                        data.orderTotal,
                        selectedPaymentMethod ?? ""
                      );

                      if (isFullyPaidWithGiftCards())
                        await handleGiftCardPayment();
                      else {
                        await handlePayment();
                      }
                    } catch (error) {}
                  }}
                >
                  {t("PayNow")}
                </Button>

                {profile?.countryCode === "DE" ? <WithdrawalNotice /> : null}
              </div>
            ) : (
              isABTestEnabled && <PayPal />
            )}

            {pdqAllowed && (
              <div>
                <h1 className={`${styles.cardTitle}`}>{t("PDQ")}</h1>
                <PDQ csrfToken={csrfToken} />
              </div>
            )}

            {error && (
              <ErrorComponent hasBackground={true} contactUs={contactUs}>
                {error}
              </ErrorComponent>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Payment;
