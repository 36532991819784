export const gtm = () => {
  const HOST = window.location.host;
  let gtmAccount;
  window.dataLayer = window.dataLayer || [];

  if (HOST && HOST !== "checkout.mountainwarehouse.com") {
    gtmAccount = "GTM-WPHBTX";
  } else {
    gtmAccount = "GTM-KLL79R";
  }

  (function (w: any, d: any, s: any, l: any, i: any) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = false;
    j.src = "//www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", gtmAccount);
};
