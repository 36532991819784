import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { enabledForProfile } from "../../utils/helpers";
import { useStateValue } from "../../utils/state-provider";
import ItemNameLink from "../ItemNameLink/index";
import styles from "./OrderSummary.module.scss";
import Totals from "./Totals";


//TODO: Display promo savings.

export const OrderSummary = () => {
  const { t } = useTranslation();
  const [{ data, profile }] = useStateValue();

  return (
    <div className={`content ${styles.summary}`}>
      <h2 className="h5">{t("Order Summary")}</h2>

      <ul>
        {!data || !data.summary ? (
          <li>
            <div className={styles.productImage}>
              <Skeleton width={29} height={44} count={2} />
            </div>
            <div className={styles.itemContent}>
              <strong>
                <Skeleton />
              </strong>
              <span>
                <Skeleton width={20} /> | <Skeleton width={40} />
              </span>
            </div>
          </li>
        ) : null}
        {data?.summary?.items.map((item: any) => {
          return (
            <li key={(item.name + item.colour).replace(" ", "_")}>
              <div className={styles.productImage}>
                <img
                  src={`${item.imageUrl.split("?")[0]}?w=29`}
                  alt={item.name}
                />
              </div>
              <div className={styles.itemContent}>
                {enabledForProfile("DE", profile) ? (
                  <ItemNameLink item={item} />
                ) : (
                  <strong>{item.name}</strong>
                )}
                {(item.colour || item.size) && (
                  <span>{`${item.colour} | ${item.size}`}</span>
                )}
                <div>
                  {t("Quantity")}: {item.quantity}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <Totals />
    </div>
  );
};

export default OrderSummary;
