import { useEffect, useState } from "react";
import { APIS as apis, appApiClient } from "../../api";
import { publishDotDigitalData } from "../analytics/dotDigital";
import { invokePurchaseGA4Event } from "../analytics/ga4";
import { API } from "../api";
import {
  APIS,
  THANKYOU_STAGE,
  functionalGroup,
  performanceGroup,
  storagePayment,
  targetingGroup,
} from "../constants";
import { gtm } from "../gtm";
import { isAnalyticConsented } from "../helpers";
import { logError, logger } from "../logger";
import { useStateValue } from "../state-provider";
import { IAnalytics, IAnalyticsData } from "../types/analytics";
import { ITransactionProduct } from "../types/products";

function getDeviceSize() {
  const WIDTH = document.documentElement.clientWidth;

  const isDevice =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      navigator.userAgent.substr(0, 4)
    );
  let device = null;

  if (WIDTH < 519) {
    device = "MOBILE";
  } else if (WIDTH >= 519 && isDevice) {
    device = "TABLET";
  } else {
    device = "DESKTOP";
  }

  return device;
}

export default function useAnalytics(): any {
  const [{ data, profile, orderTotal }] = useStateValue();
  const [googleReviewSurveyData, setGoogleReviewSurveyData] = useState<any>();
  const [isDataPublishingFinished, setIsDataPublishingFinished] =
    useState<boolean>(false);
  const basketMobilePayUser = "anonymous-checkout@mountainwarehouse.com";

  const updateUserData = async (next: (data: IAnalyticsData) => void) => {
    if (!data) {
      try {
        const uri = apis.APP_API.endpoints.orderSummary().endpoint;
        const response = await appApiClient.get(uri);
        next(response.data);
      } catch (error) {
        console.error("Error fetching order summary", error);
      }
    } else {
      next(data);
    }
  };

  const gaAnalytics = (analyticData: IAnalyticsData): Promise<void> => {
    try {
      let paymentMethod = "unknown";

      if (analyticData.owner.email === basketMobilePayUser) {
        paymentMethod = "mobile pay";
      } else {
        paymentMethod =
          localStorage.getItem(storagePayment) ??
          analyticData.payment.paymentMethod;
      }
      invokePurchaseGA4Event(
        analyticData.orderNumber,
        analyticData.orderTotal,
        analyticData.summary.itemsTax,
        analyticData.summary.deliveryPrice ?? 0,
        analyticData.shipping,
        analyticData.summary.currencyCode,
        analyticData.summary.items,
        paymentMethod
      );
    } catch {
      Promise.reject();
    }
    return Promise.resolve();
  };

  const certonaAnalytics = async (data: any) => {
    const certona = {
      pagetype: "PURCHASE",
      itemid: data.summary.items.map((i: any) => i.productId).join(";"),
      qty: data.summary.items.map((i: any) => i.quantity).join(";"),
      price: data.summary.items.map((i: any) => i.price).join(";"),
      total: data.orderTotal,
      transactionid: data.orderNumber,
      customerid: data.owner.hashedEmail,
      country: profile?.countryCode ?? "GB",
      recommendations: true,
      device: getDeviceSize(),
    };

    // @ts-ignore
    window.certona = certona;

    let scriptElement = document.createElement("script");

    scriptElement.setAttribute(
      "src",
      "https://edge1.certona.net/cd/5472ce51/mountainwarehouse.com/scripts/resonance.js"
    );

    document.head.appendChild(scriptElement);

    // @ts-ignore
    window.certonaRecommendations = function (data: any) {
      // @ts-ignore
      window.certonaResponse = data;
    };
  };

  const attraqtAnalytics = async (data: any) => {
    const pushResponse = (data: any) => {
      if (data?.trackingKey != null && data.orderNumber != null) {
        window.dataLayer = window.dataLayer || [];
        data?.summary?.items.forEach((item: any) => {
          window.dataLayer.push({
            event: "attraqt_purchase",
            product: item.ref + "_" + item.colourCode,
            price: parseInt(item.price.replace(".", "")), // in cents
            quantity: item.quantity,
            currency: data.summary.currencyCode,
            orderId: data.orderNumber.toString(),
            key: data.trackingKey,
          });
        });
      }
    };

    if (!data || !data?.trackingKey) {
      try {
        const uri = apis.APP_API.endpoints.orderSummary().endpoint;
        const response = await appApiClient.get(uri);
        pushResponse(response.data);
      } catch (error: unknown) {
        logError(
          error,
          "GET " + apis.APP_API.endpoints.orderSummary().endpoint,
          THANKYOU_STAGE
        );
      }
    } else {
      pushResponse(data);
      return Promise.resolve();
    }
  };

  const getAnalyticsData = async () => {
    try {
      return API.get(APIS.thankYou)
        .then(function (response) {
          analytics(response.data);
          if (response?.data?.googleReviewSurvey) {
            setGoogleReviewSurveyData(
              response?.data?.googleReviewSurvey?.optInDto
            );
          }
        })
        .catch(function (error) {
          logError(error, "GET " + APIS.thankYou, THANKYOU_STAGE);
          if (!error.response) {
            console.error(error);
          }
        });
    } catch (error) {
      console.error(error);
      return Promise.resolve();
    }
  };

  const updateDotDigital = async () => {
    return API.get(APIS.dotDigitalTracking + "/complete")
      .then(function (response: any) {
        publishDotDigitalData(
          response.data?.accountId,
          response.data?.basketTrackingData
        );
      })
      .catch(function (error) {
        logError(error, "GET " + APIS.dotDigitalTracking, THANKYOU_STAGE);

        if (!error.response) {
          console.error(error);
        }
      });
  };

  const analytics = (analyticsData: IAnalytics) => {
    if (analyticsData != null) {
      try {
        // The receipt event triggers various tags in GTM, dont delete!
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "receipt",
          countryCode: analyticsData?.certonaData.countryCode,
          digitalData: analyticsData?.digitalData.orderDigitalData,
          userData: analyticsData?.digitalData.userDigitalData,
        });

        const page = analyticsData?.digitalData.orderDigitalData.page;
        const transaction =
          analyticsData?.digitalData.orderDigitalData.transaction;

        // eeReceipt (can probably be moved to GTM)
        window.dataLayer.push({
          event: "checkout",
          ecommerce: {
            currencyCode: page?.attributes.siteCurrency,
            purchase: {
              actionField: {
                id: transaction?.transactionId, // Transaction ID. Required for purchases and refunds.
                affiliation: transaction?.transactionAffiliation,
                revenue: transaction?.transactionTotal, // Total transaction value (incl. tax and shipping)
                tax: transaction?.transactionTax,
                shipping: transaction?.transactionShipping,
                coupon: transaction?.transactionCouponCode,
              },
              products: transaction?.transactionProducts.map(
                (x: ITransactionProduct) => ({
                  name: x.name,
                  id: x.sku,
                  price: x.price,
                  variant: x.colour,
                  dimension17: x.colourCode,
                  quantity: x.quantity,
                  brand: x.brandName,
                })
              ),
            },
          },
        });

        // Criteo (can probably be moved to GTM)
        window.dataLayer.push({
          event: "crto_transactionpage",
          crto: {
            email: analyticsData?.certonaData.customerId.toLowerCase() || null, //can be empty string if email not known
            transactionid: transaction?.transactionId,
            products: transaction?.transactionProducts.map((x: any) => ({
              id: x.fullSku,
              price: x.price,
              quantity: x.quantity,
            })),
          },
        });

        //bing remarketing tag
        let productSkus = transaction?.transactionProducts.map(
          (item) => item.sku
        );
        window.dataLayer.push({
          event: "bing_d_rmkt",
          ecomm_prodid: productSkus,
          ecomm_pagetype: "purchase",
        });

        //LadenZeile tag
        let priceList = transaction?.transactionProducts.map((item) =>
          parseFloat(item.price)
        );
        window.dataLayer.push("event", "", {
          ecomm_prodid: productSkus,
          ecomm_pagetype: "purchase",
          ladenzeile_prices: priceList,
        });
      } catch (error) {
        logError(error, "Analytics error");
      }
    }
  };

  const bingRevenueTag = (orderTotal: number, data: any) => {
    // Add the revenue tag
    if (orderTotal == null || data == null) return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "bing_uet_revenue",
      revenue_value: orderTotal,
      currency: data?.summary?.currencyCode,
    });
  };

  useEffect(() => {
    const updateAnalytics = (data: any) => {
      try {
        gtm();

        let attraqt: Promise<void> | null = attraqtAnalytics(data);

        let certona: Promise<void> | null = null;
        let dotDigital: Promise<void> | null = null;
        if (isAnalyticConsented(targetingGroup)) {
          dotDigital = updateDotDigital();
          certona = certonaAnalytics(data);
        }

        let analyticsData = null;
        if (isAnalyticConsented(functionalGroup)) {
          analyticsData = getAnalyticsData();
        }

        if (isAnalyticConsented(performanceGroup)) {
          ga("send", "pageview", window.location.pathname);
        }
        let googleAnalytics = gaAnalytics(data);

        Promise.all([
          attraqt,
          dotDigital,
          analyticsData,
          googleAnalytics,
          certona,
        ])
          .then(() => {
            const toSend = {
              attraqt: !!attraqt,
              dotDigital: !!dotDigital,
              certona: !!certona,
              ga: !!ga,
            };
            logger.info(`SENT Analytics, All Succeeded`, toSend);
          })
          .catch((error) => {
            logger.error("ERROR sending analytics (promise reject)", {}, error);
          })
          .finally(() => setIsDataPublishingFinished(true));
      } catch (error: any) {
        logger.error("Failed to send analytics", {}, error);
        setIsDataPublishingFinished(true);
      }
    };

    if (sessionStorage.getItem("analyticsComplete") === "true") {
      setIsDataPublishingFinished(true);
      return;
    }
    updateUserData(updateAnalytics);
    sessionStorage.setItem("analyticsComplete", "true");

    bingRevenueTag(orderTotal, data);
  }, []);

  return [isDataPublishingFinished, googleReviewSurveyData];
}
