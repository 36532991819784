import { useAccount, useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { B2cLoginApiClient } from "../../utils/api";
import { protectedResources } from "../../utils/authConfig";
import { APIS, storageBasketToken } from "../../utils/constants";
import { getFullPath, getSetPropertyNames } from "../../utils/helpers";
import LoadWidget from "../../components/LoadWidget";
import { logError } from "../../utils/logger";
import { datadogLogs } from "@datadog/browser-logs";

export const TokenHandler = ({
  successfulLoginHandler,
  failureLoginHandler,
  children,
}: any) => {
  const { instance, accounts, inProgress } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const account = useAccount(accounts[0] || {});
  const { t } = useTranslation();

  function setTokenHeader(config: any, token: string) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.BasketToken = Cookies.get(storageBasketToken);
    return config;
  }

  useEffect(() => {
    if (account) {
      if (!account.localAccountId) {
        const setProperties = getSetPropertyNames(account);
        datadogLogs.logger.warn(
          "Account is available but localAccountId is not set.",
          {
            evt: { name: "b2c", setProperties: setProperties },
          }
        );
      } else if (inProgress === "none") {
        datadogLogs.logger.info("B2C Login success", {
          evt: { name: "b2c", outcome: "success" },
          accountId: account.localAccountId,
        });
        setIsLoading(true);
        instance
          .acquireTokenSilent({
            scopes: protectedResources.api.scopes,
            account: account,
          })
          .then((response) => {
            datadogLogs.logger.info("Access Token success", {
              evt: { name: "b2c", outcome: "success" },
              correlationId: response.correlationId,
            });
            setIsLoading(true);

            if (!Cookies.get(storageBasketToken)) {
              datadogLogs.logger.warn("Redirect due to missing basket token", {
                evt: { name: "b2c" },
                correlationId: response.correlationId,
              });
              // NOTE Possibly use `failureLoginHandler` without error?
              localStorage.clear();
              window.location.href = getFullPath(t, "redirectToBasket");
            }
            B2cLoginApiClient.interceptors.request.use(
              (config) => setTokenHeader(config, response.accessToken),
              (error) => Promise.reject(error)
            );

            B2cLoginApiClient.post(APIS.orderB2C)
              .then((response) => {
                successfulLoginHandler(response, {
                  name: "b2c",
                  outcome: "success",
                });
              })
              .catch((error) => {
                failureLoginHandler(error, {
                  name: "b2c",
                  outcome: "failure",
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          })
          .catch((error) => {
            datadogLogs.logger.info("Access Token failure", {
              evt: { name: "b2c", outcome: "failure" },
              error: error,
            });
            // silent token acquisition fails. Log as an error maybe.
            logError(error, "Token Error");
          });
      }
    } else if (inProgress === "handleRedirect") {
      // User is being redirected back from ab2c to the CHKUI, account for a moment is null and we are waiting to get the token
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [account, inProgress, instance]);

  if (isLoading) return <LoadWidget></LoadWidget>;
  return <>{children}</>;
};

export default TokenHandler;
