import { IItem } from "../types/products";
import { IShipping, SelectedShipmentViewModel } from "../types/shipping";
import {
  GA4Data,
  GA4Item,
  GA4PaymentData,
  GA4PurchaseData,
  GA4ShippingData,
} from "./types";

function invokeGA4Event(event: string, ecommerce: GA4Data) {
  window.dataLayer.push({ ecommerce: null });
  window.gtag("event", event, ecommerce);
}

function createGA4Item(item: IItem, currencyCode: string, index: number) {
  let ga4Item: GA4Item = {
    item_id: item.ref,
    item_name: item.standardName,
    size: item.size,
    affiliation: item.sellerName,
    coupon: item.couponCode,
    currency: currencyCode,
    discount: item.priceDifference,
    index: index,
    item_brand: item.brandName,
    item_category: item.category,
    item_category2: item.categoryL2,
    item_category3: item.categoryL3,
    item_category4: item.categoryL4,
    item_variant: item.colourCode,
    item_list_name: item.listName,
    creative_slot: item.selectPromotion?.creativeSlot,
    location_id: item.selectPromotion?.locationId,
    promotion_name: item.selectPromotion?.promotionName,
    price: item.rootPrice,
    quantity: item.quantity,
    dimension1: item.sellerName ?? "Mountain Warehouse",
  };

  return ga4Item;
}

function getDeliveryOptionNames(
  deliveryOptions: SelectedShipmentViewModel[]
): string {
  let shippingNames = "";

  shippingNames = deliveryOptions
    .map((deliveryOption: SelectedShipmentViewModel) => {
      return `${deliveryOption.shipmentId} ${deliveryOption.name}`;
    })
    .join(" | ");

  return shippingNames;
}

export function invokeShippingInfoGA4Event(
  items: IItem[],
  currencyCode: string,
  basketTotal: number,
  deliveryOptions: SelectedShipmentViewModel[]
) {
  let ga4Items: GA4Item[] = [];

  items.forEach((item: IItem, index: number) => {
    ga4Items.push(createGA4Item(item, currencyCode, index));
  });

  let ecommerce: GA4ShippingData = {
    currency: currencyCode,
    value: basketTotal,
    coupon: items.find((i) => i.coupon !== null || undefined || "")?.coupon, //Potentially a coupon is applied that doesn't apply to all products.
    shipping_tier: getDeliveryOptionNames(deliveryOptions),
    items: ga4Items,
  };

  invokeGA4Event("add_shipping_info", ecommerce);
}

export function invokePaymentInfoGA4Event(
  items: IItem[],
  currencyCode: string,
  basketTotal: number,
  paymentType: string
) {
  let ga4Items: GA4Item[] = [];

  items.forEach((item: IItem, index: number) => {
    ga4Items.push(createGA4Item(item, currencyCode, index));
  });

  let ecommerce: GA4PaymentData = {
    currency: currencyCode,
    value: basketTotal,
    coupon: items.find((i) => i.coupon !== null || undefined || "")?.coupon, //Potentially a coupon is applied that doesn't apply to all products.
    payment_type: paymentType,
    items: ga4Items,
  };

  invokeGA4Event("add_payment_info", ecommerce);
}

export function invokePurchaseGA4Event(
  orderNumber: number,
  orderTotal: number,
  itemsTax: number,
  deliveryPrice: number,
  shipping: IShipping,
  currencyCode: string,
  items: IItem[],
  paymentMethod: string
) {
  let ga4Items: GA4Item[] = [];

  items.forEach((item: IItem, index: number) => {
    ga4Items.push(createGA4Item(item, currencyCode, index));
  });

  let ecommerce: GA4PurchaseData = {
    transaction_id: orderNumber,
    value: orderTotal,
    tax: itemsTax,
    shipping: deliveryPrice,
    shipping_option: shipping?.selectedShippingOptionMethods[0]?.name ?? "",
    currency: currencyCode,
    coupon: items.find((i) => i.couponCode)?.couponCode ?? "",
    payment_method: paymentMethod,
    items: ga4Items,
  };
  invokeGA4Event("purchase", ecommerce);
}
