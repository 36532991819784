import React from "react";
import { PaymentMethod } from "../../pages/Payment";
import { useStateValue } from "../../utils/state-provider";
import ListOptionWrap from "../Forms/ListOptionWrap";
import { useTranslation } from "react-i18next";
import useEnabledABTest from "../../utils/hooks/useEnabledABTest";

interface IPaymentListOption {
  method: PaymentMethod;
  index: number;
  children: React.ReactNode;
  selectedPaymentMethod: string | null;
  setSelectedPaymentMethod: (paymentMethod: string) => void;
}
const PaymentListOption = ({
  method,
  index,
  children,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}: IPaymentListOption) => {
  const { t } = useTranslation();
  const [{ profile }] = useStateValue();
  const countryCode = profile?.countryCode ?? "GB";

  function getImagesSources(method: string): string[] {
    switch (method.toLowerCase()) {
      case "card":
        return countryCode === "US" || countryCode === "CA"
          ? [
              "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-amex.svg",
              "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-mastercard.svg",
              "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-visa.svg",
            ]
          : [
              "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-mastercard.svg",
              "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-visa.svg",
            ];
      case "afterpay":
        return [
          "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-afterpay.svg",
        ];
      case "clearpay":
        return [
          "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-clearpay.svg",
        ];
      case "klarna":
        return [
          "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-klarna.svg",
        ];
      case "paypal":
        return [
          "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-paypal.svg",
        ];
      case "blik":
        return [
          "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-blik.svg",
        ];
      case "mobile pay":
        return [
          "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-google-pay.svg",
          "https://img.cdn.mountainwarehouse.com/common/payment-icons/icon-payment-apple-pay.svg",
        ];
    }

    return [""];
  }

  function getInnerLabel(method: string) {
    switch (method.toLowerCase()) {
      case "card":
        return t("cardPaymentInnerLablel");
      case "afterpay_clearpay":
        return t("afterpayClearpayInnerLablel");
      case "klarna":
        return t("cardPaymentInnerLablel");
      case "paypal":
        return t("klarnaPaymentInnerLablel");
      case "mobile":
        return t("mobilePaymentInnerLablel");
      default:
        return "";
    }
  }

  const onSelect = (paymentMethod: string) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const isABTestEnabled = useEnabledABTest("second-line", "second-line-test");

  return (
    <ListOptionWrap
      name={"paymentMethod"}
      label={method.name}
      innerLabel={isABTestEnabled ? getInnerLabel(method.type) : ""}
      type="radio"
      key={index}
      images={getImagesSources(method.name)}
      value={`${method.type}${
        method.type === "paypal" ? "_paymentMethod" : ""
      }`}
      checked={method.type === selectedPaymentMethod}
      currencyCode={countryCode}
      onClick={() => onSelect(method.type)}
    >
      {children}
    </ListOptionWrap>
  );
};

export default PaymentListOption;
