import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  API_URL_WEBSITE,
  DELIVERY_ADDRESS_STAGE,
  miraklEnabledCountries,
  areaCodes,
} from "../../utils/constants";
import { getOrderToken, isMiraklOrder } from "../../utils/helpers";
import { logError } from "../../utils/logger";
import Loqate, {
  ILoqateAddress,
  ILoqateRetrieveResponse,
  ILoqateFindResponse,
} from "../../utils/loqate";
import { useStateValue } from "../../utils/state-provider";
import InputWrap from "../Forms/InputWrap";
import styles from "./AddressFinder.module.scss";
import { IAddress, ICountry } from "../../utils/types/address";
import { useOutsideAlerter } from "../../utils/hooks";

interface AddressFinderProps {
  pickedAddress?: IAddress | null;
  setPickedAddress: React.Dispatch<React.SetStateAction<IAddress | null>>;
  firstName: string | undefined;
  lastName: string | undefined;
  phone: string | undefined;
  billingPage: boolean | undefined;
  selectedCountryCode: string | undefined;
}

export const AddressFinder = ({
  firstName,
  lastName,
  phone,
  pickedAddress,
  setPickedAddress,
  billingPage,
  selectedCountryCode,
}: AddressFinderProps) => {
  const { t } = useTranslation();
  const [{ data, profile, lastShipping }] = useStateValue();
  const [input, setInput] = useState<string>("");
  const [showInput, setShowInput] = useState<boolean>(false);
  const [foundAddresses, setFoundAddresses] = useState<ILoqateFindResponse>();
  const [showAddressOptions, setShowAddressOptions] = useState<boolean>(false);
  const [showCountryOptions, setShowCountryOptions] = useState<boolean>(false);

  const [countryCode, setCountryCode] = useState<string>(
    profile?.countryCode ?? "GB"
  );
  const [locations, setLocations] = useState<{
    Countries: [];
    Provinces: [];
  } | null>(null);

  useEffect(() => {
    if (selectedCountryCode) setCountryCode(selectedCountryCode);
  }, [selectedCountryCode]);

  const searchContainerRef = useRef<HTMLDivElement>(null);
  const countryContainerRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOutsideAlerter(searchContainerRef, () => {
    setShowInput(false);
    setShowAddressOptions(false);
  });
  useOutsideAlerter(countryContainerRef, () => {
    setShowInput(false);
    setShowCountryOptions(false);
  });

  const getCountriesAndProvinces = async () => {
    const orderToken = getOrderToken();

    if (orderToken) {
      try {
        let response = await Axios.get(
          `${API_URL_WEBSITE}address/countries/${orderToken.profileId}`
        );
        let countries = !billingPage
          ? response.data.DeliveryCountries
          : response.data.BillingCountries;
        if (isMiraklOrder(data.orderType)) {
          countries = countries.filter((country: ICountry) =>
            miraklEnabledCountries.some((mec) => mec === country.CountryCode)
          );
        }
        setLocations({
          Countries: countries,
          Provinces: response.data.Provinces,
        });
      } catch (error: any) {
        logError(
          error,
          `GET ${API_URL_WEBSITE}address/countries/${orderToken.profileId}`,
          DELIVERY_ADDRESS_STAGE
        );
        throw error;
      }
    }
  };

  const getDefaultCountryOption = () => {
    let countryOption = null;

    if (locations && locations.Countries) {
      let currentCountryCode = countryCode ?? profile?.countryCode ?? "GB";

      let country: ICountry = locations.Countries.find(
        (country: ICountry) =>
          country.CountryCode ===
          (currentCountryCode === "EU" ? "IE" : currentCountryCode)
      ) ?? { CountryCode: "", Name: "" };
      if (!showCountryOptions)
        countryOption = (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setShowCountryOptions(true);
              }
            }}
            onClick={() => setShowCountryOptions(true)}
            className={`${styles.countriesText}`}
          >
            {t("ChangeCountry")}
            {country.Name}
          </div>
        );
    }
    return countryOption;
  };

  const generateCountryOptions = () => {
    let countryOptions = null;
    if (locations && locations.Countries && showCountryOptions) {
      countryOptions = locations.Countries.map(
        (country: ICountry, index: number) => {
          return (
            <div
              role="button"
              tabIndex={index}
              onKeyDown={(key) =>
                handleKeydown(
                  key,
                  country.CountryCode,
                  index,
                  countryContainerRef
                )
              }
              onClick={() => {
                setCountry(country.CountryCode);
                if (inputRef) inputRef?.current?.focus();
                setShowCountryOptions(false);
              }}
              className={`${styles.option}`}
            >
              {country.Name}
            </div>
          );
        }
      );
    }

    return countryOptions;
  };

  const setCountry = (value: string) => {
    if (value.length === 2) {
      setCountryCode(value);
    }
  };

  useEffect(() => {
    getCountriesAndProvinces();
  }, []);

  const searchForAddress = async (value: string) => {
    setInput(value);

    if (!value || value.trim().length === 0) {
      setShowInput(true);
      setShowCountryOptions(false);
      setShowAddressOptions(false);
      return;
    }

    let lookup = new Loqate();
    let result = await lookup.find({
      text: value,
      containerId: "",
      countries: [countryCode],
    });
    if (result) {
      setShowCountryOptions(false);
      setShowAddressOptions(true);
      setFoundAddresses(result);
    }
  };

  const generateAddresses = (addresses?: ILoqateFindResponse) => {
    let options: JSX.Element[] = [];
    let addressList = addresses ?? foundAddresses;
    if (addressList && addressList?.Items.length > 0) {
      options = addressList.Items.map((item: any, index: number) => {
        return (
          <div
            role="button"
            id={`Address_${index}`}
            key={index}
            tabIndex={index}
            className={`${styles.option}`}
            onClick={() => selectAddress(item)}
            onKeyDown={(key) =>
              handleKeydown(key, item, index, searchContainerRef)
            }
          >
            {`${item.Text}, ${item.Description}`}
          </div>
        );
      });
    }
    return options;
  };

  const selectAddress = async (address: any) => {
    if (address.Type !== "Address") {
      let lookup = new Loqate();
      let result = await lookup.find({
        text: input,
        containerId: address.Id,
        countries: [countryCode],
      });
      if (result) {
        setFoundAddresses(result);
      } else {
        setSelectedAddress(address.Id);
      }
    } else {
      setSelectedAddress(address.Id);
    }
  };

  const setSelectedAddress = async (id: string) => {
    let lookup = new Loqate();
    let result: ILoqateRetrieveResponse | undefined;
    let selectedAddress: ILoqateAddress | undefined;

    try {
      result = await lookup.retrieve(id);
      if (!result) {
        throw new Error("No address results returned");
      }
      if (result.Items.length === 1) {
        selectedAddress = result.Items[0];
      } else if (result.Items.length > 1) {
        if (result.Items[0].CountryIso2 === "CA") {
          selectedAddress =
            result.Items.find(
              (item) => item.ProvinceCode === "QC" && item.Language === "FRE"
            ) || result.Items.find((item) => item.Language === "ENG");
        }
        selectedAddress = selectedAddress || result.Items[0];
      } else {
        throw new Error("No addresses in result");
      }
    } catch (error) {
      logError(error, "Address Retrieve Error", DELIVERY_ADDRESS_STAGE);
      return;
    }

    const lastWasDelivery =
      (lastShipping?.isCnC ?? lastShipping?.isCollectFromPoint ?? false) ===
      false;
    const thisIsDelivery =
      (data.shipping?.isCnC ?? data.shipping?.isCollectFromPoint ?? false) ===
      false;
    const shouldFillIn = lastWasDelivery && thisIsDelivery;

    let areaCode = selectedAddress.PostalCode.substring(0, 2);
    let countryCode = selectedAddress.CountryIso2;
    if (areaCodes.some((c) => c.code === areaCode)) {
      countryCode = "GB";
    }
    let address: IAddress = {
      firstName: shouldFillIn
        ? firstName ??
          data?.deliveryAddress?.firstName ??
          pickedAddress?.firstName
        : firstName,
      lastName: shouldFillIn
        ? lastName ?? data?.deliveryAddress?.lastName ?? pickedAddress?.lastName
        : lastName,
      companyName: selectedAddress.Company,
      street: countryCode === "DE" ? selectedAddress.Line1 : "",
      addressLine1: countryCode !== "DE" ? selectedAddress.Line1 : "",
      addressLine2: selectedAddress.Line2,
      province: selectedAddress.ProvinceCode,
      postCode: selectedAddress.PostalCode,
      city: selectedAddress.City,
      countryCode: countryCode,
      phone: shouldFillIn
        ? phone ?? data?.deliveryAddress?.phone ?? pickedAddress?.phone
        : phone,
      billingAddress: true,
    };

    setPickedAddress(address);
    setShowAddressOptions(false);
  };

  const handleKeydown = (e: any, item: any, index: number, selector: any) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      if (selector) {
        e.preventDefault();
        try {
          let i = e.key === "ArrowDown" ? index + 1 : index - 1;
          let nextItem = selector?.current?.querySelectorAll("div")[i];

          nextItem?.focus();
        } catch (exception) {}
      }
    }

    if (e.key === "Enter" && item) {
      if (selector === searchContainerRef) {
        selectAddress(item);
      } else if (selector === countryContainerRef) {
        setCountry(item);
        if (inputRef) inputRef?.current?.focus();
        setShowCountryOptions(false);
      }
    }

    if (e.key === "Tab") {
      setShowCountryOptions(false);
      setShowAddressOptions(false);
    }
  };

  const openInput = (value: string) => {
    setShowInput(true);

    if (value) {
      searchForAddress(value);
    }
  };

  return (
    <>
      <InputWrap
        name={"AddressFinder"}
        label={t("SearchForAddress")}
        placeholder={t("address:AddressFinderPlaceholder")}
        onFocus={(e) => openInput(e.currentTarget.value)}
        onChange={(e) => searchForAddress(e.currentTarget.value)}
        autocomplete="off"
        ref={inputRef}
        iconPath={
          "https://img.cdn.mountainwarehouse.com/svg/icon-search-large.svg"
        }
      />

      {showInput && !input && (
        <div className={styles.selectContainer}>
          <div
            ref={countryContainerRef}
            id={"countryOptions"}
            className={`${styles.countrySelect}`}
          >
            {getDefaultCountryOption()}
            {generateCountryOptions()}
          </div>
        </div>
      )}

      {showInput && showAddressOptions && (
        <div className={styles.selectContainer}>
          <div
            ref={searchContainerRef}
            id={"addressOptions"}
            className={`${styles.addressSelect}`}
            style={
              foundAddresses?.Items.length && foundAddresses?.Items.length <= 2
                ? { maxHeight: "2rem", width: "100%" }
                : { maxHeight: "10rem", width: "100%" }
            }
          >
            {generateAddresses(foundAddresses)}
          </div>
        </div>
      )}
    </>
  );
};
