import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { performanceGroup } from "../../utils/constants";
import { getOrderToken, isAnalyticConsented } from "../../utils/helpers";
import { useStateValue } from "../../utils/state-provider";
import { Stages } from "../../utils/types";
import ClickCollect from "../ClickCollect";
import CollectFromPoint from "../CollectFromPoint";
import AddressForm from "./AddressForm";
import { useStageRedirect } from "../../utils/hooks";
import { Route, Routes } from "react-router-dom";

// API: /api/Address/delivery

export const DeliveryAddress = () => {
  const { t } = useTranslation();
  const [{ data }, dispatch] = useStateValue();
  const { stageRedirect } = useStageRedirect();

  useEffect(() => {
    if (isAnalyticConsented(performanceGroup)) {
      ga("send", "pageview", window.location.pathname);
    }

    dispatch({
      type: "changeShipping",
      shipping: [],
      force: true,
    });

    let token = getOrderToken();
    if (token?.email === "anonymous-checkout@mountainwarehouse.com") {
      stageRedirect(Stages.Login);
    }
  }, []);

  if (!data.shipping.isDeliveryAddressRequired) {
    stageRedirect(Stages.BillingAddress);
  }

  return (
    <>
      <h1>{t("DeliveryMethod")}</h1>
      <Routes>
        <Route path={""} element={<AddressForm />} />
        <Route
          path={`${t("paths:/click-and-collect")}`}
          element={<ClickCollect />}
        />
        <Route
          path={`${t("paths:/collect-from-point")}`}
          element={<CollectFromPoint />}
        />
      </Routes>
    </>
  );
};

export default DeliveryAddress;
