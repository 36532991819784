import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "../../utils/types";
import SelectWrap from "../Forms/SelectWrap";

type IProps = {
  locations: { CountryCode: string; Name: string }[];
  defaultCountry: string;
  setCurrentCountryCode: any;
  pickedAddress: any;
};

export const CountrySelect = ({
  locations,
  defaultCountry,
  setCurrentCountryCode,
  pickedAddress,
}: IProps) => {
  const [countryOptions, setCountryOptions] = useState<Option[]>();
  const [selectedCountry, setSelectedCountry] = useState<Option>();
  const { t } = useTranslation("address");

  useEffect(() => {
    let countries = generateCountries(locations);
    setCountryOptions(countries);
  }, [locations]);

  useEffect(() => {
    const selectCountry = (options: Option[], item: string) => {
      let selected = options.find((x) => x.value === item);
      if (selected) handleSelect(selected);
    };

    if (pickedAddress && countryOptions) {
      selectCountry(countryOptions, pickedAddress.countryCode);
    } else if (!selectedCountry && countryOptions) {
      if (defaultCountry === "EU") defaultCountry = "IE";
      selectCountry(countryOptions, defaultCountry);
    }
  }, [pickedAddress, defaultCountry, countryOptions]);

  const generateCountries = (
    locations: { CountryCode: string; Name: string }[]
  ) =>
    locations.map((item: any) => {
      return { value: item.CountryCode, label: item.Name };
    });

  const handleSelect = (country: Option) => {
    setSelectedCountry(country);
    setCurrentCountryCode(country.value);
  };

  return (
    <SelectWrap
      name="countryCode"
      label={t("countryCode")}
      value={selectedCountry}
      required={true}
      options={countryOptions}
      onChange={handleSelect}
      placeholder={t("address:SelectCountry")}
    />
  );
};

export default CountrySelect;
