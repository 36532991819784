import { PaymentMethodResult, Stripe, StripeElements } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorComponent from "../../../../components/Forms/PlaceholderComponents/Error";
import LoadWidget from "../../../../components/LoadWidget";
import { storageStage } from "../../../../utils/constants";
import { logError } from "../../../../utils/logger";
import { useStateValue } from "../../../../utils/state-provider";
import { Stages } from "../../../../utils/types";
import { IPaymentFormProps } from "../IPaymentFormProps";
import ClearpayAfterpay from "./ClearpayAfterpay/ClearpayAfterpay";

interface IRedirectPayment extends IPaymentFormProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
}

const RedirectPayment = ({
  paymentIntentHandler,
  submitHandler,
  methods,
  billingAddress,
  stripe,
  elements,
}: IRedirectPayment) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [{ orderTotal, profile }] = useStateValue();

  const [formError, setFormError] = useState<string | null>(null);
  const contactUs = process.env.REACT_APP_WEBSITE_HOST + t("paths:/help");

  useEffect(() => {
    submitHandler(() => handleSubmit);
  }, []);

  const getPaymentMethod = async (stripe: Stripe | null, type: any) => {
    const billing = {
      address: {
        line1: billingAddress.addressLine1 ?? "",
        line2: billingAddress.addressLine2 ?? "",
        city: billingAddress.city ?? "",
        country: billingAddress.countryCode ?? "",
        postal_code: billingAddress.postCode ?? "",
        state: billingAddress.province ?? "",
      },
      name: `${billingAddress.firstName} ${billingAddress.lastName}` ?? "",
      email: billingAddress.email ?? "",
      phone: billingAddress.phone ?? "",
    };

    let result: PaymentMethodResult | undefined =
      await stripe?.createPaymentMethod({
        type: type,
        billing_details: billing,
      });

    return result;
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setFormError(t("SomethingWentWrong"));
      setIsLoading(false);
      return;
    }

    let result = await getPaymentMethod(stripe, methods[0]);

    if (result?.error || !result?.paymentMethod) {
      setFormError(t("SomethingWentWrong"));
      setIsLoading(false);
      return;
    }

    try {
      const request = {
        paymentMethod: result.paymentMethod,
      };

      var response = await paymentIntentHandler(request, null, null);

      if (response?.data) {
        if (response.data?.requiresAction) {
          const intent = await stripe.retrievePaymentIntent(
            response?.data.paymentIntentClientSecret
          ); // potentially better to pass down intent through props

          if (intent.paymentIntent?.next_action?.type === "redirect_to_url") {
            const url = intent.paymentIntent.next_action.redirect_to_url?.url;
            if (url) {
              localStorage.setItem(
                storageStage,
                Stages.PaymentRedirect.toString()
              );
              window.location.assign(url);
              setIsLoading(false);
            }
          }
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      if (
        error?.response.status === 400 &&
        methods[0].toLowerCase() === "klarna" &&
        error?.response.data === "Invalid phone number for Klarna order"
      ) {
        setFormError(t("KlarnaError"));
      } else {
        setFormError(t("SomethingWentWrong"));
        logError(error, `${methods[0]} payment intent failed.`);
      }
    }
  };

  return (
    <div>
      {isLoading && <LoadWidget />}
      {profile && methods[0].toLowerCase() === "afterpay_clearpay" && (
        <ClearpayAfterpay
          totalAmount={orderTotal}
          locale={profile.locale}
          currency={profile.currencyCode}
        ></ClearpayAfterpay>
      )}
      {formError && (
        <ErrorComponent hasBackground={true} contactUs={contactUs}>
          {formError}
        </ErrorComponent>
      )}
    </div>
  );
};

export default RedirectPayment;
