export function publishDotDigitalData(accountId: any, basketTrackingData: any) {
  (function (w: any, d, u, t: any, o, c) {
    w["dmtrackingobjectname"] = o;
    c = d.createElement(t);
    (c as any).async = 1;
    (c as any).src = u;
    t = d.getElementsByTagName(t)[0];
    t.parentNode.insertBefore(c, t);
    w[o] =
      w[o] ||
      function () {
        (w[o].q = w[o].q || []).push(arguments);
      };
  })(
    window,
    document,
    "//static.trackedweb.net/js/_dmptv4.js",
    "script",
    "dmPt"
  );

  (window as any).dmPt("create", accountId);
  (window as any).dmPt("track");
  (window as any).dmPt("cartInsight", basketTrackingData);
}
