import React, {
  Dispatch,
  Reducer,
  createContext,
  useContext,
  useReducer,
} from "react";
import { AppState } from "..";
import { Action } from "./reducer";
import { Stages } from "./types";

export const StateContext = createContext<[AppState, Dispatch<Action>]>([
  {
    cncEnabled: false,
    data: null,
    shipping: null,
    profile: null,
    stage: Stages.Address,
    orderTotal: 0,
    lastShipping: null,
  },
  () => null,
]);

export const StateProvider = ({
  reducer,
  initialState,
  children,
}: {
  reducer: Reducer<AppState, Action>;
  initialState: AppState;
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={[state, dispatch]}>
      {children}
    </StateContext.Provider>
  );
};
export const useStateValue = () => useContext(StateContext);
