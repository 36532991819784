import React from "react";
import ListOption, {
  ListOptionProps,
} from "./PlaceholderComponents/ListOption";

interface ListOptionWrapProps extends ListOptionProps {
  children: React.ReactNode;
}

export const ListOptionWrap = ({
  name,
  label,
  innerLabel,
  type = "text",
  images = [],
  value,
  extraInfo,
  pillComponent,
  currencyCode,
  skeleton = false,
  checked = false,
  children,
  onClick,
  hasIcon = false, // TODO - pass exact icon
  visible,
  imageStyle,
  bodyStyle,
}: ListOptionWrapProps) => {
  if (checked)
    return (
      <>
        <ListOption
          name={name}
          label={label}
          innerLabel={innerLabel}
          type={type}
          value={value}
          extraInfo={extraInfo}
          pillComponent={pillComponent}
          currencyCode={currencyCode}
          skeleton={skeleton}
          images={images}
          onClick={onClick}
          checked={checked}
          imageStyle={imageStyle}
          bodyStyle={bodyStyle}
        />
        {children}
      </>
    );

  // Giftcard has content on top so buttons pay and apply are not duplicated
  if (visible)
    return (
      <>
        <ListOption
          name={name}
          label={label}
          innerLabel={innerLabel}
          type={type}
          value={value}
          extraInfo={extraInfo}
          pillComponent={pillComponent}
          currencyCode={currencyCode}
          skeleton={skeleton}
          images={images}
          hasIcon={hasIcon}
          onClick={onClick}
          checked={false}
          imageStyle={imageStyle}
          bodyStyle={bodyStyle}
        />
        {children}
      </>
    );
  return (
    <div>
      <ListOption
        name={name}
        label={label}
        innerLabel={innerLabel}
        type={type}
        value={value}
        extraInfo={extraInfo}
        pillComponent={pillComponent}
        currencyCode={currencyCode}
        skeleton={skeleton}
        onClick={onClick}
        images={images}
        checked={checked}
        hasIcon={hasIcon}
        imageStyle={imageStyle}
        bodyStyle={bodyStyle}
      />
    </div>
  );
};

export default ListOptionWrap;
