import { loadStripe, PaymentIntentResult, Stripe } from "@stripe/stripe-js";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { APIS, appApiClient } from "../../../../api";
import LoadWidget from "../../../../components/LoadWidget";
import { PAYMENT_STAGE } from "../../../../utils/constants";
import { getProfile } from "../../../../utils/helpers";
import { useStageRedirect } from "../../../../utils/hooks";
import { logError, Severity } from "../../../../utils/logger";
import { Stages } from "../../../../utils/types";

const PaymentRedirect = () => {
  const parsedQS = queryString.parse(window.location.search);
  const isRedirect =
    parsedQS.payment_intent != null &&
    parsedQS.payment_intent_client_secret != null;

  const { stageRedirect } = useStageRedirect();
  const { t } = useTranslation();

  useEffect(() => {
    let profile = getProfile();
    if (!profile) {
      stageRedirect(Stages.Payment, { isError: true });
    }

    let clientSecret: string;

    if (Array.isArray(parsedQS.payment_intent_client_secret))
      clientSecret = parsedQS.payment_intent_client_secret[0] ?? "";
    else clientSecret = parsedQS.payment_intent_client_secret ?? "";

    const checkIfIntentFailed = async (
      stripe: Stripe | null
    ): Promise<boolean> => {
      const intent: PaymentIntentResult | undefined =
        await stripe?.retrievePaymentIntent(clientSecret);

      if (intent?.error) {
        logError(intent?.error, "Payment Intent Error");

        redirectToPaymentPage();

        return true;
      }

      if (intent?.paymentIntent?.last_payment_error) {
        if (
          intent?.paymentIntent.last_payment_error?.decline_code !==
          "generic_decline"
        ) {
          logError(
            intent?.paymentIntent?.last_payment_error,
            "Payment Intent Error"
          );
          redirectToPaymentPage({
            error: t("SomethingWentWrong"),
            isCustomerError: true,
          });

          return true;
        }

        //If generic_decline is returned just redirect the payment page without error
        //generic_decline can occur if you just close the afterpay/clearpay page
        redirectToPaymentPage();
        return true;
      }

      return false;
    };

    if (!isRedirect) {
      redirectToPaymentPage();
    }

    const completeRedirectPayment = async () => {
      const stripe = await loadStripe(profile?.stripePublicKey ?? "");
      if (!(await checkIfIntentFailed(stripe))) {
        await completePayment(stripe, clientSecret);
      }
    };

    completeRedirectPayment();
  }, []);

  const redirectToPaymentPage = (state: any | undefined = undefined) => {
    stageRedirect(Stages.Payment, state);
  };

  const completePayment = async (
    stripe: Stripe | null,
    clientSecret: string
  ) => {
    if (!stripe) {
      logError(null, "completePayment -> Failed to get stripe");

      return;
    }

    const { error, paymentIntent } = await stripe.retrievePaymentIntent(
      clientSecret
    );

    if (error) {
      logError(
        error,
        "POST stripe.retrievePaymentIntent",
        PAYMENT_STAGE,
        Severity.Fatal
      );

      logError(error, "completePayment -> error");

      stageRedirect(Stages.Receipt, { unsuccessful: true });
    } else if (paymentIntent) {
      //Do not capture payment if intent has generic_error
      if (paymentIntent.last_payment_error?.decline_code === "generic_error") {
        redirectToPaymentPage();
        return;
      }

      if (paymentIntent.payment_method_types[0] === "card") {
        redirectToPaymentPage({
          completed3ds: true,
          paymentIntent: paymentIntent.id,
        });

        return;
      }

      let response = await capturePayment(paymentIntent.id);
      if (response?.status === 200) {
        stageRedirect(Stages.Receipt);
      } else {
        await attemptCancel(paymentIntent.id);
      }
    } else {
      logError(null, "completePayment -> payment intent is null or undefined");

      stageRedirect(Stages.Error);
    }
  };

  const capturePayment = async (paymentIntentId: string) => {
    const data = {
      gatewayReference: paymentIntentId,
    };

    const uri = APIS.APP_API.endpoints.paymentCapture("Stripe").endpoint;
    try {
      return await appApiClient.post(uri, data);
    } catch (error: any) {
      if (error?.response?.status === 409) {
        // payment cancellation failed
        logError(
          error,
          "POST stripe.capture.CancelPayment",
          PAYMENT_STAGE,
          Severity.Fatal
        );
      } else {
        logError(error, uri, PAYMENT_STAGE, Severity.Fatal);
      }
    }
  };

  const attemptCancel = async (paymentIntentId: string) => {
    const uri = APIS.APP_API.endpoints.paymentCancel("Stripe").endpoint;
    try {
      const data = {
        paymentIntentId: paymentIntentId,
      };
      await appApiClient.post(uri, data);
      redirectToPaymentPage({
        error: t("SomethingWentWrong"),
        isCustomerError: true,
      });
    } catch (error: any) {
      logError(error, uri, PAYMENT_STAGE, Severity.Fatal);
      stageRedirect(Stages.Receipt, { unsuccessful: true });
    }
  };

  return (
    <>
      <LoadWidget />
    </>
  );
};

export default PaymentRedirect;
