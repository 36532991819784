import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Option } from "../../utils/types";
import ErrorComponent from "./PlaceholderComponents/Error";
import Select from "./PlaceholderComponents/Select";


interface Props {
  name: string;
  label: string;
  value?: Option;
  id?: string;
  autocomplete?: string;
  required?: boolean;
  onChange?: any;
  children?: React.ReactNode;
  options?: any[] | null;
  placeholder? : string;
}

const SelectWrap = ({
  name,
  label,
  id = name,
  autocomplete = "on",
  value,
  required = false,
  onChange,
  children,
  options,
  placeholder
}: Props) => {
  const { t } = useTranslation();

  const [field, { error, touched }] = useField({
    name: name
  });

  return (
    <div className="input-wrap">
      <label htmlFor={id}>
        {label} {required ? "" : t("Optional")}
      </label>
      <Select
        name={field.name}
        id={id}
        onBlur={field.onBlur}
        onChange={onChange}
        value={value}
        autoComplete={autocomplete}
        error={error ? true : false}
        options={options}
        placeholder={placeholder}
      >
        {children}
      </Select>

      {error && touched && <ErrorComponent>{error}</ErrorComponent>}
    </div>
  );
};

export default SelectWrap;
