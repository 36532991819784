import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "../../pages/Errors/Error/";
import Header from "../Header";
import Tracker from "../Tracker";

interface SiteWrapProps {
  showTracker?: boolean;
  children?: React.ReactNode;
}

export const SiteWrap = ({ showTracker = true, children }: SiteWrapProps) => {
  return (
    <div id="siteWrap" role="none">
      <Header />

      <div role="main" className="body">
        {showTracker && <Tracker />}

        <div className="body__inner">
          <ErrorBoundary FallbackComponent={ErrorPage}>
            {children}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default SiteWrap;
