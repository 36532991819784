import React from "react";
import { useTranslation } from "react-i18next";
import iconSSL from "../../icons/icon-ssl.svg";
import { isLocalStorageAvailable, parsePrefix } from "../../utils/helpers";
import { useStateValue } from "../../utils/state-provider";
import styles from "./header.module.scss";

export const Header = () => {
  const { t } = useTranslation();

  const [{ profile }] = useStateValue();

  const getCountryCode = () => {
    if (profile) {
      if (profile?.countryCode === "GB") return "";
      return profile?.countryCode.toLowerCase() + "/";
    } else {
      const prefix = window.location.pathname.split("/")[1];
      let validPrefix = parsePrefix(prefix);
      return validPrefix ?? "";
    }
  };

  const getLogo = (): string => {
    let brandLogo =
      "//img.cdn.mountainwarehouse.com/common/mw-logo-dark.svg?width=98";

    localStorage.setItem("logo", brandLogo);
    return brandLogo;
  };

  return (
    <div className={`${styles.header}`} id="header" role="banner">
      <a
        className={styles.logo}
        href={`${process.env.REACT_APP_WEBSITE_HOST}${getCountryCode()}`}
      >
        <img
          src={
            isLocalStorageAvailable()
              ? localStorage.getItem("logo") ?? getLogo()
              : ""
          }
          alt="Mountain Warehouse"
          width="146"
        />
      </a>
      <div className={styles.title}>
        <h1>{t("Checkout")}</h1>
      </div>
      <div className={styles.secure}>
        <span>{t("SSLSecure")}</span>
        <img src={iconSSL} alt="SSL Icon" />
      </div>
    </div>
  );
};

export default Header;
