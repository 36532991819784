import jquery from "jquery";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import WebFont from "webfontloader";
import App from "./App";
import { Profile, storageStage } from "./utils/constants";
import "./utils/custom-polyfills";
import "./utils/i18n";
import { initialiseDatadog, initializeSentry } from "./utils/logger";
import Reducer from "./utils/reducer";
import { StateProvider } from "./utils/state-provider";
import { Stages } from "./utils/types";

export type AppState = {
  stage: Stages;
  cncEnabled: boolean;
  data: any | null;
  shipping: any | null;
  profile: Profile | null;
  lastShipping?: any;
  orderTotal: number;
};

const initialState: AppState = {
  stage: Number.parseInt(localStorage.getItem(storageStage) ?? "0"),
  cncEnabled: false,
  shipping: null,
  data: null,
  profile: null,
  lastShipping: null,
  orderTotal: 0,
};

window.$ = window.jQuery = jquery;

WebFont.load({
  google: {
    families: ["Lato"],
  },
});

if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "Development") {
  // Init logging on Staging + Production but not local development
  initializeSentry();
  initialiseDatadog();
}

if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "Production") {
  import("react-axe").then((axe) => {
    axe.default(React, createRoot, 1000);
    createRoot(document.getElementById("root")!).render(
      <StateProvider initialState={initialState} reducer={Reducer}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </StateProvider>
    );
  });
} else {
  console.log = function () {};
  createRoot(document.getElementById("root")!).render(
    <StateProvider initialState={initialState} reducer={Reducer}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </StateProvider>
  );
}
