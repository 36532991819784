import { useField } from "formik";
import React from "react";
import Skeleton from "react-loading-skeleton";
import ListOptionWrap from "../../../../components/Forms/ListOptionWrap";
import Location from "./Location";
import styles from "./LocationOption.module.scss";
import { BranchTime } from "../../../../utils/types/branches";
import { OrderEstimatedDelivery } from "../../../../utils/types/shipping";

interface Props {
  name: string;
  label: string;
  value: string;
  address: string;
  branchTimes: BranchTime[];
  miles: string;
  isSelected?: boolean;
  pillComponent?: {
    label: string;
    backgroundColour: string;
    colour: string;
  };
  estimatedDelivery?: OrderEstimatedDelivery;
}

const LocationOption = ({
  name,
  label,
  value,
  address,
  branchTimes,
  miles,
  isSelected,
  pillComponent,
  estimatedDelivery,
}: Props) => {
  const [field] = useField({
    name: name,
    type: name,
  });

  if (value === "") {
    return (
      <div>
        <div className={styles.skeleton}>
          <div>
            <Skeleton width={200} />
          </div>
          <div>
            <Skeleton width={60} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <ListOptionWrap
      name={name}
      label={label}
      type="radio"
      extraInfo={miles}
      images={[]}
      hasIcon={false}
      value={value}
      onClick={field.onChange}
      checked={isSelected}
      pillComponent={pillComponent}
      bodyStyle={{ padding: "1rem" }}
    >
      <Location
        storeName={label}
        address={address}
        branchTimes={branchTimes}
        estimatedDelivery={estimatedDelivery}
      />
    </ListOptionWrap>
  );
};

export default LocationOption;
