import React, { useEffect } from "react";
import { getSelectedShipmentViewModel } from "../../utils/shipping/getSelectedShipmentViewModel";
import { useStateValue } from "../../utils/state-provider";
import {
  IShipping,
  IShippingOption,
  SelectedShipmentViewModel,
} from "../../utils/types/shipping";
import Shipment from "./Shipment";
import ShippingMethod from "./ShippingMethod";

const SkeletonShipping = () => {
  return (
    <>
      <ShippingMethod
        name=""
        label=""
        shipmentId={1}
        warehouse=""
        rate=""
        onClick={{}}
        deliveryTime=""
        skeleton={true}
        currencyCode=""
        checked={false}
      />
      <ShippingMethod
        name=""
        label=""
        shipmentId={1}
        onClick={{}}
        warehouse=""
        rate=""
        deliveryTime=""
        skeleton={true}
        currencyCode=""
        checked={false}
      />
    </>
  );
};

interface IProps {
  shipping: IShipping | undefined;
  isLoading: boolean;
}

export const ShippingOptions = ({ shipping, isLoading }: IProps) => {
  const [{ data }, dispatch] = useStateValue();

  useEffect(() => {
    if (
      shipping &&
      shipping?.selectedShippingOptionMethods &&
      shipping.availableShippingOptions
    ) {
      let selectedShipments: SelectedShipmentViewModel[] = [];
      let copiedData = JSON.parse(JSON.stringify(shipping));

      copiedData.availableShippingOptions.forEach((option: IShippingOption) => {
        let firstMethodId = option.methods[0]?.shipMethodId;
        let selectedShipment = getSelectedShipmentViewModel(
          option,
          firstMethodId
        );
        if (selectedShipment) selectedShipments.push(selectedShipment);
      });

      copiedData.selectedShippingOptionMethods = selectedShipments;

      dispatch({
        type: "changeShipping",
        shipping: copiedData,
        force: true,
      });
    }
  }, []);

  if (isLoading || !shipping || !data) return <SkeletonShipping />;

  return (
    <>
      {shipping.availableShippingOptions.map(
        (shippingOption: IShippingOption, index: number) => (
          <Shipment shippingOption={shippingOption} index={index} />
        )
      )}
    </>
  );
};

export default ShippingOptions;
