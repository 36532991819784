import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import InputWrap from "../../components/Forms/InputWrap";
import { performanceGroup } from "../../utils/constants";
import {
  isAnalyticConsented,
  getRangeInKmPerProfile,
} from "../../utils/helpers";
import { useStageRedirect } from "../../utils/hooks";
import { useStateValue } from "../../utils/state-provider";
import { Stages } from "../../utils/types";
import { IStore } from "../../utils/types/branches";
import Button from "../Forms/PlaceholderComponents/Button";
import ErrorComponent from "../Forms/PlaceholderComponents/Error";
import LoadWidget from "../LoadWidget";
import styles from "./ClickCollect.module.scss";
import LocationPicker from "./StoreFinder/LocationPicker";
import LocationsMap from "./StoreFinder/Map/LocationsMap";

type FormValues = {
  address: string;
  isParcelShop?: boolean;
};

//TODO: Consider loading google maps script dynamically instead of in the index.html. So that it is only loaded when needed.

export const PickupDelivery = ({
  storeSearchCallback,
  isParcelShop,
  errorMessage,
}: {
  storeSearchCallback: any; // TODO - Add strong types here
  isParcelShop: any;
  errorMessage?: string;
}) => {
  const { t } = useTranslation();
  const geocoding = useMapsLibrary("geocoding");
  const [storeList, setStoreList] = useState<IStore[]>([]);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [{ data, profile }] = useStateValue();
  const [focusedItem, setFocusedItem] = useState<IStore>();
  const { stageRedirect } = useStageRedirect();
  const [latLong, setLatLong] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [initialValues, setInitialValues] = useState({
    address: "",
  });

  const handleUseMyLocationSearch = () => {
    let lat: number | null = null;
    let lng: number | null = null;
    navigator.geolocation.getCurrentPosition(
      function success(pos) {
        lat = pos.coords.latitude;
        lng = pos.coords.longitude;
        setLatLong({ lat, lng });
        setSearching(true);
        setStoreList([]);
        storeSearchCallback(
          lat,
          lng,
          null,
          setStoresWithDistances,
          setSearching
        );
        setError(null);
      },
      null,
      { enableHighAccuracy: false, maximumAge: 0 }
    );
  };

  const handleStoreSearch = async (
    values: FormValues,
    actions: FormikHelpers<FormValues> | null
  ) => {
    actions?.setSubmitting(true);

    if (!geocoding) {
      return;
    }

    const geocoder = new geocoding.Geocoder();
    const address = values.address.toLowerCase();

    let countryCode = profile?.countryCode ?? "GB";
    geocoder.geocode(
      // google.maps.GeocoderRequest.region => as in this table https://developers.google.com/maps/coverage
      { address: `${address}, ${profile?.countryName}`, region: countryCode },
      function (results, status) {
        if (results && status === geocoding.GeocoderStatus.OK) {
          const firstLoc = results[0].geometry.location;
          let lat = firstLoc.lat();
          let lng = firstLoc.lng();
          setLatLong({ lat, lng });

          if (isParcelShop) {
            const country = results[0].address_components.find((a) =>
              a.types.includes("country")
            )?.short_name;
            if (country !== countryCode) {
              setError(t("store:NoCollectionPointsFound"));
              setSearching && setSearching(false);
              actions?.setSubmitting(false);
              setStoreList([]);
              return;
            }
          }
          //Incorrect data from google api is returned so we need to hard code the lat and lng when searching for lincoln
          if (
            countryCode === "GB" &&
            address === ("lincoln" || "lincoln, uk")
          ) {
            lat = 53.230688;
            lng = -0.540579;
          }

          setSearching(true);
          setStoreList([]);
          storeSearchCallback(
            lat,
            lng,
            actions,
            setStoresWithDistances,
            setSearching
          );
          setError(null);
        } else {
          setError(t("LocationNotFound"));
          actions?.setSubmitting(false);
        }
      }
    );
  };

  const distanceUnit = (val: number) => {
    if (profile?.countryCode === "GB" || profile?.countryCode === "US") {
      //1 mile is 1.60934 km
      return Number((val / 1.60934).toFixed(1)) + " miles";
    } else return Number(val.toFixed(1)) + "km";
  };

  const setStoresWithDistances = (
    lat: number,
    long: number,
    storeList: IStore[]
  ) => {
    let stores: IStore[] = [];
    storeList.forEach((store) => {
      let distance = calculateDistance(
        lat,
        store.latitude,
        long,
        store.longitude
      );
      store.miles = distanceUnit(distance);
      stores.push(store);
    });

    setStoreList(stores);
  };

  //formula found from https://www.geeksforgeeks.org/program-distance-two-points-earth/#:~:text=For%20this%20divide%20the%20values,is%20the%20radius%20of%20Earth.
  const calculateDistance = (
    lat1: number,
    lat2: number,
    lon1: number,
    lon2: number
  ): number => {
    try {
      lon1 = (lon1 * Math.PI) / 180;
      lon2 = (lon2 * Math.PI) / 180;
      lat1 = (lat1 * Math.PI) / 180;
      lat2 = (lat2 * Math.PI) / 180;

      let dlon = lon2 - lon1;
      let dlat = lat2 - lat1;
      let a =
        Math.pow(Math.sin(dlat / 2), 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

      let c = 2 * Math.asin(Math.sqrt(a));
      let r = 6371; // Radius of the earth in km

      let distanceInKm = c * r;

      return distanceInKm;
    } catch (e) {
      // TODO, do it properly, PUDO stores might not have that info
      return 0;
    }
  };

  useEffect(() => {
    if (isAnalyticConsented(performanceGroup)) {
      ga("send", "pageview", window.location.pathname);
    }

    if (
      data?.deliveryAddress?.addressLine1 &&
      (data?.shipping.isCnC || data?.shipping.isCollectFromPoint)
    ) {
      const valueToSearch = {
        address:
          data.deliveryAddress.addressLine1 + " " + data.deliveryAddress.city,
      };

      // Pre-fill the address field _only_ if user has gone back to page they previously filled in
      const isCnc = data?.shipping.isCnC;
      if (isCnc && !isParcelShop) {
        setInitialValues(valueToSearch);
      } else if (!isCnc && isParcelShop) {
        setInitialValues(valueToSearch);
      }

      if (storeList.length > 0) handleStoreSearch(valueToSearch, null);
    }
  }, []);

  useEffect(() => {
    if (
      data?.deliveryAddress?.addressLine1 &&
      (data?.shipping.isCnC || data?.shipping.isCollectFromPoint)
    ) {
      const focused = storeList.find(
        (x) => x.address.address1 === data.deliveryAddress.addressLine1
      );

      setFocusedItem(focused);
    }
  }, [storeList]);

  useEffect(() => {
    if (errorMessage) setError(errorMessage);
  }, [errorMessage]);

  const render = (): any => {
    if (latLong) {
      return (
        <LocationsMap
          storeList={storeList}
          focusedItem={focusedItem}
          onItemFocus={setFocusedItem}
          isParcelShop={isParcelShop}
          latLong={latLong}
          range={getRangeInKmPerProfile(profile)}
        />
      );
    }
    return null;
  };

  if (
    data &&
    !data.shipping.cnCEnabled &&
    !data.shipping.collectFromPointEnabled
  ) {
    stageRedirect(Stages.Address);
  }

  return (
    <>
      <div className={styles.container}>
        <h2>{isParcelShop ? t("store:CollectFromPoint") : t("StoreFinder")}</h2>
        <p className={styles.intro}>
          {!isParcelShop ? (
            <>
              {t("StoreFinderIntro")}
              <br />
            </>
          ) : (
            <>
              {t("store:CollectFromPointInfo")}
              <br />
            </>
          )}
          {t("StoreFinderIntroMail")}
        </p>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            address: Yup.string().required(t("store:CNCEmptySearchError")),
          })}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            handleStoreSearch(values, actions);
          }}
        >
          {({ isSubmitting }) => (
            <>
              {(isSubmitting || searching) && <LoadWidget />}
              <Form>
                <div className={styles.form}>
                  <div className={`${styles.formInputs} ${styles.cncMap}`}>
                    <div className={styles.input}>
                      <InputWrap
                        name="address"
                        placeholder={t("store:CNCSearchPlaceholder")}
                        label={t("store:FindAStore")}
                        type="text"
                        required={true}
                        autocomplete="off"
                        value={initialValues.address}
                      />
                    </div>
                    <div className={`${styles.buttons} ${styles.cncMap}`}>
                      <div className={styles.searchButton}>
                        <Button disabled={isSubmitting} type="submit">
                          {t("Search")}
                        </Button>
                      </div>

                      <>
                        Or
                        <Button
                          disabled={isSubmitting}
                          className={styles.locationButton}
                          type={"button"}
                          onClick={() => handleUseMyLocationSearch()}
                        >
                          {t("store:UseMyLocation")}
                        </Button>
                      </>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>

        {error && (
          <ErrorComponent hasBackground={true} style={{ margin: "1rem 0rem" }}>
            {error}
          </ErrorComponent>
        )}

        {storeList.length === 0 && searching && (
          <LocationPicker
            focusedItem={focusedItem}
            onItemFocus={setFocusedItem}
            storeList={[]}
            isParcelShop={isParcelShop}
            setStoreList={setStoreList}
            searching={searching}
          />
        )}

        {(storeList.length > 0 || (error && !isParcelShop)) && (
          <>
            {!searching && render()}
            <LocationPicker
              focusedItem={focusedItem}
              onItemFocus={setFocusedItem}
              storeList={storeList}
              isParcelShop={isParcelShop}
              setStoreList={setStoreList}
              searching={searching}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PickupDelivery;
