import { datadogLogs } from "@datadog/browser-logs";
import { Severity } from "./logger";
import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import Cookies from "js-cookie";
import React, { useRef } from "react";
import { publishDotDigitalData } from "./analytics/dotDigital";
import { API } from "./api";
import {
  APIS,
  consentCookie,
  Profile,
  profilesMap,
  storageBasketToken,
  storageOrderToken,
  THANKYOU_STAGE,
  VALIDATION_STAGE,
} from "./constants";
import {
  logError,
  logger,
  setLogUserContextFromBasket,
  setLogUserContextFromOrder,
} from "./logger";
import { OrderType } from "./types";
import { IBasketToken, IOrderToken, IUserToken } from "./types/auth";
import { IItem } from "./types/products";
import { MiraklError } from "./types/errors/MiraklError";

export const currency = (
  value: number,
  currency: string,
  cultureCode: string
): string => {
  if (cultureCode && !isNaN(value)) {
    let loc = cultureCode.slice(0, 2) + "-" + cultureCode.slice(2);

    return new Intl.NumberFormat(loc, {
      style: "currency",
      currency: currency,
    }).format(value);
  } else {
    return "";
  }
};

export function getProfileName() {
  let lng: string | undefined = "";
  const basketToken = getBasketToken();

  if (basketToken) lng = getProfileNameFromToken(basketToken);
  return lng;
}

function getProfileNameFromToken(basketToken: IBasketToken) {
  return profilesMap.get(basketToken.profileId)?.name.toString();
}

export function getProfile(): Profile | undefined {
  const basketToken = getBasketToken();

  if (!basketToken) return;
  let profile = profilesMap.get(basketToken.profileId);
  return profile;
}

const MIRAKL_ERROR_INDICATOR = "Error processing Mirakl request";

// Don't use it for get methods, handle them accordingly
export const handleSubmitError = (
  error: any,
  t: any,
  redirectionHandler: History,
  message?: string,
  handleValidationError?: (() => void) | null,
  actions?: FormikHelpers<any>
) => {
  if (actions) actions.setSubmitting(false);
  let profileName = getProfileName();

  if (!error.response) {
    console.error(error);
    logError(error, "Unknown error", null, Severity.Fatal);
    throw error; //Could pass in an error message here to login page to display.
  } else if (error?.response?.status === 401) {
    localStorage.clear();
    removeStorageOrderToken();
    window.location = t("paths:/login");
  } else if (error?.response?.status === 409) {
    // 409 - Conflict, basket has changed so redirect to basket page
    message = "BasketChanged";
    logError(error, message, VALIDATION_STAGE, Severity.Warning);
    localStorage.clear();
    removeStorageOrderToken();
    window.location.href =
      process.env.REACT_APP_WEBSITE_HOST + t("paths:/redirectToBasket");
    return;
  } else if (error?.response?.status === 400 && handleValidationError) {
    logError(error, "Validation error", VALIDATION_STAGE, Severity.Warning);
    handleValidationError();
  } else if (
    profileName === "enGB" &&
    error?.response?.status === 400 &&
    typeof error?.response?.data === "string" &&
    error?.response?.data?.includes(MIRAKL_ERROR_INDICATOR)
  ) {
    // Parse to a mirakl error object
    const mklError = JSON.parse(error?.response?.data) as MiraklError;
    if (mklError.message !== MIRAKL_ERROR_INDICATOR) {
      // If doesn't properly parse, send to error page
      logError(error, "Critical error", VALIDATION_STAGE, Severity.Fatal);
      redirectionHandler.go(t("paths:/error"));
      return;
    }

    // Build param string from each individual error
    let urlParams: string[] = [];
    mklError.data?.ProductErrors.forEach((p) => {
      switch (p.error) {
        case "OFFER_NOT_FOUND":
          urlParams.push(`invalidOffers=${p.identifier}`);
          break;
        case "SHIPPING_ZONE_NOT_ALLOWED":
          urlParams.push(`noShippingZones=${p.identifier}`);
          break;
        default:
          logger.error(
            `Unhandled error for product ${p.identifier}: '${p.error}', handling as invalid offer.`
          );
          urlParams.push(`invalidOffers=${p.identifier}`);
      }
    });

    window.location.href = `${process.env.REACT_APP_WEBSITE_HOST}${t(
      "paths:/redirectToBasket"
    )}?${urlParams.join(",")}`;
  } else if (error?.response?.status === 404) {
    localStorage.clear();
    removeStorageOrderToken();
    //TODO: This isn't very user friendly, user should get a message to say their basket is empty and are being redirected.
    window.location.href =
      process.env.REACT_APP_WEBSITE_HOST + t("paths:/redirectToBasket");
  } else if (error.response.status === 500) {
    logError(error, "Critical error", VALIDATION_STAGE, Severity.Fatal);
    redirectionHandler.go(t("paths:/error"));
  }
};

export const parsePrefix = (prefix: string) => {
  let validPrefix = Array.from(profilesMap.values()).find(
    (x) => x.countryCode.toLocaleLowerCase() === prefix
  )?.countryCode;

  if (!validPrefix || validPrefix.toLowerCase() === "gb") return "";
  else return prefix + "/";
};

export const removeStorageOrderToken = () => {
  Cookies.remove(storageOrderToken, {
    path: "/",
    domain: ".mountainwarehouse.com",
  });
  datadogLogs.setUserProperty("hasOrderToken", false);
  datadogLogs.logger.warn("Order Token Removed!");
};

export const removeStorageBasketToken = () => {
  Cookies.remove(storageBasketToken, {
    path: "/",
    domain: ".mountainwarehouse.com",
  });
  datadogLogs.setUserProperty("hasBasketToken", false);
  datadogLogs.logger.warn("Basket Token Removed!");
};

export const setTotalBasketItems = (items: IItem[]) => {
  let totalItems = 0;
  items.forEach((item: IItem) => {
    totalItems += item.quantity;
  });

  localStorage.setItem("totalBasketItems", totalItems.toString());
};

// Thank you stackoverflow <3 https://stackoverflow.com/questions/28821804/how-can-i-quickly-determine-the-state-for-a-given-zipcode
export function getUSState(zipString: string): string {
  /* Ensure we have exactly 5 characters to parse */
  if (zipString.length !== 5) {
    logError(null, "getUSState -> Must pass a 5-digit zipcode.");
    return "error";
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zipString, 10);

  let st;

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    st = "AL";
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    st = "AK";
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    st = "AZ";
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    st = "AR";
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    st = "CA";
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    st = "CO";
  } else if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    st = "CT";
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    st = "DE";
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    st = "FL";
  } else if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    st = "GA";
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    st = "HI";
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    st = "ID";
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    st = "IL";
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    st = "IN";
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    st = "IA";
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    st = "KS";
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    st = "KY";
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    st = "LA";
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    st = "ME";
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    st = "MD";
  } else if (
    (zipcode >= 1000 && zipcode <= 2799) ||
    zipcode === 5501 ||
    zipcode === 5544
  ) {
    st = "MA";
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    st = "MI";
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    st = "MN";
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    st = "MS";
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    st = "MO";
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    st = "MT";
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    st = "NC";
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    st = "ND";
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    st = "NE";
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    st = "NV";
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    st = "NH";
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    st = "NJ";
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    st = "NM";
  } else if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode === 6390 ||
    zipcode === 501 ||
    zipcode === 544
  ) {
    st = "NY";
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    st = "OH";
  } else if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    st = "OK";
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    st = "OR";
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    st = "PA";
  } else if (zipcode >= 300 && zipcode <= 999) {
    st = "PR";
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    st = "RI";
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    st = "SC";
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    st = "SD";
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    st = "TN";
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    st = "TX";
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    st = "UT";
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    st = "VT";
  } else if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode === 20598
  ) {
    st = "VA";
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    st = "DC";
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    st = "WA";
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    st = "WV";
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    st = "WI";
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    st = "WY";
  } else {
    st = "none";
  }

  return st;
}

export const validatePostcode = (
  postCode: string,
  countryCode: string
): boolean => {
  if (postCode && countryCode) {
    const countrycodeUpper = countryCode.slice(-2).toUpperCase();
    const postcodeUpper = postCode.toUpperCase();
    switch (countrycodeUpper) {
      case "DK":
      case "BE":
      case "AU":
      case "AT":
        return /^\d{4}$/.test(postcodeUpper);
      case "GB":
        return /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/.test(
          postcodeUpper
        );
      case "US":
        return /^\d{5}([-]?\d{4})?$/.test(postcodeUpper);
      case "CA":
        return /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/.test(
          postcodeUpper
        );
      case "FR":
        return /^[0-9]{5}$/.test(postcodeUpper);
      case "DE":
        return /^[0-9]{5}$/.test(postcodeUpper);
      case "PL":
        return /[0-9]{2}-[0-9]{3}$/.test(postcodeUpper);
      case "ES":
        return /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(postcodeUpper);
      case "GI":
        return /^[Gg][Xx][1]{2}\s{0,1}[1][Aa]{2}$/.test(postcodeUpper);
      case "GR":
        return /^\d{3}\s{0,1}\d{2}$/.test(postcodeUpper);
      case "IE": // Ireland
        return true;
      case "IT":
        return /^\d{5}$/.test(postcodeUpper);
      case "LU":
        return /^\d{4}$/.test(postcodeUpper);
      case "NL":
        return /^\d{4}\s{0,1}[A-Za-z]{2}$/.test(postcodeUpper);
      case "UA":
        return /^\d{5}$/.test(postcodeUpper);
      default:
        return true;
    }
  } else {
    return true;
  }
};

export function getCurrentProfileById(id: any) {
  let profileId = Number.parseInt(id);
  return profilesMap.get(profileId);
}

export function enabledForProfile(countryCode: string, profile: any): boolean {
  if (!profile || countryCode === "") return false;

  return profile?.countryCode === countryCode;
}

// This method gets called on app startup and checks tokens - ideal place to start user logging
export function compareTokens(
  basketToken: IBasketToken | undefined,
  orderToken: IOrderToken | undefined
) {
  if (!basketToken) return;

  setLogUserContextFromBasket(basketToken);

  if (!orderToken) return;

  setLogUserContextFromOrder(orderToken);

  if (
    basketToken.profileId !== orderToken.profileId ||
    basketToken.basketId !== orderToken.basketId ||
    (orderToken.isLoggedIn === basketToken.isLoggedIn &&
      basketToken.userId !== orderToken.userId) ||
    basketToken.brand !== orderToken.brand
  ) {
    datadogLogs.logger.warn("Token mismatch! Order token will be removed!");
    removeStorageOrderToken();
  }
}

export function isLoggedIn() {
  const basketToken = getBasketToken();

  if (!basketToken) {
    datadogLogs.setUserProperty("hasBasketToken", false);
    return false;
  }

  let isLoggedIn = basketToken.isLoggedIn === true;
  if (isLoggedIn) {
    return isLoggedIn;
  }

  const orderToken = getOrderToken();
  if (!orderToken) return false;

  isLoggedIn = orderToken.isLoggedIn === true;
  return isLoggedIn;
}

export function updateDotDigital() {
  API.get(APIS.dotDigitalTracking + "/start")
    .then(function (response: any) {
      publishDotDigitalData(
        response.data?.accountId,
        response.data?.basketTrackingData
      );
    })
    .catch(function (error) {
      logError(error, "update dot digital failed", THANKYOU_STAGE);

      if (!error.response) {
        console.error(error);
      }
    });
}

export function parseToken<IToken>(
  token: string | undefined
): IToken | undefined {
  if (!token) {
    // NOTE Should we log this?
    return undefined;
  }

  try {
    let result = JSON.parse(atob(token.split(".")[1]));

    result.userId = Number.parseInt(result.userId);
    result.profileId = Number.parseInt(result.profileId);
    result.basketId = Number.parseInt(result.basketId);

    return result;
  } catch (e) {
    datadogLogs.logger.warn("Failed to parse {Token} with {Error}", {
      Token: token,
      Error: e,
    });
    return undefined;
  }
}

export function getBasketToken(): IBasketToken | undefined {
  let token = parseToken<IBasketToken>(Cookies.get(storageBasketToken));
  if (token) {
    checkTokenExpiration(token);
  }
  return token;
}

export function getOrderToken(): IOrderToken | undefined {
  const token = parseToken<IOrderToken>(Cookies.get(storageOrderToken));
  if (token) {
    checkTokenExpiration(token);
  }
  return token;
}

function checkTokenExpiration(token: IUserToken) {
  if (Date.now() >= token.exp * 1000) {
    datadogLogs.setUserProperty("id", token.userId);
    datadogLogs.logger.warn("Token expired!");
  }
}

export const isMiraklOrder = (orderType: OrderType): boolean => {
  if (orderType & OrderType.Mirakl) {
    return true;
  }
  return false;
};

// Based on https://en.wikipedia.org/wiki/Postal_codes_in_Canada#Table_of_all_postal_codes
export function getCAProvince(postalCode: string): string {
  if (!postalCode) return "error";

  const postalTerritory: string = postalCode[0];

  switch (postalTerritory) {
    case "A":
      return "NL";
    case "B":
      return "NS";
    case "C":
      return "PE";
    case "E":
      return "NB";
    case "G":
    case "H":
    case "J":
      return "QC";
    case "K":
    case "L":
    case "M":
    case "N":
    case "P":
      return "ON";
    case "R":
      return "MB";
    case "S":
      return "SK";
    case "T":
      return "AB";
    case "V":
      return "BC";
    case "X":
    case "Y":
      return "error";
    default:
      return "error";
  }
}

export function useValidRef(ref: React.MutableRefObject<any> | undefined) {
  const fieldRef =
    useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

  return ref ?? fieldRef;
}

export function isLocalStorageAvailable() {
  let test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
export const getFullPath = (translation: TFunction, path: string) => {
  return process.env.REACT_APP_WEBSITE_HOST + translation(`paths:/${path}`);
};

export type consentGroup = {
  name: string;
  enabled: boolean;
};

export function getConsentCookie() {
  var cookie = Cookies.get(consentCookie);
  var groups = cookie?.split("groups=")[1]?.split(",");
  var consentGroups: consentGroup[] = [];

  groups?.forEach((group: string) => {
    var consentGroup: consentGroup = { name: "", enabled: false };
    var groupDetails = group.split(":");
    consentGroup.name = groupDetails[0];
    consentGroup.enabled = Boolean(parseInt(groupDetails[1]));
    consentGroups.push(consentGroup);
  });

  localStorage.setItem("consentGroups", JSON.stringify(consentGroups));
}

export function isAnalyticConsented(groupName: string): boolean {
  var storedGroups = localStorage.getItem("consentGroups");
  if (storedGroups) {
    var groups = JSON.parse(storedGroups);
    var consentedGroup = groups.find((g: consentGroup) => g.name === groupName);
    return consentedGroup?.enabled;
  }
  return true;
}

/**
 * Returns the names of properties in an object that are set (i.e., not `undefined` or `null`).
 *
 * @param {any} obj - The object to inspect.
 * @returns {string} A comma-separated string of set property names, or "No properties set" if none are set.
 */
export function getSetPropertyNames(obj: any): string {
  const setProperties = Object.keys(obj).filter(
    (key) => obj[key] !== undefined && obj[key] !== null
  );
  return setProperties.length > 0
    ? setProperties.join(", ")
    : "No properties set";
}

/**
 * Returns the range in Km for a specific profile
 *
 * @returns {number} Range in Km for profile
 * @param profile
 */
export const getRangeInKmPerProfile = (profile: Profile | null): number => {
  if (profile) {
    if (profile.distanceUnit === "m") {
      // range need to be in Km as expected by the SLS service
      return Math.round(profile.storeLocatorRange * 1.60934);
    } else {
      return profile.storeLocatorRange;
    }
  }
  return 25;
};

export const getRangeTextPerProfile = (profile: Profile | null): string => {
  if (profile) {
    if (profile.distanceUnit === "m") {
      return `${profile.storeLocatorRange} Miles`;
    } else {
      return `${profile.storeLocatorRange} Km`;
    }
  }
  return "25 Miles"; // GB Default
};
