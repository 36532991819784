import { AxiosInstance, AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { endpointLookup } from "./api-config";
import { logger } from "../utils/logger";

function shouldRetryRequest(url: string, method: string): boolean {
  const upperMethod = method.toUpperCase();

  if (!(["GET", "POST"] as const).includes(upperMethod as any)) return false;
  const methodKey = upperMethod as "GET" | "POST";

  // Attempt direct URL match
  if (endpointLookup.direct[url] && endpointLookup.direct[url][methodKey])
    return true;

  // Fallback to regex
  for (const entry of endpointLookup.patterns) {
    if (entry.pattern.test(url) && entry.retryPolicy[methodKey]) {
      return true;
    }
  }

  return false;
}

const setupAxiosRetryPolicy = (axiosInstance: AxiosInstance) => {
  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: (retryCount) =>
      axiosRetry.exponentialDelay(retryCount, undefined, 1000),
    retryCondition: (error: AxiosError): boolean => {
      const url = error.config?.url;
      const method = error.config?.method;
      if (!url || !method) return false;

      const isRetryableEndpoint = shouldRetryRequest(
        url,
        method.toUpperCase() as "GET" | "POST"
      );

      const status = error.response?.status;
      const isNetworkError = axiosRetry.isNetworkError(error);
      const isTooManyRequests = status === 429;
      const isTransient5xxError =
        status === 502 || status === 503 || status === 504;

      const shouldRetry =
        (isNetworkError || isTooManyRequests || isTransient5xxError) &&
        isRetryableEndpoint;

      if (shouldRetry) {
        const statusCode = error.response?.status;
        const errorMsg = error.message;
        logger.warn(
          `RETRY ${method} ${url} - Status: ${statusCode}, Error: ${errorMsg}`
        );
      }
      return shouldRetry !== undefined ? shouldRetry : false;
    },

    onMaxRetryTimesExceeded: (error, retryCount) => {
      const url = error.config?.url;
      const method = error.config?.method;
      const statusCode = error.response?.status;
      const errorMsg = error.message;
      logger.error(
        `${method} ${url} FAILED RETRIES. COUNT: ${retryCount} - Status: ${statusCode}, Error: ${errorMsg}`
      );
      throw error;
    },
  });
};

export { setupAxiosRetryPolicy, shouldRetryRequest };
