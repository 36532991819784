import { FormikErrors } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorComponent from "../../Forms/PlaceholderComponents/Error";
import { IAddress } from "../../../utils/types/address";

interface ErrorMobileProps {
  errors: FormikErrors<IAddress>;
}

const ErrorMobile = ({ errors }: ErrorMobileProps) => {
  const [errorsArray, setErrorsArray] = useState<string[]>([]);
  const { t } = useTranslation();

  const getTotalErrors = () => {
    const allErrors: string[] = [];

    if (errors.firstName) {
      allErrors.push(errors.firstName);
    }
    if (errors.lastName) {
      allErrors.push(errors.lastName);
    }
    if (errors.phone) {
      allErrors.push(errors.phone);
    }
    if (errors.addressLine1) {
      allErrors.push(errors.addressLine1);
    }
    if (errors.postCode) {
      allErrors.push(errors.postCode);
    }
    setErrorsArray(allErrors);
  };

  useEffect(() => {
    getTotalErrors();
  }, [errors]);

  return (
    <div>
      {errorsArray.length === 1 && (
        <ErrorComponent hasBackground={true}>
          <div>{t("SingleFormError", { fieldName: errorsArray.length })}</div>
        </ErrorComponent>
      )}
      {errorsArray.length >= 2 && (
        <ErrorComponent hasBackground={true}>
          <div>
            {t("MultipleFormErrors", { totalErrors: errorsArray.length })}
          </div>
        </ErrorComponent>
      )}
    </div>
  );
};

export default ErrorMobile;
