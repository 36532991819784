import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { storageBasketToken, storageOrderToken } from "../utils/constants";
import { APIConfig, APIS } from "./api-config";
import { setupAxiosRetryPolicy } from "./retry-policies";

import Cookies from "js-cookie";

const createConfiguredAxios = (apiConfig: APIConfig): AxiosInstance => {
  return axios.create({
    baseURL: apiConfig.baseURL,
    headers: { "Content-Type": "application/json" },
  });
};

const websiteApiClient = createConfiguredAxios(APIS.WEBSITE_API);
const appApiClient = createConfiguredAxios(APIS.APP_API);
const WebLoginApiClient = createConfiguredAxios(APIS.APP_API);
const B2cLoginApiClient = createConfiguredAxios(APIS.APP_API);

// Setup APIs we want to retry
[websiteApiClient, appApiClient].forEach((axiosInstance) => {
  setupAxiosRetryPolicy(axiosInstance);
});

// Add order token
[websiteApiClient, appApiClient].forEach((axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => setTokenHeader(config, storageOrderToken),
    (error) => Promise.reject(error)
  );
});

// Add basket token
[WebLoginApiClient].forEach((axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => setTokenHeader(config, storageBasketToken),
    (error) => Promise.reject(error)
  );
});

function setTokenHeader(config: AxiosRequestConfig, tokenName: string): any {
  const token = Cookies.get(tokenName);

  if (!config.headers) {
    config.headers = {};
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
}

export { B2cLoginApiClient, WebLoginApiClient, appApiClient, websiteApiClient };
