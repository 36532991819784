import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import ErrorComponent from "../../../components/Forms/PlaceholderComponents/Error";
import { performanceGroup, ZENDESK_URL } from "../../../utils/constants";
import { isAnalyticConsented } from "../../../utils/helpers";

export const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isAnalyticConsented(performanceGroup)) {
      ga("send", "pageview", window.location.pathname);
    }
  }, []);

  return (
    <div className="content">
      <h1>{t("NotFound")}</h1>
      <ErrorComponent hasBackground={true}>
        <Trans i18nKey="NotFoundPageMessage">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={ZENDESK_URL + t("paths:/help")}
          >
            {t("ContactUs")}
          </a>
        </Trans>
      </ErrorComponent>
    </div>
  );
};

export default NotFound;
