import React from "react";

import styles from "./Checkbox.module.scss";

//! This component will be replaced by an Input in the component library (seperate project). Proof of concept.

interface Props {
  name: string;
  id: string;
  value: string;
  required?: boolean;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ name, id, onChange, value = "" }: Props) => (
  <input
    type="checkbox"
    id={id}
    value={value}
    checked={value === "true" ? true : false}
    onChange={onChange}
    name={name}
    className={styles.checkbox}
  />
);

export default Checkbox;
