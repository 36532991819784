import { APIProvider } from "@vis.gl/react-google-maps";
import { FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PickupDelivery from "../../components/PickupDelivery";
import API from "../../utils/api";
import { APIS, COLLECTFROMPOINT_STAGE } from "../../utils/constants";
import { logError, logger } from "../../utils/logger";
import { useStateValue } from "../../utils/state-provider";
import { IShipping } from "../../utils/types/shipping";
import DeliveryTabs from "../DeliveryAddress/DeliveryTabs";

type FormValues = {
  address: string;
};

//TODO: Consider loading google maps script dynamically instead of in the index.html. So that it is only loaded when needed.

export const CollectFromPoint = () => {
  const { t } = useTranslation();
  const [{ data }, dispatch] = useStateValue();
  const [error, setError] = useState<string | undefined>(undefined);

  const postStoreSearch = async (
    latitude: number,
    longitude: number,
    actions: FormikHelpers<FormValues> | null,
    setStoresWithDistances: any,
    setSearching: any
  ) => {
    setError("");
    try {
      var request = {
        latitude: latitude,
        longitude: longitude,
        radius: 1000,
        limit: 10,
      };

      var response = await API.post(APIS.findCollectionPoints, request);

      if (response.data.length === 0) {
        setSearching(false);
        actions?.setSubmitting(false);
        setError(t("store:NoCollectionPointsFound"));
        return;
      }

      setStoresWithDistances(latitude, longitude, response.data);
      if (response.data.length > 0) {
        // trying to do shallow copy fails, please use JSON.parse for safety
        let newShipping: IShipping = JSON.parse(JSON.stringify(data.shipping));
        logger.debug(response.data, {
          params: {
            latitude,
            longitude,
          },
        });

        newShipping.selectedShippingOptionMethods[0] = {
          localizedName: t("store:CollectFromPoint"),
          name: t("store:CollectFromPoint"),
          rate: Number.parseFloat(response.data[0].rate) ?? 0,
          warehouse: response.data[0].warehouse ?? "",
          storeName: "",
          shipmentId: 0,
          isComplimentary: false,
        };

        dispatch({
          type: "changeShipping",
          shipping: { ...newShipping },
          force: true,
        });
      }

      setSearching(false);
      actions?.setSubmitting(false);
      setError("");
    } catch (error) {
      setSearching(false);
      actions?.setSubmitting(false);
      logError(
        error,
        "GET " + APIS.findCollectionPoints,
        COLLECTFROMPOINT_STAGE
      );
      setError(t("store:NoCollectionPointsFound"));
    }
  };

  return (
    <>
      <h1>{t("DeliveryMethod")}</h1>
      <DeliveryTabs activeTab={3} />
      <APIProvider apiKey={process.env.REACT_APP_GAPI_KEY!}>
        <PickupDelivery
          storeSearchCallback={postStoreSearch}
          isParcelShop={true}
          errorMessage={error}
        />
      </APIProvider>
    </>
  );
};

export default CollectFromPoint;
