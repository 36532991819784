import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { State, storageStage } from "../../utils/constants";
import { handleSubmitError } from "../../utils/helpers";
import { useStageRedirect } from "../../utils/hooks";
import { useStateValue } from "../../utils/state-provider";
import { Stages } from "../../utils/types";
import { APIS as apis, appApiClient } from "../../api";

interface IAuthProps {
  children?: React.ReactNode;
  routeStage: number;
}

export const Auth = ({ children, routeStage }: IAuthProps) => {
  const { t } = useTranslation("paths");
  const [{ data }, dispatch] = useStateValue();
  const { stageRedirect } = useStageRedirect();
  const [isLoading, setLoading] = useState<boolean>(true);

  const validateUser = async () => {
    try {
      const uri = apis.APP_API.endpoints.orderSummary().endpoint;
      const response = await appApiClient.get(uri);

      dispatch({
        type: "changeData",
        newData: response.data,
      });

      if (response?.data?.shipping) {
        dispatch({
          type: "changeShipping",
          shipping: response.data.shipping,
          force: false,
        });
      }
      setLoading(false);
    } catch (error) {
      handleSubmitError(
        error,
        t,
        window.history,
        "GET " + apis.APP_API.endpoints.orderSummary().endpoint,
        () => {
          localStorage.clear();
          window.location.href = t("/login");
        }
      );
    }
  };

  const checkStage = () => {
    const stage = +(localStorage.getItem(storageStage) ?? "0");
    if (
      data &&
      data.state.id > State.PaymentInProgress &&
      stage !== Stages.Receipt
    ) {
      stageRedirect(Stages.Receipt);
      return;
    }

    if (routeStage !== Stages.Receipt && routeStage > stage) {
      stageRedirect(stage as Stages);
    }
    if (stage === 0) {
      dispatch({ type: "changeStage", newStage: 0 });
    }
  };

  useEffect(() => {
    if (!data) {
      validateUser();
    }

    checkStage();
    setLoading(false);
  }, [routeStage, data]);

  return isLoading ? null : <>{children}</>;
};

export default Auth;
