import { datadogLogs } from "@datadog/browser-logs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { APIS as apis, appApiClient } from "../../api";
import buttonStyle from "../../components/Forms/PlaceholderComponents/Button.module.scss";
import ErrorComponent from "../../components/Forms/PlaceholderComponents/Error";
import ItemNameLink from "../../components/ItemNameLink/index";
import LoadWidget from "../../components/LoadWidget";
import Totals from "../../components/OrderSummary/Totals";
import iconCommunity from "../../icons/icon-community.svg";
import iconFacebook from "../../icons/icon-social-facebook.svg";
import iconInsta from "../../icons/icon-social-insta.svg";
import iconTwitter from "../../icons/icon-social-twitter.svg";
import iconYoutube from "../../icons/icon-social-youtube.svg";
import "../../styles/app.scss";
import { ZENDESK_URL } from "../../utils/constants";
import {
  getFullPath,
  getOrderToken,
  removeStorageOrderToken,
} from "../../utils/helpers";
import useAnalytics from "../../utils/hooks/useAnalytics";
import { useStateValue } from "../../utils/state-provider";
import { Stages } from "../../utils/types";
import GoogleReview from "./GoogleReview";
import SignupIncentive from "./SignupIncentive";
import style from "./Thanks.module.scss";

type item = {
  name: string;
  colour: string;
  size: string;
  imageUrl: string;
  colourCode: string;
  ref: string;
  quantity: number;
  price: string;
  currency: string;
};

interface ThanksProps {
  unsuccessful?: boolean;
}

export const Thanks = () => {
  const { t } = useTranslation();
  const locationState = useLocation()?.state as ThanksProps;
  const [{ data, profile }, dispatch] = useStateValue();
  const [isDataPublishingFinished, googleReviewSurveyData] = useAnalytics();
  const [isUnsuccessful] = useState(locationState?.unsuccessful ?? false);
  const contactUs = ZENDESK_URL + t("paths:/help");

  useEffect(() => {
    const resetCheckoutData = () => {
      sessionStorage.setItem("analyticsComplete", "false");
      removeStorageOrderToken();
      dispatch({
        type: "changeStage",
        newStage: Stages.Login,
      });
    };

    if (isDataPublishingFinished) {
      resetCheckoutData();
    }
  }, [isDataPublishingFinished]);

  useEffect(() => {
    const fetchOrderSummary = async () => {
      try {
        const uri = apis.APP_API.endpoints.orderSummary().endpoint;
        const response = await appApiClient.get(uri);
        dispatch({
          type: "changeData",
          newData: response.data,
        });
        dispatch({
          type: "changeShipping",
          shipping: response.data.shipping,
          force: true,
        });
      } catch (error: any) {
        datadogLogs.logger.warn(
          "Failed to retrieve order summary on Receipt Page",
          { orderToken: getOrderToken() },
          error
        );
        window.location.replace(getFullPath(t, "redirectToBasket"));
      }
    };
    fetchOrderSummary().catch((error) => {
      console.error(
        "Unexpected error fetching order summary on Receipt Page:",
        error
      );
    });
  }, []);

  if (!data || !isDataPublishingFinished) {
    return <LoadWidget />;
  }

  return (
    <div className="content-container is-thankyou" id="signup-content">
      <div className="content-main">
        {isUnsuccessful ? (
          <h1 className="side-pad is-centered h2">{t("ErrorWithOrder")}</h1>
        ) : (
          <h1 className="side-pad is-centered h2">{t("ThankYou")}</h1>
        )}
        <h5 className={style.orderNumber}>
          {t("OrderNumber", { orderNumber: data?.affiliateOrderNumber })}
        </h5>
        {isUnsuccessful && (
          <ErrorComponent hasBackground={true} contactUs={contactUs}>
            {t("UnsuccessfulPayment")}
          </ErrorComponent>
        )}

        {data?.owner?.isGuest && <SignupIncentive />}

        <div className="content is-thankyou">
          <div className={style.itemTopic}>
            <strong>{t("ShippingMethod")}</strong>
            {data.shipping &&
              data.shipping.selectedShippingOptionMethods?.map((item: any) => (
                <div className={style.itemText}>
                  - {item.localizedName ?? item.name ?? ""}
                </div>
              ))}
          </div>
        </div>
        <div className={`content ${style.itemBody}`}>
          <ul>
            {!data || !data.summary ? (
              <li>
                <div className={style.productImage}>
                  <Skeleton width={29} height={44} count={2} />
                </div>
                <div className={style.itemTopic}>
                  <strong>
                    <Skeleton />
                  </strong>
                  <span>
                    <Skeleton width={20} /> | <Skeleton width={40} />
                  </span>
                </div>
              </li>
            ) : null}
            {data?.summary?.items.map((item: item) => {
              return (
                <li key={(item.name + item.colour).replace(" ", "_")}>
                  <div className={style.productImage}>
                    <img
                      src={`${item.imageUrl.split("?")[0]}?w=29`}
                      alt={item.name}
                    />{" "}
                  </div>
                  <div className={style.itemTopic}>
                    <ItemNameLink item={item} />
                    {(item.colour || item.size) && (
                      <span>{`${item.colour} | ${item.size}`}</span>
                    )}
                    <div>
                      {t("Quantity")}: {item.quantity}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={`content ${style.itemBody}`}>
          {data?.summary && <Totals />}
        </div>
        <div className={style.keepShopping}>
          <button
            type="submit"
            className={`${buttonStyle.button}`}
            onClick={() =>
              (window.location.href =
                (process.env.REACT_APP_WEBSITE_HOST as string) +
                t("paths:/baseName").replace("/", ""))
            }
          >
            {t("KeepShopping")}
          </button>
        </div>

        <div className={style.iconContainer}>
          <a href={"http://www.mountainwarehouse.com/community/"}>
            <img
              className={style.communityIcon}
              src={iconCommunity}
              alt="Community"
            />
          </a>
          <div className={style.socialIcons}>
            <a href={"http://www.facebook.com/MountainWarehouse"}>
              <img
                className={style.socialIcon}
                src={iconFacebook}
                alt="Facebook"
              />
            </a>
            <a href={"https://www.instagram.com/mountainwarehouse/?hl=en"}>
              <img
                className={style.socialIcon}
                src={iconInsta}
                alt="Instagram"
              />
            </a>
            <a href={"https://twitter.com/MountainWHouse"}>
              <img
                className={style.socialIcon}
                src={iconTwitter}
                alt="Twitter"
              />
            </a>
            <a href={"http://www.youtube.com/user/MWOutdoorsOfficial/"}>
              <img
                className={style.socialIcon}
                src={iconYoutube}
                alt="Youtube"
              />
            </a>
          </div>
        </div>
      </div>
      <GoogleReview
        surveyOptin={googleReviewSurveyData}
        cultureCode={profile?.countryCode}
      />
    </div>
  );
};

export default Thanks;
