import React from "react";
import { useTranslation } from "react-i18next";
import ErrorModal from "./Index";

interface IMissingStock {
  products: [];
}

const MissingStock = ({ products }: IMissingStock) => {
  const { t } = useTranslation();

  const redirectToBasket = () => {
    window.location.href =
      process.env.REACT_APP_WEBSITE_HOST + t("paths:/redirectToBasket");
  };

  return (
    <ErrorModal
      errorMessage={t("OutOfStock")}
      redirectMessage={t("RedirectToBasket")}
      buttonMessage={t("GoToBasket")}
      redirectUrl={""}
      products={products}
      onButtonClick={() => redirectToBasket()}
    />
  );
};

export default MissingStock;
