import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import iconCoins from "../../icons/icon-coins.svg";
import { currency, enabledForProfile } from "../../utils/helpers";
import { useStateValue } from "../../utils/state-provider";
import {
  IShippingOption,
  SelectedShipmentViewModel,
} from "../../utils/types/shipping";
import ItemsSummary from "./ItemsSummary";
import ShippingsSummary from "./ShippingSummary";
import styles from "./Totals.module.scss";
import GiftCardsSummary from "./GiftCardsSummary";
import { shippingRateCost } from "../../utils/shipping";

interface TotalsProps {
  showTotalSavings?: boolean;
  showVATMessage?: boolean;
}

export const Totals = ({ showTotalSavings = true }: TotalsProps) => {
  const { t } = useTranslation();
  const [{ data, shipping, profile, orderTotal }, dispatch] = useStateValue();

  const getShippingPrice = (
    selectedShipping: SelectedShipmentViewModel[],
    allShippings: IShippingOption[]
  ) => {
    let shippingsSummary = 0;
    if (allShippings && selectedShipping) {
      shippingsSummary += selectedShipping
        .map((x) => shippingRateCost(x))
        .reduce((partialSum, a) => partialSum + a, 0);
    }

    let taxes =
      (data?.summary.itemsTax ?? 0) + (data?.summary.deliveryTax ?? 0);

    return (
      data?.summary.itemsPrice -
      (data?.totalSavings ?? 0) +
      shippingsSummary +
      taxes -
      (data?.summary?.giftCardsTotal ?? 0)
    );
  };

  useEffect(() => {
    if (data) {
      let total = getShippingPrice(
        shipping?.selectedShippingOptionMethods,
        shipping?.availableShippingOptions
      );

      dispatch({
        type: "changeOrderTotal",
        orderTotal: total,
      });
    }
  }, [data, shipping]);

  return (
    <>
      <ItemsSummary />
      <ShippingsSummary />
      <GiftCardsSummary />
      <div className={styles.prices}>
        {data?.orderTotal && (
          <div className={styles.subtotal}>
            <div>
              <strong>{t("OrderTotal")}</strong>
              {enabledForProfile("DE", profile) ? (
                <>
                  <br />
                  <div className={styles.vatMessage}>
                    <p>{t("VATIncluded")}</p>
                  </div>
                </>
              ) : null}
            </div>
            <div className={styles.subtotalPrice}>
              <strong>
                {currency(
                  orderTotal,
                  data?.summary?.currencyCode,
                  profile?.name ?? "enGB"
                )}
              </strong>
            </div>
          </div>
        )}
        {showTotalSavings && data?.totalSavings && (
          <div className={styles.saved}>
            <img className={styles.coins} src={iconCoins} alt="" />
            <strong>
              {t("DiscountSave", {
                discountSave: currency(
                  data?.totalSavings,
                  data?.summary?.currencyCode,
                  profile?.name ?? "enGB"
                ),
              })}
            </strong>
          </div>
        )}
      </div>
    </>
  );
};

export default Totals;
