import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Forms/PlaceholderComponents/Button";
import { API } from "../../utils/api";
import {
  APIS,
  SHIPPING_STAGE,
  ZENDESK_URL,
  performanceGroup,
} from "../../utils/constants";
import { logError } from "../../utils/logger";
import { useStateValue } from "../../utils/state-provider";
import styles from "./Delivery.module.scss";

import ErrorComponent from "../../components/Forms/PlaceholderComponents/Error";
import { invokeShippingInfoGA4Event } from "../../utils/analytics/ga4";
import {
  getOrderToken,
  handleSubmitError,
  isAnalyticConsented,
} from "../../utils/helpers";
import { Stages } from "../../utils/types";
import {
  IShipping,
  IShippingOption,
  SelectedShipmentViewModel,
} from "../../utils/types/shipping";
import ShippingOptions from "./ShippingOptions";
import { useStageRedirect } from "../../utils/hooks";

export const Delivery = () => {
  const { t } = useTranslation();
  const { stageRedirect } = useStageRedirect();
  const [{ data, shipping }, dispatch] = useStateValue();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isShippingError, setIsShippingError] = useState(false);
  const contactUs = ZENDESK_URL + t("paths:/help");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getDeliveryOptions = async () => {
    const setDefaultSelectedShippings = (shipping: IShipping) => {
      shipping.selectedShippingOptionMethods = [];
      if (shipping.availableShippingOptions) {
        shipping.availableShippingOptions.forEach((item: IShippingOption) => {
          let defaultSelected: SelectedShipmentViewModel = {
            warehouse: item.warehouse,
            storeName: item.name,
            shipmentId: item.methods[0].shipMethodId,
            localizedName: item.methods[0].localizedName,
            name: item.methods[0].name,
            rate: item.methods[0].rate,
            isComplimentary: false,
          };
          shipping.selectedShippingOptionMethods.push(defaultSelected);
        });
      }
    };

    try {
      setIsLoading(true);
      API.get(APIS.deliveryOptions)
        .then(function (response) {
          setIsShippingError(false);
          if (response.data && response.data.shipping) {
            setDefaultSelectedShippings(response.data.shipping);
            dispatch({
              type: "changeShipping",
              shipping: response.data.shipping,
              force: true,
            });

            if (
              response.data.shipping.isCnC ||
              response.data.shipping.isCollectFromPoint
            ) {
              stageRedirect(Stages.Payment);
            }
          }
        })
        .catch(function (error) {
          logError(error, "GET " + APIS.deliveryOptions, SHIPPING_STAGE);
          if (error.response) {
            if (error.response && error?.response?.status === 500) {
              setIsShippingError(true);
            } else if (
              error?.response?.status === 409 ||
              error?.response?.status === 400
            ) {
              stageRedirect(Stages.Login);
            }
          }
          console.error(error);
          throw error;
        })
        .finally(() => setIsLoading(false));
    } catch (error: any) {
      logError(error, "GET " + APIS.deliveryOptions, SHIPPING_STAGE);
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    let selected: SelectedShipmentViewModel[] =
      shipping?.selectedShippingOptionMethods;

    if (
      !selected ||
      selected.length === 0 ||
      shipping?.selectedShippingOptionMethods.length !==
        shipping?.availableShippingOptions.length
    ) {
      setIsShippingError(true);
      return;
    }
    setIsSubmitting(true);

    let summary = data.summary;
    invokeShippingInfoGA4Event(
      summary.items,
      summary.currencyCode,
      data.orderTotal,
      selected
    );

    await API.post(`${APIS.delivery}`, JSON.stringify(selected), {
      headers: { "Content-Type": "application/json" },
      "axios-retry": {
        retryDelay: (retryCount) => {
          return retryCount * 1000;
        },
      },
    })
      .then((response) => {
        dispatch({
          type: "changeData",
          newData: response.data,
        });
        setIsSubmitting(false);

        stageRedirect(Stages.Payment);
      })
      .catch(async (error) => {
        setIsSubmitting(false);
        logError(error, "POST " + APIS.delivery, SHIPPING_STAGE);
        if (error?.response?.status === 504) {
          setIsShippingError(true);
        } else {
          handleSubmitError(
            error,
            t,
            window.history,
            "POST " + APIS.addressDelivery,
            () => {
              setIsShippingError(true);
            }
          );
        }
      });
  };

  useEffect(() => {
    if (isAnalyticConsented(performanceGroup)) {
      ga("send", "pageview", window.location.pathname);
    }

    if (!data || !(data.shipping.isCnC || data.shipping.isCollectFromPoint)) {
      getDeliveryOptions(); // No need to call for shipping options if CnC or store pickup
    }

    let token = getOrderToken();
    if (token?.email === "anonymous-checkout@mountainwarehouse.com") {
      stageRedirect(Stages.Login);
    }
  }, []);

  if (data?.shipping?.isCnC || data?.shipping?.isCollectFromPoint) {
    stageRedirect(Stages.Payment, null, true, true);
  }

  return (
    <div className={styles.deliveryContainer}>
      <h2 className="is-centered">{t("ChooseDelivery")}</h2>

      <>
        <ShippingOptions isLoading={isLoading} shipping={shipping} />
        {isShippingError && (
          <ErrorComponent hasBackground={true} contactUs={contactUs}>
            {t("SomethingWentWrong")}
          </ErrorComponent>
        )}
        <div className="is-centered">
          <Button
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
            dataTestid="continue-to-payment"
            visible={true}
            type="submit"
          >
            {t("GoToPayment")}
          </Button>
        </div>
      </>
    </div>
  );
};

export default Delivery;
