import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import InputWrap from "../../../components/Forms/InputWrap";
import ListOptionWrap from "../../../components/Forms/ListOptionWrap";
import Button from "../../../components/Forms/PlaceholderComponents/Button";
import ErrorComponent from "../../../components/Forms/PlaceholderComponents/Error";
import LinkButton from "../../../components/Forms/PlaceholderComponents/LinkButton";
import iconRemove from "../../../icons/icon-remove.svg";
import API from "../../../utils/api";
import { APIS } from "../../../utils/constants";
import { currency } from "../../../utils/helpers";
import { useStateValue } from "../../../utils/state-provider";
import styles from "./Giftcard.module.scss";
import { OrderType } from "../../../utils/types";
import { logError } from "../../../utils/logger";

type FormValues = {
  cardNumber: string;
  pin: string;
};

interface GiftCardProps {
  isLoading: boolean;
  csrfToken: string;
}

export const GiftCard = ({ isLoading, csrfToken }: GiftCardProps) => {
  const { t } = useTranslation();
  const [{ data, profile }, dispatch] = useStateValue();
  const [showGiftCards, setShowGiftCards] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [hideAddForm, setHideAddForm] = useState<boolean>(false);

  const initialValues: FormValues = {
    cardNumber: "",
    pin: "",
  };

  const addGiftcard = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    try {
      let response = await API.post(APIS.giftcardAdd, values.pin, {
        params: { cardNumber: values.cardNumber },
        headers: { "x-csrf-token": csrfToken },
      });

      dispatch({
        type: "changeData",
        newData: response.data,
      });

      actions.resetForm();
      setShowForm(false);
    } catch (err: any) {
      console.log(err.response);
      actions.setSubmitting(false);
      switch (err.response.data) {
        case "InvalidCardException":
          setCardError(t("InvalidGiftCard"));
          break;
        case "GiftCardHasExpiredByDate":
          setCardError(t("GiftCardExpired"));
          break;
        case "OverLimitException":
          setCardError(t("GiftCardOverLimit"));
          break;
        case "Wrong Currency":
          setCardError(t("GiftCardWrongCurrency"));
          break;
        default:
          setCardError(t("GiftCardError"));
          break;
      }
    }
  };

  const removeGiftCard = async (cardNumber: string) => {
    try {
      API.post(APIS.giftcardRemove, {}, { params: { cardNumber } })
        .then(function (response) {
          dispatch({
            type: "changeData",
            newData: response.data,
          });

          if (response.data.payment.giftCards.length === 0) {
            setShowForm(true);
            setShowGiftCards(true);
          } else {
            setShowForm(false);
          }
        })
        .catch(function (error) {
          logError(error, "POST " + APIS.giftcardRemove);
        });
    } catch (error: any) {
      logError(error, "removeGiftCard -> error");
    }
  };

  const generateGiftCardList = () => {
    if (data.payment && data.payment.giftCards.length) {
      const giftCards = data.payment.giftCards.map(
        (item: { amount: number; cardNumber: string }) => (
          <div className={styles.addedGiftcard}>
            <strong>
              -
              {currency(
                +item.amount,
                data.summary.currencyCode,
                profile?.name ?? "enGB"
              )}
            </strong>
            <span>
              {t("Gift Card")} {item.cardNumber}
            </span>
            <button
              onClick={() => removeGiftCard(item.cardNumber)}
              className={styles.removeButton}
            >
              <img src={iconRemove} alt="" />
              {t("remove")}
            </button>
          </div>
        )
      );

      if (giftCards) return giftCards;
    }
  };

  const toggle = () => {
    setShowForm(!showForm);
  };

  useEffect(() => {
    if (data?.payment?.giftCards?.length > 0) {
      setShowForm(true);
      setShowGiftCards(true);
    }

    if (data.orderTotal - data.summary.giftCardsTotal <= 0) {
      setHideAddForm(true);
    }
  }, [data]);

  const handleChange = () => {
    setCardError(null);
  };

  if (!showGiftCards) return null;
  if (data.orderType === OrderType.Mirakl) return null;

  return (
    <div className={styles.giftCardContainer}>
      {!hideAddForm && (
        <ListOptionWrap
          name={t("Gift Card")}
          label={t("AddGiftCard")}
          innerLabel={t("giftCardPaymentInnerLablel")}
          type="button"
          images={[]}
          hasIcon={true}
          value={"giftCard"}
          onClick={() => {
            toggle();
          }}
          visible={
            (showForm && showGiftCards) || data?.payment?.giftCards?.length > 0
          }
          checked={false}
        >
          <div>
            <div className={styles.giftCardWrap}>
              {data.payment && showForm && (
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.object().shape({
                    cardNumber: Yup.string()
                      .matches(/^\d{16,20}$/, t("FieldInvalid"))
                      .required(t("FieldRequired")),
                    pin: Yup.number()
                      .typeError(t("FieldInvalid"))
                      .required(t("FieldRequired")),
                  })}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={(values, actions) => {
                    addGiftcard(values, actions);
                  }}
                >
                  {({ isSubmitting }) => (
                    <>
                      <Form
                        className={styles.giftCardForm}
                        data-testid="login-form"
                      >
                        <div>
                          {data.orderType & OrderType.Mirakl ? (
                            <div className={styles.warning}>
                              {t("giftCard:warning")}
                            </div>
                          ) : null}
                          <InputWrap
                            name="cardNumber"
                            maxlength={20}
                            placeholder="0000 0000 0000 0000 (0000)"
                            label={t("GiftCardNumber")}
                            autocomplete="off"
                            onChange={handleChange}
                            required={true}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputWrap
                            name="pin"
                            maxlength={8}
                            placeholder="0000"
                            label={t("Pin")}
                            autocomplete="off"
                            onChange={handleChange}
                            required={true}
                          />
                          <span className={styles.buttonContainer}>
                            <Button
                              className={styles.applyButton}
                              disabled={isSubmitting || isLoading}
                              type="submit"
                            >
                              {t("Apply")}
                            </Button>
                          </span>
                        </div>

                        {cardError && (
                          <ErrorComponent hasBackground={true}>
                            {cardError}
                          </ErrorComponent>
                        )}
                      </Form>
                    </>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </ListOptionWrap>
      )}
      {data.payment.giftCards.length > 0 && (
        <div className={styles.cardList}>
          {generateGiftCardList()}
          {data.orderBalance > 0 && (
            <div className="is-centered">
              <LinkButton
                clickCallback={() => setShowForm(true)}
                children={t("AddedAnotherGiftcard")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GiftCard;
