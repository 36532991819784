import { Checkbox } from "@mw/mw-web-ui/experiments";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import "core-js/features/structured-clone";
import { useStateValue } from "../../utils/state-provider";
import {
  IShipping,
  IShippingOption,
  SelectedShipmentViewModel,
} from "../../utils/types/shipping";
import styles from "./Delivery.module.scss";
import ShippingImages from "./ShippingImages";
import ShippingMethods from "./ShippingMethods";
import {
  getSelectedShipmentViewModel,
  isMethodEligibleForComplimentary,
} from "../../utils/shipping";

type IShippingOptionProps = {
  shippingOption: IShippingOption;
  index: number;
};

const complimentaryAvailable = (option: IShippingOption): boolean => {
  const available =
    option.complimentaryAvailable ||
    option.complimentaryDetails?.available === true;
  const isMkl = option.warehouse === "MKL";
  return available && !isMkl;
};

const Shipment = ({ shippingOption, index }: IShippingOptionProps) => {
  const [{ data, shipping }, dispatch] = useStateValue();
  const { t } = useTranslation();
  const selectedMethodId = useRef(shippingOption.methods[0]?.shipMethodId);

  const onClick = (
    option: IShippingOption,
    optionIndex: number,
    availableOptions: IShippingOption[]
  ) => {
    return function (id: number, warehouse: string) {
      if (!availableOptions || availableOptions.length === 0 || !shipping) {
        return;
      }
      let existingWithinScopeOfGivenOption =
        shipping!.selectedShippingOptionMethods.findIndex(
          (item: SelectedShipmentViewModel) =>
            item.warehouse === warehouse &&
            option.methods.map((x) => x.shipMethodId).includes(item.shipmentId)
        );

      let copiedShipping: IShipping = structuredClone(shipping);

      let arrayCopyOfSelected: SelectedShipmentViewModel[] = [
        ...shipping!.selectedShippingOptionMethods,
      ];

      let method = getSelectedShipmentViewModel(option, id);
      if (method) {
        if (!isMethodEligibleForComplimentary(method.shipmentId, option)) {
          method.isComplimentary = false;
          copiedShipping.availableShippingOptions[optionIndex].isComplimentary =
            false;
        }

        if (existingWithinScopeOfGivenOption !== -1) {
          arrayCopyOfSelected[existingWithinScopeOfGivenOption] = method;
        } else {
          arrayCopyOfSelected.push(method);
        }
      }

      copiedShipping.selectedShippingOptionMethods = arrayCopyOfSelected;

      dispatch({
        type: "changeShipping",
        shipping: copiedShipping,
        force: true,
      });
      selectedMethodId.current = id;
    };
  };

  return (
    <div className="content-shipping">
      <div className={styles.shippingHeader}>
        <div className={styles.shippingHeaderInfo}>
          <strong>
            {t("ShippingNumber", {
              number: index + 1,
              total: shipping.availableShippingOptions.length,
            })}
          </strong>
          <span>
            {t("SoldAndShippedBy")} <strong>{shippingOption.name}</strong>
          </span>
        </div>
      </div>
      <div>
        {complimentaryAvailable(shippingOption) && (
          <div className={styles.compHeader}>
            <div className={styles.complimentarySection}>
              <div className={styles.complimentaryMessage}>
                {shippingOption.complimentaryDetails?.complimentaryMessage && (
                  <span>
                    {shippingOption.complimentaryDetails.complimentaryMessage}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.complimentaryDelivery}>
              <Checkbox
                disabled={
                  !isMethodEligibleForComplimentary(
                    selectedMethodId.current,
                    shippingOption
                  )
                }
                label={
                  (shippingOption.complimentaryDetails?.complimentaryMessage
                    ?.length ?? 0) > 0
                    ? t("ComplimentaryShippingClaimNow")
                    : t("ComplimentaryShipping")
                }
                id={"complimentaryDelivery" + index}
                name={"complimentaryDelivery" + index}
                checked={shippingOption.isComplimentary}
                onChange={() => {
                  shippingOption.isComplimentary =
                    !shippingOption.isComplimentary;

                  let updatedShipping = shipping;
                  updatedShipping.selectedShippingOptionMethods[
                    index
                  ].isComplimentary = shippingOption.isComplimentary;

                  if (shippingOption.isComplimentary) {
                    updatedShipping.selectedShippingOptionMethods[
                      index
                    ].complimentaryPercentage =
                      shippingOption.complimentaryDetails
                        ?.complimentaryPercentage ?? 100;
                  }

                  dispatch({
                    type: "changeShipping",
                    shipping: { ...updatedShipping },
                    force: true,
                  });
                }}
              />
              {!isMethodEligibleForComplimentary(
                selectedMethodId.current,
                shippingOption
              ) && (
                <span className={styles.complimentaryUnavailableMsg}>
                  {`${t("ComplimentaryUnavailableForMethod", {
                    name: getSelectedShipmentViewModel(
                      shippingOption,
                      selectedMethodId.current
                    )?.localizedName,
                  })}`}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="content">
        <ShippingImages
          products={data.summary.items}
          shipping={shippingOption}
          isVisible={true}
          name={t("ShippingNumber") + index}
        />
        <ShippingMethods
          shippingOptions={shippingOption.methods}
          warehouse={shippingOption.warehouse}
          currencyCode={data.summary.currencyCode}
          index={index}
          onClick={onClick(
            shippingOption,
            index,
            shipping?.availableShippingOptions
          )}
          isComplimentary={shippingOption.isComplimentary}
          complimentaryDetails={shippingOption.complimentaryDetails}
        />
      </div>
    </div>
  );
};

export default Shipment;
