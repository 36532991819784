import { Button, Icon16 } from "@mw/mw-web-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { getFullPath } from "../../utils/helpers";
import useEnabledABTest from "../../utils/hooks/useEnabledABTest";
import style from "./Thanks.module.scss";

const SignupIncentive = () => {
  const { t } = useTranslation();

  const isABEnabled = useEnabledABTest(
    "usCampaignSignupIncentive",
    "testUSCampaignSignupIncentive"
  );

  const incentives = [
    t("IncentivePercentageOff"),
    t("IncentiveTrackStatus"),
    t("IncentiveFasterFuture"),
    t("IncentiveExclusiveOffers"),
  ];

  if (isABEnabled) {
    incentives.push(t("IncentiveCampaignDelivery"));
  }

  return (
    <div
      id="signupIncentivePanel"
      className={["content", "is-thankyou", style.registerPanel].join(" ")}
    >
      <div className={style.incentiveTopic}>
        <strong>{t("CreateAMWAccount")}</strong>
        <hr />
      </div>
      <div className={style.incentiveBody}>
        {incentives.map((i) => (
          <span>
            <Icon16 name="Tick Green 16" />
            {i}
          </span>
        ))}
      </div>

      <Button
        label={t("CreateAnAccount")}
        onClick={() => (window.location.href = getFullPath(t, "account"))}
      />
    </div>
  );
};

export default SignupIncentive;
