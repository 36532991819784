import { FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Address from "../../components/Address";
import { API } from "../../utils/api";
import {
  API_URL_WEBSITE,
  APIS,
  DELIVERY_ADDRESS_STAGE,
  miraklEnabledCountries,
} from "../../utils/constants";
import {
  enabledForProfile,
  getCAProvince,
  getUSState,
  handleSubmitError,
  isLoggedIn,
  isMiraklOrder,
} from "../../utils/helpers";
import { logError } from "../../utils/logger";
import { useStateValue } from "../../utils/state-provider";
import ErrorComponent from "../Forms/PlaceholderComponents/Error";
import { Stages } from "../../utils/types";
import {
  IAddress,
  IGetAddressResponse,
  IPostAddressResponse,
} from "../../utils/types/address";
import styles from "./Address.module.scss";
import { useStageRedirect } from "../../utils/hooks";

//TODO: Different PCA implementation to what we have on live.

interface IProps {
  title: string;
  billingPage?: boolean;
}

const AddressPage = ({ title, billingPage = false }: IProps) => {
  const { t } = useTranslation();
  const { stageRedirect } = useStageRedirect();
  const [{ data, profile }, dispatch] = useStateValue();
  const [savedAddresses, setSavedAddresses] = useState<
    IGetAddressResponse[] | null
  >(null);
  const [isServerValidationError, setIsServerValidationError] = useState(false);
  const [isAddressAlreadyRequested, setIsAddressAlreadyRequested] =
    useState(false);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [showMiraklError, setShowMiraklError] = useState<boolean>(false);

  const [showZipError, setShowZipError] = useState(false);
  const [showInvalidPostcodeError, setInvalidPostcodeError] = useState(false);

  const getError = (localisedMessage: string) => {
    return (
      <ErrorComponent hasBackground={true}>
        <div>{localisedMessage}</div>
      </ErrorComponent>
    );
  };

  function isInvalidPostcode(values: IAddress, postcodes: number[]) {
    if (
      postcodes.some(
        (code) =>
          code.toString() === values.postCode.substring(0, 2) ||
          code.toString() === values.postCode.substring(0, 3)
      )
    ) {
      setInvalidPostcodeError(true);
      return true;
    }
    return false;
  }

  function removeSpecialCharacters(address: IAddress) {
    var regexExpression =
      /[^a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F0-9-#°'&/ ]/g;
    address.firstName = address.firstName.replace(regexExpression, "");
    address.lastName = address.lastName.replace(regexExpression, "");
    address.city = address.city.replace(regexExpression, "");

    if (address.addressLine1)
      address.addressLine1 = address.addressLine1.replace(regexExpression, "");
    if (address.addressLine2)
      address.addressLine2 = address.addressLine2.replace(regexExpression, "");
    if (address.phone)
      address.phone = address.phone.replace(regexExpression, "");
    if (address.companyName)
      address.companyName = address.companyName.replace(regexExpression, "");
  }

  const handleSubmit = async (
    values: IAddress,
    actions: FormikHelpers<IAddress>
  ) => {
    removeSpecialCharacters(values);

    if (isMiraklOrder(data.orderType)) {
      if (!miraklEnabledCountries.some((code) => code === values.countryCode)) {
        setShowMiraklError(true);
        actions.setSubmitting(false);
        return;
      }
    }

    switch (values.countryCode) {
      case "ES": {
        if (isInvalidPostcode(values, [35, 38, 51, 52])) {
          actions.setSubmitting(false);
          return;
        }
        break;
      }
      case "FR": {
        if (isInvalidPostcode(values, [97, 986, 987, 988])) {
          actions.setSubmitting(false);
          return;
        }
        break;
      }
      default:
        break;
    }

    if (enabledForProfile("US", profile)) {
      const zip = values.postCode.substr(0, 5);

      if (getUSState(zip) !== values.province) {
        setShowZipError(true);
        actions.setSubmitting(false);
        return;
      }
    }

    if (enabledForProfile("CA", profile)) {
      if (getCAProvince(values.postCode) !== values.province) {
        setShowZipError(true);
        actions.setSubmitting(false);
        return;
      }
    }

    API.post(APIS.addressDelivery, values)
      .then(function (response) {
        dispatch({
          type: "changeData",
          newData: response.data,
        });
        if (response.data && response.data.billingAddress) {
          stageRedirect(Stages.Shipping);
        } else {
          stageRedirect(Stages.BillingAddress);
        }
        actions.setSubmitting(false);
      })
      .catch(function (error) {
        actions.setSubmitting(false);
        if (error && error.response && error.response.status === 500) {
          setIsServerValidationError(true);
          return;
        }
        handleSubmitError(
          error,
          t,
          window.history,
          "POST " + APIS.addressDelivery,
          null,
          actions
        );
      });

    await SaveAddress({ ...values });
  };

  function getProvince(code: string, address: IAddress): string {
    if (enabledForProfile(code, profile)) {
      return address.province ?? "";
    } else {
      return "";
    }
  }

  const SaveAddress = async (address: IAddress) => {
    let apiAddress: IPostAddressResponse = {
      Address1:
        address.addressLine1 ??
        (address.countryCode === "DE" ? address.street ?? "" : ""),
      Address2: address.addressLine2 ?? "",
      CAProvince: getProvince("CA", address),
      City: address.city,
      Company: address.companyName ?? "",
      CountryCode: address.countryCode ?? "",
      FirstName: address.firstName,
      LastName: address.lastName,
      Phone: address.phone ?? "",
      Postcode: address.postCode,
      State: address.province ?? "",
      Street: address.street ?? "",
      USState: getProvince("US", address),
    };
    try {
      await API.post(APIS.userAddresses, apiAddress, {
        baseURL: API_URL_WEBSITE,
      });
    } catch (error) {
      logError(error, "POST " + APIS.userAddresses, DELIVERY_ADDRESS_STAGE);
    }
  };

  const handleBilling = async (
    values: IAddress,
    actions: FormikHelpers<IAddress>
  ) => {
    if (!data?.shipping?.isDeliveryAddressRequired) {
      const digitalAnonAddress: IAddress = {
        addressLine1: "1st Street Generic Neighbourhood",
        addressLine2: "",
        street: "",
        firstName: "John",
        lastName: "Doe",
        province: "",
        city: "London",
        companyName: "",
        phone: "123456798",
        postCode: "AA1 1AA",
        countryCode: "GB",
        billingAddress: null,
      };
      await API.post(APIS.addressDelivery, digitalAnonAddress);
    }

    removeSpecialCharacters(values);

    API.post(APIS.addressBilling, values)
      .then(function (response) {
        actions.setSubmitting(false);
        dispatch({
          type: "changeData",
          newData: response.data,
        });

        if (response.data.shipping) {
          dispatch({
            type: "changeShipping",
            shipping: response.data.shipping,
            force: true,
          });
        }

        if (
          response.data.shipping.isCnC ||
          response.data.shipping.isCollectFromPoint
        ) {
          stageRedirect(Stages.Payment);
        } else {
          stageRedirect(Stages.Shipping);
        }
      })
      .catch(function (error) {
        actions.setSubmitting(false);

        if (error && error.response && error.response.status === 500) {
          setIsServerValidationError(true);
          return;
        }
        handleSubmitError(
          error,
          t,
          window.history,
          "POST " + APIS.addressBilling,
          null,
          actions
        );
      });
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.owner && isLoggedIn() && !isAddressAlreadyRequested) {
      setIsAddressAlreadyRequested(true);
      API.get(APIS.userAddresses, { baseURL: API_URL_WEBSITE })
        .then(function (response) {
          if (response.data.length > 0) {
            setSavedAddresses(response.data);
          }
        })
        .catch(function (error) {
          logError(error, "GET " + APIS.userAddresses, DELIVERY_ADDRESS_STAGE);
        });
    }
  }, [data]);

  return (
    <div className={styles.addressBox}>
      <Address
        billingPage={billingPage}
        handleSubmit={!billingPage ? handleSubmit : handleBilling}
        firstName={firstName}
        lastName={lastName}
        phone={phone}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setPhone={setPhone}
        savedAddresses={savedAddresses}
        title={title}
      />

      {isServerValidationError &&
        !showInvalidPostcodeError &&
        billingPage &&
        getError(t("SomethingWentWrong"))}

      {isServerValidationError &&
        !showInvalidPostcodeError &&
        !billingPage &&
        getError(t("address:addressValidation"))}

      {showMiraklError && getError(t("mirakl:AddressError"))}

      {showZipError && getError(t("address:postcodeInvalidError"))}

      {showInvalidPostcodeError &&
        getError(t("address:deliveryNotAvailableError"))}
    </div>
  );
};

export default AddressPage;
