import React from "react";
import styles from "./Input.module.scss";

//! This component will be replaced by an Input in the component library (seperate project). Proof of concept.

interface Props {
  name: string;
  id?: string;
  type: string;
  autoComplete?: string;
  autoCapitalize?: string;
  autocorrect?: string;
  value: string;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  pattern?: string;
  maxlength?: number;
  hidden?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (boolean: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (boolean: React.FocusEvent<HTMLInputElement>) => void;
  iconPath?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    name,
    id = name,
    placeholder = "",
    autoComplete = "on",
    autoCapitalize = "none",
    autocorrect = "off",
    onBlur,
    onChange,
    onFocus,
    value = "",
    error,
    type = "text-box",
    maxlength,
    hidden,
    iconPath,
  } = props;

  return (
    <input
      value={value}
      id={id}
      placeholder={placeholder}
      autoComplete={autoComplete}
      autoCapitalize={autoCapitalize}
      autoCorrect={autocorrect}
      maxLength={maxlength}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      type={type}
      className={`${styles.textBox} ${error ? styles.isError : ""}`}
      hidden={hidden}
      ref={ref}
      style={{
        backgroundImage: `url(${iconPath})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
    />
  );
});

export default Input;
