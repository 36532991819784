import React from "react";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../utils/state-provider";
import { Stages } from "../../utils/types";
import { IAddressResponse } from "../../utils/types/address";
import { IShippingOption } from "../../utils/types/shipping/IShippingOption";
import CompletedItem from "./CompletedItem";

interface CompletedProps {
  childComponent: React.ReactNode;
  routePageState: Stages;
}

export const Completed = ({
  childComponent,
  routePageState,
}: CompletedProps) => {
  const { t } = useTranslation();
  const [{ data, shipping, stage }] = useStateValue();
  const generateDeliveryString = (
    d: IAddressResponse,
    cnc: boolean = false
  ) => {
    const firstName = d.firstName ?? "";
    const lastName = d.lastName ?? "";
    let addressLine1 = d.addressLine1 ? `${d.addressLine1}, ` : "";
    const city = d.city ? `${d.city}, ` : "";
    const postCode = d.postCode ? `${d.postCode}` : "";
    const street = d.street ?? "";

    if (cnc) {
      return `${addressLine1} ${city} ${postCode}`;
    } else {
      if (d.countryCode === "DE" && street) addressLine1 = street;
      return `${firstName} ${lastName}, ${addressLine1} ${city} ${postCode}`;
    }
  };

  const getShippingMethod = () => {
    if (
      !shipping?.selectedShippingOptionMethods ||
      shipping.selectedShippingOptionMethods.length === 0
    ) {
      return "";
    }
    let firstShipping = shipping.selectedShippingOptionMethods[0].shipmentId;

    let item = shipping.availableShippingOptions
      .map((shipping: IShippingOption) => shipping.methods)
      .flat()
      .find((element: any) => element.shipMethodId === firstShipping);

    return item?.localizedName?.toString() ?? item?.name ?? "";
  };

  // If we're not asking user for delivery address, then the 'count' for each completed item is shifted -1
  const showDeliveryItem = data?.shipping?.isDeliveryAddressRequired ?? false;
  const deliveryItemNumber = 1;
  const billingItemNumber = showDeliveryItem ? 2 : 1;
  const shippingItemNumber = showDeliveryItem ? 3 : 2;

  /// A nicer method to decide which version of the 'delivery' completed item to use
  const deliveryItem = (): JSX.Element => {
    if (data && data.shipping?.isCnC) {
      return (
        <CompletedItem
          isVisible={data.deliveryAddress}
          count={deliveryItemNumber}
          title={t("CollectFromStore")}
          body={() => generateDeliveryString(data.deliveryAddress, true)}
          path={t("paths:/click-and-collect")}
        />
      );
    } else if (data?.shipping.isCollectFromPoint) {
      return (
        <CompletedItem
          isVisible={data.deliveryAddress}
          count={deliveryItemNumber}
          title={t("store:CollectFromPoint")}
          body={() => generateDeliveryString(data.deliveryAddress, true)}
          path={t("paths:/collect-from-point")}
        />
      );
    } else {
      return (
        <CompletedItem
          isVisible={data.deliveryAddress}
          count={deliveryItemNumber}
          title={t("DeliveryAddress")}
          body={() => generateDeliveryString(data.deliveryAddress, false)}
          path={`${t("paths:/delivery-address")}`}
        />
      );
    }
  };

  if (!data) return <CompletedItem isVisible={true} body={() => ""} />;

  /**
   * The 'completed items' on this page are displayed above {childComponent}
   * A completed item is shown if the stage of the current page is >= to the relevant info, AND the stored stage (max progression through checkout) is more than relevant info
   * In the future, if we want to show items completed by the user but are further along the checkout flow, we can either remove the `routePageState >` logic from these checks,
   * or, easily add some other <CompletedItem/> elements _below_ {childComponent} :)
   */
  return (
    <>
      {routePageState >= Stages.Address && (
        <>{data.shipping?.isDeliveryAddressRequired && deliveryItem()}</>
      )}

      {routePageState > Stages.BillingAddress &&
        stage >= Stages.BillingAddress && (
          <>
            <CompletedItem
              isVisible={
                data.deliveryAddress &&
                !data.shipping?.isCnc &&
                !data.shipping?.isCollectFromPoint
              }
              count={billingItemNumber}
              title={t("BillingAddress")}
              path={t("paths:/billing-address")}
              body={() => generateDeliveryString(data.billingAddress, false)}
            />
          </>
        )}

      {routePageState > Stages.Shipping && stage >= Stages.Shipping && (
        <CompletedItem
          count={shippingItemNumber}
          isVisible={
            shipping != null &&
            shipping.selectedShippingOptionMethods != null &&
            shipping.selectedShippingOptionMethods?.length > 0 &&
            !shipping.isCnC &&
            !shipping.isCollectFromPoint
          }
          title={t("ShippingMethod")}
          body={() => getShippingMethod()}
          path={t("paths:/delivery")}
        />
      )}

      {childComponent}
    </>
  );
};

export default Completed;
