import BillingAddress from "../pages/BillingAddress";
import ClickCollect from "../pages/ClickCollect";
import CollectFromPoint from "../pages/CollectFromPoint";
import Delivery from "../pages/Delivery";
import DeliveryAddress from "../pages/DeliveryAddress";
import LoginPage from "../pages/Login";
import Payment from "../pages/Payment";
import Thanks from "../pages/Thanks";
import Error from "../pages/Errors/Error";
import Unsupported from "../pages/Errors/Unsupported";
import PaymentRedirect from "../pages/Payment/Stripe/RedirectingPayments/PaymentRedirect";
import { Stages } from "./types";

export type CheckoutRoute = {
  authRequired: boolean;
  stage: Stages;
  path: string;
  component: () => JSX.Element;
};

const routeConfig: CheckoutRoute[] = [
  {
    stage: Stages.Login,
    path: "/login",
    component: LoginPage,
    authRequired: false,
  },
  {
    stage: Stages.Address,
    path: "/delivery-address",
    component: DeliveryAddress,
    authRequired: true,
  },
  {
    stage: Stages.Address,
    path: "/click-and-collect",
    component: ClickCollect,
    authRequired: true,
  },
  {
    stage: Stages.Address,
    path: "/collect-from-point",
    component: CollectFromPoint,
    authRequired: true,
  },
  {
    stage: Stages.BillingAddress,
    path: "/billing-address",
    component: BillingAddress,
    authRequired: true,
  },
  {
    stage: Stages.Shipping,
    path: "/delivery",
    component: Delivery,
    authRequired: true,
  },
  {
    stage: Stages.Payment,
    path: "/payment",
    component: Payment,
    authRequired: true,
  },
  {
    stage: Stages.PaymentRedirect,
    path: "/redirect",
    component: PaymentRedirect,
    authRequired: true,
  },
  {
    stage: Stages.Receipt,
    path: "/receipt",
    component: Thanks,
    authRequired: true,
  },
  {
    stage: Stages.Error,
    path: "/error",
    component: Error,
    authRequired: false,
  },
  {
    stage: Stages.Unsupported,
    path: "/unsupported",
    component: Unsupported,
    authRequired: false,
  },
];

export default routeConfig;
