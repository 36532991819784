//? This will probably change to match the API.

export enum Stages {
  Login,
  Address,
  BillingAddress,
  Shipping,
  Payment,
  PaymentRedirect,
  Receipt,
  Unsupported,
  Error,
}

export enum OrderType {
  Mountainwarehouse = 1 << 0,
  NeonSheep = 1 << 1,
  Mirakl = 1 << 2,
}

export type Option = {
  value: any;
  label: string | null;
};
