import { useField } from "formik";
import React from "react";
import styles from "./CheckboxWrap.module.scss";
import Checkbox from "./PlaceholderComponents/Checkbox";
import ErrorComponent from "./PlaceholderComponents/Error";
interface Props {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  autocomplete?: string;
}

const CheckboxWrap = ({ name, label }: Props) => {
  const [field, { error, touched }] = useField({
    name: name,
    type: name,
  });

  return (
    <div className={styles.checkboxWrap}>
      <Checkbox
        id={field.name}
        name={field.name}
        onChange={field.onChange}
        value={field.value?.toString()}
      />
      <label htmlFor={name}>{label}</label>

      {error && touched && <ErrorComponent>{error}</ErrorComponent>}
    </div>
  );
};

export default CheckboxWrap;
