import React from "react";
import styles from "./LoadWidget.module.scss";

const LoadWidget = () => {
  return (
    <div className={styles.container}>
      <div className={styles.widget} />
    </div>
  );
};

export default LoadWidget;
