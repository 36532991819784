import { AxiosRequestConfig, default as axios, default as Axios } from "axios";
import axiosRetry from "axios-retry";
import Cookies from "js-cookie";
import {
  APIS,
  API_URL,
  API_URL_WEBSITE,
  storageBasketToken,
  storageOrderToken,
} from "./constants";

axios.defaults.withCredentials = true;

export function setTokenHeader(
  config: AxiosRequestConfig,
  tokenName: string
): any {
  const token = Cookies.get(tokenName);

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete API.defaults.headers.common.Authorization;
  }
  return config;
}

// sets up axios retry policy - we only want to retry on the endpoints within
// filteredEndpoints
function setUpAxiosRetryPolicy() {
  /* eslint-disable */ // disabling due to no-useless-escape rule but this is for regexes so it's not useless
  const filteredEndpoints: RegExp[] = [
    new RegExp(`${API_URL_WEBSITE}address\/countries\/(\d+)`),
    new RegExp(APIS.deliveryCollectionPoint),
    new RegExp(`${APIS.deliveryCNC}\/(\d+)`),
    new RegExp(APIS.delivery),
  ];
  /* eslint-enable */

  const retryFilter = (endpoint: any): boolean => {
    return filteredEndpoints.some((r) => r.test(endpoint));
  };

  [API, Axios].forEach((client) =>
    axiosRetry(client, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: (error) => retryFilter(error.request.responseURL),
    })
  );
}

export const API = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

setUpAxiosRetryPolicy();

API.interceptors.request.use(
  (config) => setTokenHeader(config, storageOrderToken),
  (error) => Promise.reject(error)
);

export const WebLoginApiClient = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

WebLoginApiClient.interceptors.request.use(
  (config) => setTokenHeader(config, storageBasketToken),
  (error) => Promise.reject(error)
);

export default API;

export const B2cLoginApiClient = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});
