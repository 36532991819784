/**
 * Custom React hook to check if an A/B test is enabled based on a query parameter or the presence of a specified class in the DOM.
 *
 * @param paramName The name of the query parameter to check.
 * @param className The class name to search for in the DOM.
 *
 * @returns true if either the query parameter is present with the specified paramName or the className is found in the DOM; otherwise, returns false.
 */
const useEnabledABTest = (
  paramName: string,
  className: string = ""
): boolean => {
  const isQueryParamPresent = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const qaParamValue = urlSearchParams.get("qa");
    return qaParamValue === paramName;
  };

  const isClassPresent = () =>
    document.getElementsByClassName(className).length > 0;

  const result = isQueryParamPresent() || isClassPresent();

  return result;
};

export default useEnabledABTest;
