import React from "react";
import { useTranslation } from "react-i18next";
import useDeliveryEstimate from "../../utils/hooks/useDeliveryEstimate";
import Skeleton from "react-loading-skeleton";
import ListOption from "../../components/Forms/PlaceholderComponents/ListOption";
import { currency } from "../../utils/helpers";
import { useStateValue } from "../../utils/state-provider";
import styles from "./ShippingMethod.module.scss";

interface Props {
  name: string;
  label: string;
  type?: string;
  shipmentId: number;
  warehouse: string;
  rate: string;
  skeleton?: boolean;
  currencyCode: string;
  checked?: boolean;
  deliveryTime: string;
  onClick: any;
}

const ShippingMethod = ({
  name,
  label,
  type = "text",
  shipmentId,
  warehouse,
  rate,
  currencyCode,
  skeleton = false,
  checked = false,
  onClick,
  deliveryTime,
}: Props) => {
  const { t } = useTranslation();

  const [{ profile }] = useStateValue();
  const estimatedDeliveryTime = useDeliveryEstimate(deliveryTime);

  const getId = (id: number, warehouse: string) => `${id}_${warehouse}`;

  if (skeleton) {
    return (
      <div>
        {/*  eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={getId(shipmentId, warehouse)}>
          <span>
            <Skeleton />
          </span>
          <span className={styles.value}>
            <Skeleton width={40} />
          </span>
        </label>
      </div>
    );
  }

  const getRate = (): string => {
    if (+rate === 0) {
      return t("Free");
    }

    return currency(+rate, currencyCode, profile?.name ?? "enGB");
  };

  return (
    <div className={styles.itemContainer}>
      <ListOption
        name={name}
        label={label}
        innerLabel={estimatedDeliveryTime}
        type={type}
        value={getId(shipmentId, warehouse)}
        extraInfo={getRate()}
        currencyCode={currencyCode}
        skeleton={skeleton}
        onClick={() => {
          onClick(shipmentId, warehouse);
        }}
        checked={checked}
      />
    </div>
  );
};

export default ShippingMethod;
