import { RedirectRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { datadogLogs } from "@datadog/browser-logs";
import { detect } from "detect-browser";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadWidget from "../../components/LoadWidget";
import { WebLoginApiClient } from "../../utils/api";
import { loginRequest } from "../../utils/authConfig";
import { APIS, performanceGroup, storageStage } from "../../utils/constants";
import { isAnalyticConsented, isLoggedIn } from "../../utils/helpers";
import { useStageRedirect } from "../../utils/hooks";
import { useStateValue } from "../../utils/state-provider";
import { Stages } from "../../utils/types";
import GuestForm from "./GuestForm";
import styles from "./Login.module.scss";

//TODO: Tabs could go in to their own shared component.

const isUserOnInitialScreen = (currentStage: number) => currentStage === 0;

export const Login = ({ successfulLoginHandler, failureLoginHandler }: any) => {
  const { t, i18n } = useTranslation();
  const [activeTab] = useState<number>(1); // 1 = Guest, 2 = Login
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentStage, setCurrentStage] = useState(0);
  const [azureB2CLoginRequest, setAzureB2CLoginRequest] =
    useState<any>(loginRequest);
  const [, dispatch] = useStateValue();

  const { instance } = useMsal(); // TODO - inject callback
  const [isLoading, setIsLoading] = useState(false);
  const [{ profile }] = useStateValue();
  const { stageRedirect } = useStageRedirect();

  const browser = detect();
  if (browser?.name === "ie") {
    stageRedirect(Stages.Unsupported);
  }

  const handleAutoLogin = async () => {
    setIsLoading(true);

    try {
      datadogLogs.logger.info("Login attempt", { evt: { name: "auto" } });
      await WebLoginApiClient.post(APIS.orderAuto)
        .then((response) => {
          successfulLoginHandler(response, {
            name: "auto",
            outcome: "success",
          });
        })
        .catch((error: any) => {
          failureLoginHandler(error, { name: "auto", outcome: "failure" });
        });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isUserOnInitialScreen(currentStage) && isLoggedIn()) {
      handleAutoLogin();
    }

    if (i18n) {
      const req: RedirectRequest = Object.assign({}, loginRequest);

      if (!req.extraQueryParameters) {
        req.extraQueryParameters = {};
      }

      req.extraQueryParameters.ui_locales = t("CultureName");
      req.redirectUri = window.location.href;
      setAzureB2CLoginRequest(req);
    }
  }, [i18n, currentStage]);

  const checkStage = () => {
    const stage = +(localStorage.getItem(storageStage) ?? "0");
    setCurrentStage(stage);

    if (stage > 0) {
      stageRedirect(stage as Stages);
    } else {
      dispatch({ type: "changeStage", newStage: 0 });
    }
  };

  useEffect(() => {
    if (isAnalyticConsented(performanceGroup)) {
      ga("send", "pageview", window.location.pathname);
    }
    checkStage();
  }, []);

  return isLoading ? (
    <LoadWidget />
  ) : (
    <div className={styles.loginContainer}>
      <h1 className="side-pad is-centered h2">{t("LoginWelcome")}</h1>

      <div className={styles.loginWrap}>
        <GuestForm
          successfulLoginHandler={successfulLoginHandler}
          failureLoginHandler={failureLoginHandler}
          activeTab={activeTab}
          signInHandler={() => {
            setIsLoading(true);
            datadogLogs.logger.info("Login attempt", {
              evt: { name: "b2c" },
              request: azureB2CLoginRequest,
            });
            instance.loginRedirect(azureB2CLoginRequest);
          }}
        />
      </div>

      {profile?.countryCode === "AU" && (
        <div className={styles.arnText}>{t("ARN")}</div>
      )}
    </div>
  );
};

export default Login;
