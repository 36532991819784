import React from "react";
import styles from "./Delivery.module.scss";
import { IShippingMethod, IShippingOption } from "../../utils/types/shipping";
import { IItem } from "../../utils/types/products";

interface IProps {
  products: IItem[];
  shipping: IShippingOption;
  name: string;
  isVisible: boolean;
}

export const ShippingImages = ({
  products,
  shipping,
  name,
  isVisible,
}: IProps) => {
  if (!isVisible || shipping === null || products.length === 0) return <></>;

  const items: IItem[] = products.filter((item: IItem) => {
    return (
      shipping?.productSkus &&
      shipping.productSkus.some((id: string) => id === item.sku)
    );
  });

  return (
    <div className={styles.items}>
      <ul>
        {items.map((item: IItem) => {
          shipping?.methods &&
            shipping.methods.forEach((option: IShippingMethod) => {
              option.warehouse = item.warehouse;
            });

          return (
            <div className="content-row">
              <li key={(item.name + item.colour).replace(" ", "_")}>
                <div className={styles.productImage}>
                  <img
                    src={`${item.imageUrl.split("?")[0]}?w=29`}
                    alt={item.name}
                  />
                </div>
                <div className={styles.itemContent}>
                  <strong>{item.name}</strong>
                  {(item.colour || item.size) && (
                    <span>{`${item.colour} | ${item.size}`}</span>
                  )}
                </div>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
export default ShippingImages;
