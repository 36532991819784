import React from "react";
import { ZENDESK_URL } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  const contactUs = ZENDESK_URL + t("paths:/help");

  return (
    <div className={styles.footer}>
      <ul className={styles.linkList}>
        <li className={styles.linkListItem}>
          <a
            href={`${contactUs}/${t("paths:/terms")}`}
            target="_blank"
            className="footer-link"
            rel="noreferrer"
          >
            {t("translations:TermsAndConditions")}
          </a>
        </li>
        <li className={styles.linkListItem}>
          <a
            href={`${contactUs}/${t("paths:/data")}`}
            target="_blank"
            className="footer-link"
            rel="noreferrer"
          >
            {t("translations:DataProtection")}
          </a>
        </li>
        <li className={styles.linkListItem}>
          <a
            href={`${contactUs}/${t("paths:/rightToReturn")}`}
            target="_blank"
            className="footer-link"
            rel="noreferrer"
          >
            {t("translations:RightToReturn")}
          </a>
        </li>
        <li className={styles.linkListItem}>
          <a
            href={`${contactUs}/${t("paths:/paymentMethods")}`}
            target="_blank"
            className="footer-link"
            rel="noreferrer"
          >
            {t("translations:PaymentMethods")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
