import i18n from "i18next";
import LanguageDetector, {
  DetectorOptions,
} from "i18next-browser-languagedetector";
import { getBasketToken, getConsentCookie } from "./helpers";
import { initReactI18next } from "react-i18next";
import enGB from "../locales/en-GB.json";
import enAU from "../locales/en-AU.json";
import frFR from "../locales/fr-FR.json";
import deDE from "../locales/de-DE.json";
import enCA from "../locales/en-CA.json";
import frCA from "../locales/fr-CA.json";
import enIE from "../locales/en-IE.json";
import enNZ from "../locales/en-NZ.json";
import esES from "../locales/es-ES.json";
import plPL from "../locales/pl-PL.json";
import enUS from "../locales/en-US.json";
import { profilesMap } from "./constants";

const supportedLanguages = {
  enGB,
  enAU,
  frFR,
  deDE,
  enCA,
  frCA,
  enIE,
  enNZ,
  esES,
  plPL,
  enUS,
};

// Custom Language Detector
export const customLanguageDetector = {
  name: "customDetector",

  lookup(options: DetectorOptions) {
    const basketToken = getBasketToken();
    let detectedLanguage = "";

    if (basketToken) {
      const cultureCode = basketToken.cultureCode;
      if (
        cultureCode &&
        cultureCode.length === 5 &&
        cultureCode.indexOf("-") === 2
      ) {
        let language = cultureCode.replace("-", "");
        if (language in supportedLanguages) {
          detectedLanguage = language;
        }
      }

      // When language can't be detected by the basket.cultureCode, try fail back to use the profile
      if (!detectedLanguage) {
        let profile = profilesMap.get(basketToken.profileId);

        if (profile) {
          if (profile.name in supportedLanguages) {
            detectedLanguage = profile.name;
          }
        }
      }
    }

    const html = document.querySelector("html");
    if (html) {
      if (detectedLanguage) {
        const language = detectedLanguage.substring(0, 2);
        const region = detectedLanguage.substring(2);
        html.setAttribute("lang", `${language}-${region}`);
      } else {
        html.setAttribute("lang", `en-GB`);
      }
    }

    loadOneTrustScript(getConsentCookie);

    return detectedLanguage || "";
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customLanguageDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["customDetector"],
    },
    resources: supportedLanguages,
    fallbackLng: "enGB",
    debug: process.env.REACT_APP_CUSTOM_NODE_ENV !== "Production",
    ns: ["translations", "paths"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

// Function to dynamically load the OneTrust script
function loadOneTrustScript(callback: any) {
  const script = document.createElement("script");
  script.src = "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js";
  script.type = "text/javascript";
  script.charset = "UTF-8";
  script.setAttribute(
    "data-domain-script",
    "3de5e030-6a0c-44d3-971e-6f829168336f"
  );
  script.setAttribute("data-document-language", "true");
  script.onload = function () {
    if (typeof callback === "function") {
      callback();
    }
  };
  document.head.appendChild(script);
}

export default i18n;
