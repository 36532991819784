import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as IconPayment } from "../../../icons/icon-payment.svg";
import styles from "./ListOption.module.scss";

export interface ListOptionProps {
  name: string;
  label: string;
  innerLabel?: string;
  innerLabelPosition?: "right" | "bottom";
  type?: string;
  value: string;
  extraInfo?: string;
  pillComponent?: {
    label: string;
    backgroundColour: string;
    colour: string;
  };
  skeleton?: boolean;
  currencyCode?: string;
  images?: string[];
  checked?: boolean;
  visible?: boolean;
  hasIcon?: boolean;
  onClick?: any;
  imageStyle?: CSSProperties | undefined;
  bodyStyle?: any;
}

const ListOption = ({
  name,
  label,
  innerLabel,
  innerLabelPosition = "right",
  type = "text",
  value,
  extraInfo,
  pillComponent,
  images = [],
  skeleton = false,
  checked = false,
  hasIcon = false,
  onClick,
  imageStyle,
  currencyCode,
  bodyStyle,
}: ListOptionProps) => {
  const { t } = useTranslation();

  if (skeleton) {
    return (
      <div>
        {/*  eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={styles.label} htmlFor={value}>
          <span className={styles.body}>
            <Skeleton />
          </span>
          <span className={styles.value}>
            <Skeleton width={40} />
          </span>
        </label>
      </div>
    );
  }

  return (
    <div>
      <input
        onClick={(e) => {
          if (onClick) onClick(e);
        }}
        value={value}
        className={styles.radio}
        type={type}
        name={name}
        id={value}
        checked={checked}
        defaultChecked={checked}
      />
      <label className={styles.label} htmlFor={value}>
        {type === "radio" && !hasIcon && (
          <input
            className={styles.radioButton}
            type={type}
            id={value}
            name={name + "radio"}
            checked={checked}
            defaultChecked={checked}
          />
        )}

        {hasIcon && <IconPayment width={20} height={20} stroke="black" />}
        <span
          className={`${styles.body} ${
            (innerLabelPosition === "right" && styles.right) ||
            (innerLabelPosition === "bottom" && styles.bottom)
          }`}
          style={bodyStyle}
        >
          <div> {label}</div>
          {label === "Klarna" && currencyCode === "DE" && (
            <div className={styles.klarnaText}>{t("klarnaMessage")}</div>
          )}
          {innerLabel && <div className={styles.innerLabel}>{innerLabel}</div>}
        </span>

        <span className={styles.sideContainer}>
          {images &&
            images.length > 0 &&
            images.map((image) => (
              <img
                className={styles.image}
                alt="payment card"
                style={imageStyle}
                src={image ?? ""}
              />
            ))}
          {extraInfo && <div className={styles.extraInfo}>{extraInfo}</div>}
          {pillComponent && (
            <div
              className={styles.pill}
              style={{
                backgroundColor: pillComponent.backgroundColour,
                color: pillComponent.colour,
              }}
            >
              <div className={styles.pillLabel}>{pillComponent.label}</div>
            </div>
          )}
        </span>
      </label>
    </div>
  );
};

export default ListOption;
