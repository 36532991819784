function useQAParam(paramName: string) {
  const isQueryParamPresent = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const qaParamValue = urlSearchParams.get("qa");
    return qaParamValue === paramName;
  };

  return isQueryParamPresent();
}

export default useQAParam;
