import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import iconEdit from "../../icons/icon-edit.svg";
import iconTick from "../../icons/icon-tick.svg";
import { useStageRedirect } from "../../utils/hooks";
import useEnabledABTest from "../../utils/hooks/useEnabledABTest";
import styles from "./CompletedItem.module.scss";

interface IProps {
  title?: string;
  path?: string;
  count?: number;
  isVisible: boolean;
  body: () => string;
}

export const CompletedItem = ({ title, path, isVisible, body }: IProps) => {
  const { t } = useTranslation();
  const { pathRedirect } = useStageRedirect();
  const abDisplayForMobile = useEnabledABTest(
    "mobileCompletedItems",
    "test-mobileCompletedItems"
  );

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--test-completed-items-mobile-visibility",
      abDisplayForMobile ? "flex" : "none"
    );
  }, [abDisplayForMobile]);

  if (!isVisible) return null;

  const edit = () => {
    if (path) {
      pathRedirect(path);
    }
  };

  return (
    <div
      className={styles.completedItem}
      onClick={() => edit()}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key.toLowerCase() === "enter") edit();
      }}
    >
      {!title ? (
        <Skeleton className={styles.tick} width={16} />
      ) : (
        <img className={styles.tick} src={iconTick} alt="Completed task" />
      )}

      <div className={styles.copy}>
        <strong>{title || <Skeleton width={200} />}</strong>
        <span className={styles.body}>
          {body() || <Skeleton width={250} />}
        </span>
      </div>

      {title ? (
        <div className={styles.editButton}>
          <img tabIndex={-1} src={iconEdit} alt="" />
          <span>{t("change")}</span>
        </div>
      ) : (
        <Skeleton width={40} />
      )}
    </div>
  );
};

export default CompletedItem;
