import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  CardNetworkBrand,
  PaymentMethod,
  PaymentMethodCreateParams,
} from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { APIS as apis, appApiClient } from "../../../../api";
import MissingStock from "../../../../components/Errors/MissingStock";
import ErrorComponent from "../../../../components/Forms/PlaceholderComponents/Error";
import Tooltip from "../../../../components/Forms/Tooltip";
import LoadWidget from "../../../../components/LoadWidget";
import API from "../../../../utils/api";
import {
  APIS,
  PAYMENT_STAGE,
  ZENDESK_URL,
  storageStage,
} from "../../../../utils/constants";
import { removeStorageOrderToken } from "../../../../utils/helpers";
import { useStageRedirect } from "../../../../utils/hooks";
import { logError, Severity } from "../../../../utils/logger";
import { useStateValue } from "../../../../utils/state-provider";
import { Stages } from "../../../../utils/types";
import { paymentIntentStatus } from "../../../../utils/types/payments";
import { ICardPaymentFormProps } from "../IPaymentFormProps";
import "../StripeStyles.css";
import styles from "./CardForm.module.scss";
interface CardFormLocationProps {
  completed3ds?: boolean;
  paymentIntent?: string;
  error?: string;
  isCustomerError?: boolean;
}

type BillingDetails = PaymentMethodCreateParams.BillingDetails;

const CardForm = ({
  billingAddress,
  csrfToken,
  submitHandler,
  paymentIntentHandler,
  methods,
}: ICardPaymentFormProps) => {
  const { t } = useTranslation();
  const { stageRedirect } = useStageRedirect();

  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [{ profile }] = useStateValue();
  const [cancelledPaymentError, setCancelledPaymentError] =
    useState<boolean>(false);

  const [formError, setFormError] = useState<string | null>(null);
  const contactUs = ZENDESK_URL + t("paths:/help");
  const [products, setProducts] = useState<[]>([]);

  const locationState = useLocation()?.state as CardFormLocationProps;
  const [completed3ds] = useState<boolean>(
    locationState?.completed3ds ?? false
  );
  const [intentId3ds] = useState<string | undefined>(
    locationState?.paymentIntent
  );
  const [error, setError] = useState<string | null>(
    locationState?.error ?? null
  );
  const [isCustomerError, setIsCustomerError] = useState<boolean>(
    locationState?.isCustomerError ?? false
  );

  const [cardNetworks, setCardNetworks] = useState<CardNetworkBrand[]>([]);

  const getBillingDetails = (): BillingDetails => {
    let billingDetails: BillingDetails;

    billingDetails = {
      address: {
        line1: billingAddress.addressLine1 ?? "",
        line2: billingAddress.addressLine2 ?? "",
        city: billingAddress.city ?? "",
        country: billingAddress.countryCode ?? "",
        postal_code: billingAddress.postCode ?? "",
        state: billingAddress.province ?? "",
      },
      name: `${billingAddress.firstName} ${billingAddress.lastName}` ?? "",
      email: billingAddress.email ?? "",
      phone: billingAddress.phone ?? "",
    };

    return billingDetails;
  };

  useEffect(() => {
    submitHandler(() => {
      return () => handleSubmit(csrfToken || "");
    });
  }, [csrfToken]);

  let completingPayment = false;
  useEffect(() => {
    if (completingPayment) return;

    const authoriseAndCapture = async (intent: string) => {
      const authStatus = await authorisePayment(null, intent, csrfToken ?? "");
      if (authStatus) {
        if (authStatus.isError) {
          // Request authentication
          logError(error, "Payment Intent Authorisation Error", PAYMENT_STAGE);
          setError(t("SomethingWentWrong"));
        } else {
          await completePayment(
            authStatus.paymentIntentClientSecret,
            csrfToken ?? ""
          );
        }
      } else {
        logError(error, "Payment Intent Authorisation Error", PAYMENT_STAGE);
        setError(t("SomethingWentWrong"));
      }
    };

    if (completed3ds) {
      if (intentId3ds) {
        completingPayment = true;
        authoriseAndCapture(intentId3ds);
      } else {
        setError(t("SomethingWentWrong"));
      }
    }
  }, [completed3ds, intentId3ds]);

  const handleSubmit = async (csrf: string) => {
    setIsLoading(true);
    //Block native form submission.

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (cardElement) {
      // Use your card Element with other Stripe.js APIs
      // Collects card details and creates a PaymentMethod
      const { error, paymentMethod } = await stripe?.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: getBillingDetails(),
      });

      if (error) {
        setFormError(error.message ?? "");
        setIsLoading(false);

        if (error.type === "validation_error") {
          setIsCustomerError(true);
        } else {
          logError(error, "Stripe create payment method failed -> error");
        }
      } else {
        if (paymentMethod) {
          const authorisedPaymentIntent = await authorisePayment(
            paymentMethod,
            null,
            csrf
          );
          if (authorisedPaymentIntent) {
            if (authorisedPaymentIntent.requiresAction) {
              // Request authentication
              await handleAction(
                authorisedPaymentIntent.paymentIntentClientSecret,
                csrf
              );
            } else {
              await completePayment(
                authorisedPaymentIntent.paymentIntentClientSecret,
                csrf
              );
            }
          }
        }
      }
    }
  };

  const authorisePayment = async (
    paymentMethod: PaymentMethod | null,
    paymentIntentId: string | null,
    csrf: string
  ): Promise<paymentIntentStatus | undefined> => {
    const additionalData = new Map<string, any>([
      ["paymentMethodId", paymentMethod ? paymentMethod.id : ""],
      ["paymentIntentId", paymentIntentId ?? ""],
    ]);

    const uri = APIS.paymentIntent.replace("{1}", "Stripe");

    try {
      setIsLoading(true);

      const request = {
        paymentMethod: paymentMethod ?? {
          id: paymentIntentId,
          type: methods[0],
        },
      };

      let response = await paymentIntentHandler(request, additionalData, csrf);

      if (!response) {
        throw Error();
      }

      return response?.data;
    } catch (err) {
      let error: any = err;
      setIsLoading(false);

      if (error?.response?.status === 400 && error.response.data) {
        //customer related errors
        if (error.response.data.includes("minimum_charge")) {
          setIsCustomerError(false);
          const minimum: string = Number(
            error.response.data.split(": ")[1]
          ).toLocaleString(profile!.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          setError(t("MinimumTotalStripeError", { total: minimum }));
          return;
        }

        setIsCustomerError(true);
        setError(error.response.data); //stripe customer error message
        logError(error, "POST " + uri, PAYMENT_STAGE, Severity.Warning);
      } else {
        logError(error, "POST " + uri, PAYMENT_STAGE, Severity.Fatal);
        if (error?.response?.status === 409) {
          stageRedirect(Stages.Login);
          return;
        }
        if (error?.response?.status === 410) {
          setProducts(error.response.data);
          removeStorageOrderToken();
          localStorage.clear();
        }
        if (error?.response?.status === 500) {
          //API or network error
          setError(t("SomethingWentWrong"));
        } else if (error.request) {
          setError(t("SomethingWentWrong"));
        } else {
          setError(t("SomethingWentWrong"));
        }
      }
    }
  };

  const capturePayment = async (paymentIntentId: string) => {
    let data = {
      gatewayReference: paymentIntentId,
    };
    const uri = APIS.paymentCapture.replace("{1}", "Stripe");
    try {
      return await API.post(uri, data);
    } catch (error: any) {
      // Attempt to cancel, move to receipt error page if fails
      logError(
        error,
        "POST stripe.capture.CapturePayment",
        PAYMENT_STAGE,
        Severity.Error
      );
      try {
        let data = {
          paymentIntentId,
        };
        const uri = apis.APP_API.endpoints.paymentCancel("Stripe").endpoint;
        await appApiClient.post(uri, data);
        setIsLoading(false);
        setError(t("SomethingWentWrong"));
      } catch (cancelError: any) {
        if (error?.response?.status === 409) {
          // 409 conflict, it has already been cancelled
          setIsLoading(false);
          setCancelledPaymentError(true);
          logError(
            error,
            "POST stripe.capture.CancelPayment",
            PAYMENT_STAGE,
            Severity.Fatal
          );
        } else {
          setIsLoading(false);
          setError(t("SomethingWentWrong"));
          logError(error, uri, PAYMENT_STAGE, Severity.Fatal);
          stageRedirect(Stages.Receipt, { unsuccessful: true });
        }
      }
    }
  };

  const handleAction = async (clientSecret: string, csrf: string) => {
    if (!stripe) {
      logError(null, "handleAction -> Failed to get stripe");
      return;
    }

    localStorage.setItem(storageStage, Stages.PaymentRedirect.toString());
    const { error, paymentIntent } = await stripe.handleCardAction(
      clientSecret
    );

    if (error) {
      logError(
        error,
        "POST stripe.handleCardAction",
        PAYMENT_STAGE,
        Severity.Fatal
      );

      setIsLoading(false);
      setError(t("SomethingWentWrong"));
    } else if (paymentIntent?.status === "requires_confirmation") {
      let authorisedPaymentIntent = await authorisePayment(
        null,
        paymentIntent.id,
        csrf
      );

      if (authorisedPaymentIntent) {
        if (authorisedPaymentIntent.isError) {
          // Request authentication
          logError(error, "Payment Intent Authorisation Error", PAYMENT_STAGE);
        } else {
          await completePayment(
            authorisedPaymentIntent.paymentIntentClientSecret,
            csrf
          );
        }
      }
    } else if (paymentIntent?.status === "succeeded") {
      if (paymentIntent.client_secret) {
        await completePayment(paymentIntent.client_secret, csrf);
      }
    }
  };

  const completePayment = async (clientSecret: string, csrf: string) => {
    if (!stripe) {
      logError(null, "completePayment -> Failed to get stripe", PAYMENT_STAGE);
      return;
    }

    const { error, paymentIntent } = await stripe.retrievePaymentIntent(
      clientSecret
    );

    if (error) {
      logError(
        error,
        "POST stripe.retrievePaymentIntent",
        PAYMENT_STAGE,
        Severity.Fatal
      );

      setIsLoading(false);
      setError(t("SomethingWentWrong"));
    } else if (paymentIntent) {
      let response = await capturePayment(paymentIntent.id);
      if (response?.status === 200) {
        stageRedirect(Stages.Receipt);
      }
    } else {
      logError(
        null,
        " completePayment -> payment intent is null or undefined",
        PAYMENT_STAGE
      );
      setIsLoading(false);
      setError(t("SomethingWentWrong"));
    }
  };

  const onNetworksChange = (event: {
    elementType: "cardNumber";
    networks?: CardNetworkBrand[];
  }) => {
    if (event.networks) {
      setCardNetworks(event.networks);
    }
  };

  return (
    <div className={styles.paymentContainer}>
      <form>
        <div>
          <div className={styles.cardInput}>
            <label htmlFor={"cardNumberElementInput"}>{t("CardNumber")}</label>
            <div className="cardLockIcon">
              <div className={styles.cardElement}>
                <CardNumberElement
                  id={"cardNumberElementInput"}
                  onNetworksChange={onNetworksChange}
                  options={{
                    showIcon: true,
                    preferredNetwork: cardNetworks,
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.cardInfoContainer}>
            <div className={styles.cardInput} style={{ flex: 1 }}>
              <label htmlFor={"cardExpiryElementInput"}>
                {t("ExpiryDate")}
              </label>
              <div className={styles.additionalCardElement}>
                <CardExpiryElement id={"cardExpiryElementInput"} />
              </div>
            </div>
            <div className={styles.cardInput}>
              <div className={styles.cvvInput}>
                <label htmlFor={"cardCvcElementInput"}>CVV</label>
                <Tooltip text={t("CvvTooltip")} />
              </div>
              <div className="cardLockIconAdditional">
                <div className={styles.additionalCardElement}>
                  <CardCvcElement
                    id={"cardCvcElementInput"}
                    options={{
                      placeholder: "000",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <LoadWidget />}
      </form>
      {error && (
        <ErrorComponent
          hasBackground={true}
          contactUs={contactUs}
          isCustomerError={isCustomerError}
        >
          {error}
        </ErrorComponent>
      )}
      {products.length > 0 && <MissingStock products={products} />}
      {formError && (
        <ErrorComponent
          hasBackground={true}
          contactUs={contactUs}
          isCustomerError={isCustomerError}
        >
          {formError}
        </ErrorComponent>
      )}
      {cancelledPaymentError && (
        <ErrorComponent hasBackground={true} isCustomerError={isCustomerError}>
          <Trans i18nKey="CancelledPaymentError">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={ZENDESK_URL + t("paths:/help")}
            >
              {t("ContactUs")}
            </a>
          </Trans>
        </ErrorComponent>
      )}
    </div>
  );
};

export default CardForm;
