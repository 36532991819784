import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentMethod } from ".";
import PaymentListOption from "../../components/Payment/PaymentListOption";
import API from "../../utils/api";
import { APIS, storagePayment } from "../../utils/constants";
import { logError } from "../../utils/logger";
import { useStateValue } from "../../utils/state-provider";
import { IPaymentMethods } from "../../utils/types/payments";
import PayPal from "./PayPal";
import PayPalMessage from "./PayPal/PayPalMessage";
import StripeForm from "./Stripe";
import { isMobile } from "react-device-detect";
import useEnabledABTest from "../../utils/hooks/useEnabledABTest";

interface IPaymentMethodsProps {
  selectedPaymentMethod: string | null;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<string | null>>;
  csrfToken: string;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  setHandlePayment: any;
}

const PaymentMethods = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  csrfToken,
  setError,
  setHandlePayment,
}: IPaymentMethodsProps) => {
  const { t } = useTranslation();

  const [{ data, profile }] = useStateValue();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const isABTestEnabled = useEnabledABTest(
    "paypal-test",
    "show-paypal-button-test"
  );

  const handleSelectedPaymentMethod = (paymentMethod: string) => {
    if (selectedPaymentMethod === paymentMethod) return;

    setSelectedPaymentMethod(paymentMethod);

    localStorage.setItem(storagePayment, paymentMethod);
  };

  async function getPaymentMethods() {
    try {
      var response = await API.get<IPaymentMethods>(APIS.paymentMethods, {
        params: {
          countryCode: profile?.countryCode,
        },
      });

      setPaymentMethods(response.data.availableMethods.methods);
      setSelectedPaymentMethod(response.data.availableMethods.methods[0].type);
    } catch (error) {
      logError(error, "Error getting payment methods");
    }
  }
  useEffect(() => {
    if (profile) {
      getPaymentMethods();
    }
  }, [profile]);

  return (
    <>
      {paymentMethods.map((method, index) => {
        if (method.type === "paypal") {
          return (
            <PaymentListOption
              method={method}
              index={index}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={handleSelectedPaymentMethod}
            >
              {!isABTestEnabled && <PayPal />}

              <PayPalMessage />
            </PaymentListOption>
          );
        } else {
          if (method.type === "mobile" && !isMobile) return <></>;
          return (
            <StripeForm
              csrfToken={csrfToken}
              errorHandler={() => setError(t("SomethingWentWrong"))}
              billingAddress={data.billingAddress}
              onSubmit={setHandlePayment}
              method={method}
              index={index}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={handleSelectedPaymentMethod}
            />
          );
        }
      })}
    </>
  );
};

export default PaymentMethods;
