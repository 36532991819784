import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Forms/PlaceholderComponents/Button";
import API from "../../../utils/api";
import { APIS, PAYMENT_STAGE } from "../../../utils/constants";
import { useStageRedirect } from "../../../utils/hooks";
import { logError, Severity } from "../../../utils/logger";
import { Stages } from "../../../utils/types";
import styles from "./PDQ.module.scss";

const PDQ = ({ csrfToken }: any) => {
  const { t } = useTranslation();
  const { stageRedirect } = useStageRedirect();

  const Authorise = async () => {
    const uri = APIS.paymentAuthorize.replace("{1}", "Phone");
    try {
      await API.post(uri, {}, { headers: { "x-csrf-token": csrfToken } });
      submitPayment();
    } catch (error) {
      logError(error, uri, `PDQ ${PAYMENT_STAGE}`, Severity.Fatal);
    }
  };

  const submitPayment = async () => {
    const uri = APIS.paymentSubmit.replace("{1}", "Phone");
    try {
      await API.post(uri, null, { headers: { "x-csrf-token": csrfToken } });
      stageRedirect(Stages.Receipt);
    } catch (error) {
      logError(error, uri, `PDQ ${PAYMENT_STAGE}`, Severity.Fatal);
    }
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        Authorise();
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.container}>
          <Button disabled={isSubmitting} type="submit">
            {t("PayNow")} PDQ
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default PDQ;
