import React, { useState, useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { b2cPolicies, msalConfig } from "../../utils/authConfig";
import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import LoadWidget from "../LoadWidget";
import { logError } from "../../utils/logger";

const Ab2cInstanceFactory = ({ children }) => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE && event.error) {
        logError(event, "Azure B2C login");
      }

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (
          (event?.payload &&
            event.payload.idTokenClaims["acr"] ===
              b2cPolicies.names.editProfile) ||
          event.payload.idTokenClaims["acr"] ===
            b2cPolicies.names.forgotPassword
        ) {
          return instance.logout();
        }
      }
    });

    setIsLoading(false);

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  if (isLoading) return <LoadWidget />;
  return <>{children}</>;
};

const msalInstance = new PublicClientApplication(msalConfig);

export const Ab2cInstanceProvider = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <Ab2cInstanceFactory>{children}</Ab2cInstanceFactory>
    </MsalProvider>
  );
};
export default Ab2cInstanceProvider;
