import React from "react";

import styles from "./Button.module.scss";

//! This component will be replaced by an Input in the component library (seperate project). Proof of concept.

interface IProps {
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
  onClick?: () => void;
  className?: string;
  dataTestid?: string;
  visible?: boolean;
  children?: React.ReactNode;
}

const Button = ({
  disabled = false,
  type = "submit",
  onClick,
  className,
  dataTestid,
  visible = true,
  children,
}: IProps) => (
  <button
    className={`${styles.button} ${className}`}
    data-testid={dataTestid}
    disabled={disabled}
    type={type}
    onClick={onClick ? () => onClick() : () => {}}
    hidden={!visible}
  >
    {children}
  </button>
);

export default Button;
