import React, { FocusEventHandler, useState } from "react";
import ReactSelect from "react-select";
import { Option } from "../../../utils/types";
import styles from "./Input.module.scss";
//! This component will be replaced by an Input in the component library (seperate project). Proof of concept.

interface Props {
  name: string;
  id?: string;
  autoComplete?: string;
  value?: Option;
  required?: boolean;
  error?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  children?: React.ReactNode;
  options?: any[] | null;
  placeholder?: string;
}

const Select = ({
  name,
  id = name,
  onBlur,
  onChange,
  value,
  error,
  options,
  placeholder,
}: Props) => {
  const [selectValue, setSelectValue] = useState<Option>();

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      padding: "0.3rem",
      border: "solid 1px #a3a9aa",
      width: "100%",
      borderRadius: "0",
      fontFamily: `sans-serif`,
      fontSize: "0.85rem",
    }),
    input: (styles: any) => ({
      ...styles,
      fontFamily: `sans-serif`,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "#a3a9aa",
      fontFamily: `sans-serif`,
      fontSize: "1rem",
    }),
    option: (styles: any) => ({
      ...styles,
      fontFamily: `sans-serif`,
      fontSize: "0.85rem",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      fontFamily: `sans-serif`,
      fontSize: "0.85rem",
    }),
  };

  const handleChange = (e: any) => {
    setSelectValue(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <ReactSelect
      aria-label={id}
      id={id}
      className={`${error ? styles.isError : ""}`}
      onBlur={onBlur}
      value={value ?? selectValue ?? null}
      onChange={(e: any) => handleChange(e)}
      options={options ?? []}
      placeholder={placeholder}
      styles={customStyles}
    />
  );
};

export default Select;
