import { SelectedShipmentViewModel } from "../types/shipping";

export const shippingRateCost = (
  shipping: SelectedShipmentViewModel
): number => {
  const rate = shipping.rate ?? 0;
  const newRate = shipping.isComplimentary
    ? rate - ((shipping.complimentaryPercentage ?? 100) / 100) * rate
    : rate ?? 0;
  return Math.max(0, newRate);
};
