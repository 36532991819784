import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ReactComponent as IconAddress } from "../../icons/icon-address.svg";
import { ReactComponent as IconPayment } from "../../icons/icon-payment.svg";
import { ReactComponent as IconShipping } from "../../icons/icon-shipping.svg";
import { storageStage } from "../../utils/constants";
import styles from "./tracker.module.scss";
import TrackerLink from "./TrackerLink";

export const Tracker = () => {
  const [stage, setStage] = useState(0);
  const { t } = useTranslation();
  let location = useLocation();

  React.useEffect(() => {
    const localStage = localStorage.getItem(storageStage);
    if (localStage) {
      setStage(+localStage);
    }
  }, [location]);

  const generateClass = (listStage: number) => {
    const done = stage >= listStage ? styles.isDone : "";
    const active = stage === listStage ? styles.isActive : "";

    return `${done} ${active}`;
  };

  return (
    <div className={styles.tracker}>
      <ul>
        <li className={generateClass(1)}>
          <TrackerLink
            component={<IconAddress />}
            name={t("Address")}
            path={t("paths:/delivery-address")}
            isActive={stage === 1}
            isDone={stage > 1}
          />
        </li>
        <li className={generateClass(3)}>
          <TrackerLink
            component={<IconShipping />}
            name={t("Shipping")}
            path={t("paths:/delivery")}
            isActive={stage === 3}
            isDone={stage > 3}
          />
        </li>
        <li className={generateClass(4)}>
          <TrackerLink
            component={<IconPayment />}
            name={t("Payment")}
            path={t("paths:/payment")}
            isActive={stage === 4}
            isDone={stage > 4}
          />
        </li>
      </ul>
    </div>
  );
};

export default Tracker;
