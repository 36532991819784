import React from "react";

interface Props {
  item: any;
}

const ItemNameLink = (props: Props) => {
  const { item } = props;

  // REACT_APP_WEBSITE_HOST has trailing /
  return (
    <a
      href={
        item.url !== ""
          ? item.url
          : `${process.env.REACT_APP_WEBSITE_HOST}goto/${item.ref}/`
      }
      target="_blank"
      rel="noreferrer"
      className="summary-item-link"
    >
      <strong>{item.name}</strong>
    </a>
  );
};

export default ItemNameLink;
