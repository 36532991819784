import {
  PayPalButtons,
  PayPalScriptProvider,
  ReactPayPalScriptOptions,
} from "@paypal/react-paypal-js";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ErrorComponent from "../../../components/Forms/PlaceholderComponents/Error";
import { invokePaymentInfoGA4Event } from "../../../utils/analytics/ga4";
import API from "../../../utils/api";
import { APIS, PAYMENT_STAGE, ZENDESK_URL } from "../../../utils/constants";
import { useStageRedirect } from "../../../utils/hooks";
import { logError, Severity } from "../../../utils/logger";
import { useStateValue } from "../../../utils/state-provider";
import { Stages } from "../../../utils/types";
import { PayPalOrder } from "../../../utils/types/payments/PayPal";
import styles from "./PayPal.module.scss";

const PayPal = () => {
  const { t } = useTranslation();
  const { stageRedirect } = useStageRedirect();

  const [{ data }, dispatch] = useStateValue();
  const [error, setError] = useState<string | null>(null);
  const currencyCode = data.summary.currencyCode;
  const contactUs = ZENDESK_URL + t("paths:/help");

  let summary = data.summary;
  let amount = data.orderBalance;
  useEffect(() => {
    summary = data.summary;
    amount = data.orderBalance;
  }, [data]);

  const capturePayment = async (payPalOrderId: string) => {
    const uri = APIS.paymentCapture.replace("{1}", "PayPal");
    let data = {
      orderId: payPalOrderId,
    };

    try {
      let response = await API.post(uri, data);
      return response;
    } catch (error) {
      logError(
        error,
        "POST PayPal.capturePayment",
        PAYMENT_STAGE,
        Severity.Fatal
      );
    }
  };

  const createOrder = async (): Promise<PayPalOrder | undefined> => {
    try {
      var response: AxiosResponse<PayPalOrder> = await API.post<PayPalOrder>(
        APIS.paymentPayPalCreateOrder
      );
      return response.data;
    } catch (error) {
      logError(error, "POST PayPal.createOrder", PAYMENT_STAGE, Severity.Fatal);
    }
  };

  const options: ReactPayPalScriptOptions = {
    clientId: process.env[
      `REACT_APP_PAYPAL_CLIENT_ID_${currencyCode}`
    ] as string,
    components: "messages,buttons",
    currency: currencyCode,
  };

  return (
    <>
      {!amount && <Skeleton />}
      {amount && (
        <div className={styles.container}>
          <PayPalScriptProvider options={options}>
            <div className={styles.button}>
              <PayPalButtons
                style={{
                  layout: "horizontal",
                  height: 40,
                  tagline: false,
                  shape: "rect",
                }}
                createOrder={async () => {
                  const payPalOrder = await createOrder();
                  invokePaymentInfoGA4Event(
                    summary.items,
                    summary.currencyCode,
                    amount,
                    data.payment.paymentMethod
                  );
                  return payPalOrder?.orderId ?? "";
                }}
                forceReRender={[amount]}
                onApprove={async (data: any) => {
                  let response = await capturePayment(data.orderID);
                  if (response?.status === 200) {
                    dispatch({
                      type: "changeStage",
                      newStage: Stages.Receipt,
                    });
                    stageRedirect(Stages.Receipt, { unsuccessful: false });
                  } else {
                    setError(t("SomethingWentWrong"));
                  }
                }}
              />
            </div>
          </PayPalScriptProvider>
        </div>
      )}
      {error && (
        <div className={styles.errorMessage}>
          <ErrorComponent hasBackground={true} contactUs={contactUs}>
            {error}
          </ErrorComponent>
        </div>
      )}
    </>
  );
};

export default PayPal;
