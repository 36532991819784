import { useElements, useStripe } from "@stripe/react-stripe-js";
import React from "react";
import { PaymentMethod } from "..";
import PaymentListOption from "../../../components/Payment/PaymentListOption";
import { useIsMobilePayAvailable } from "../../../utils/hooks";
import { logError, Severity } from "../../../utils/logger";
import { IAddressResponse } from "../../../utils/types/address";
import BlikForm from "./Blik/BlikForm";
import CardForm from "./Card/CardForm";
import MobilePay from "./Mobile/MobilePay";
import RedirectPayment from "./RedirectingPayments/RedirectPayment";

interface IStripePaymentMethods {
  billingAddress: IAddressResponse;
  onSubmit: React.Dispatch<any>;
  csrfToken: string;
  method: PaymentMethod;
  index: number;
  createPaymentIntent: any;
  selectedPaymentMethod: string | null;
  setSelectedPaymentMethod: (option: string) => void;
}

const StripePaymentMethods = ({
  billingAddress,
  csrfToken,
  method,
  index,
  createPaymentIntent,
  onSubmit,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}: IStripePaymentMethods) => {
  const stripe = useStripe();
  const elements = useElements();
  const { mobilePaymentRequest } = useIsMobilePayAvailable(stripe);

  if (!stripe || !elements) return <></>;

  const stripeMethod = () => {
    if (method.type === "card")
      return (
        <CardForm
          billingAddress={billingAddress}
          csrfToken={csrfToken}
          methods={[method.type]}
          paymentIntentHandler={createPaymentIntent}
          submitHandler={onSubmit}
        />
      );
    else if (method.type === "klarna" || method.type === "afterpay_clearpay") {
      return (
        <RedirectPayment
          methods={[method.type]}
          paymentIntentHandler={createPaymentIntent}
          submitHandler={onSubmit}
          billingAddress={billingAddress}
          stripe={stripe}
          elements={elements}
        />
      );
    } else if (method.type === "blik")
      return (
        <BlikForm
          methods={[method.type]}
          paymentIntentHandler={createPaymentIntent}
          submitHandler={onSubmit}
          billingAddress={billingAddress}
        />
      );
    else if (method.type === "mobile") {
      return (
        <MobilePay
          csrf={csrfToken}
          handler={onSubmit}
          stripe={stripe}
          mobilePaymentRequest={mobilePaymentRequest}
        />
      );
    } else {
      logError(null, "Unhandled payment type", null, Severity.Fatal);
      return <></>;
    }
  };

  //Don't show mobile pay for browsers that do not support google or apple pay
  if (method.type === "mobile" && !mobilePaymentRequest) return <></>;

  return (
    <PaymentListOption
      method={method}
      index={index}
      selectedPaymentMethod={selectedPaymentMethod}
      setSelectedPaymentMethod={setSelectedPaymentMethod}
    >
      {stripeMethod()}
    </PaymentListOption>
  );
};

export default StripePaymentMethods;
