import React, { useEffect } from "react";
import { useStateValue } from "../../../utils/state-provider";
import styles from "./PayPal.module.scss";

const PayPalMessage = () => {
  const [{ data }] = useStateValue();
  const DGCRef = "GFTMWD";
  const containsDigitalGiftCards: boolean = data.summary?.items?.some(
    (i: any) => i.ref === DGCRef
  );
  const currencyCode = data.summary?.currencyCode;
  let amount = data.orderBalance;

  useEffect(() => {
    amount = data.orderBalance;
  }, [data]);

  if (!amount) return null;

  return (
    <>
      {!containsDigitalGiftCards &&
        (currencyCode === "GBP" ||
          currencyCode === "AUD" ||
          currencyCode === "USD") && (
          <div
            className={styles.message}
            data-pp-message
            data-pp-style-layout="text"
            data-pp-style-logo-type="alternative"
            data-pp-placement="payment"
            data-pp-style-text-align="center"
            data-pp-amount={amount.toString()}
          />
        )}
    </>
  );
};

export default PayPalMessage;
