import { Stripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorComponent from "../../../../components/Forms/PlaceholderComponents/Error";
import LoadWidget from "../../../../components/LoadWidget";
import API from "../../../../utils/api";
import { APIS, PAYMENT_STAGE, ZENDESK_URL } from "../../../../utils/constants";
import { useStageRedirect } from "../../../../utils/hooks";
import { logError, Severity } from "../../../../utils/logger";
import { Stages } from "../../../../utils/types";
import { paymentIntentStatus } from "../../../../utils/types/payments";

interface MobilePayProps {
  csrf: string;
  handler: any;
  stripe: Stripe | null;
  mobilePaymentRequest: any;
}

const MobilePay = ({
  csrf,
  handler,
  stripe,
  mobilePaymentRequest,
}: MobilePayProps) => {
  const { stageRedirect } = useStageRedirect();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const contactUs = ZENDESK_URL + t("paths:/help");

  const completePayment = async () => {
    if (!stripe || !mobilePaymentRequest) return;

    mobilePaymentRequest.on("paymentmethod", async function (ev: any) {
      let clientSecret = "";
      try {
        const uri = APIS.paymentIntent.replace("{1}", "Stripe");
        const response = await API.post<paymentIntentStatus>(uri, {
          headers: { "x-csrf-token": csrf },
        });
        clientSecret = response.data.paymentIntentClientSecret;
      } catch (error) {
        logError(
          error,
          "Mobile Pay - API Payment Intent",
          PAYMENT_STAGE,
          Severity.Fatal
        );
        setError(t("SomethingWentWrong"));
      }

      if (!clientSecret) return;

      try {
        const confirmResult = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmResult.error) {
          ev.complete("fail");
          setError(t("SomethingWentWrong"));
        } else {
          ev.complete("success");
          if (confirmResult.paymentIntent.status === "requires_action") {
            const result = await stripe.confirmCardPayment(clientSecret);
            if (result.error) {
              logError(
                result.error,
                "Mobile Pay - requires_action -> stripe.confirmCardPayment",
                PAYMENT_STAGE,
                Severity.Fatal
              );
              setError(t("SomethingWentWrong"));
            } else {
              await processPayment(result.paymentIntent.id);
            }
          } else {
            await processPayment(confirmResult.paymentIntent.id);
          }
        }
      } catch (error) {
        logError(
          error,
          "Mobile Pay - stripe.confirmCardPayment",
          PAYMENT_STAGE,
          Severity.Fatal
        );
        setError(t("SomethingWentWrong"));
      }
    });
  };

  const processPayment = async (id: string) => {
    setIsLoading(true);
    let data = {
      gatewayReference: id,
    };
    const uri = APIS.paymentCapture.replace("{1}", "Stripe");
    await API.post(uri, data);
    stageRedirect(Stages.Receipt);
  };

  useEffect(() => {
    handler(() => {
      return () => handleSubmit();
    });
    completePayment();
  }, [mobilePaymentRequest]);

  const handleSubmit = async () => {
    if (mobilePaymentRequest) {
      mobilePaymentRequest.show();
    }
  };

  return (
    <div>
      {!isLoading ? (
        error && (
          <ErrorComponent hasBackground={true} contactUs={contactUs}>
            {error}
          </ErrorComponent>
        )
      ) : (
        <LoadWidget />
      )}
    </div>
  );
};

export default MobilePay;
