import React from "react";
import { currency } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../utils/state-provider";
import styles from "./Totals.module.scss";

const GiftCardsSummary = () => {
  const { t } = useTranslation();
  const [{ data, profile }] = useStateValue();

  return (
    <>
      {profile && data?.summary?.giftCardsTotal && (
        <div className={styles.prices}>
          <dl>
            <div>
              <dt>
                <strong>{t("GiftCardsTotal")}</strong>
              </dt>
              <dd>
                <strong>
                  {currency(
                    -data?.summary?.giftCardsTotal,
                    data?.summary?.currencyCode,
                    profile.name
                  )}
                </strong>
              </dd>
            </div>
          </dl>
        </div>
      )}
    </>
  );
};

export default GiftCardsSummary;
