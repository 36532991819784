import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { performanceGroup } from "../../../utils/constants";
import { isAnalyticConsented } from "../../../utils/helpers";
import styles from "./Unsupported.module.scss";

const Unsupported = () => {
  useEffect(() => {
    if (isAnalyticConsented(performanceGroup)) {
      ga("send", "pageview", window.location.pathname);
    }
  }, []);

  return (
    <div className={styles.warningContainer}>
      <img
        width="150"
        height="46"
        src="https://img.cdn.mountainwarehouse.com/common/mw-logo-dark.svg?width=150"
        alt="Mountain Warehouse"
      />
      <div className={styles.messageStyle}>
        <img
          src="https://img.cdn.mountainwarehouse.com/common/404-sign-01.svg"
          alt=""
          width="100"
          height="100"
        />
        <Trans i18nKey="UnsupportedPageMessage">
          <b />
        </Trans>
      </div>
    </div>
  );
};

export default Unsupported;
