import React from "react";
import styles from "./ErrorModal.module.scss";
import Button from "../Forms/PlaceholderComponents/Button";
import { useStageRedirect } from "../../utils/hooks";

interface IErrorModal {
  errorMessage: string;
  redirectMessage: string;
  buttonMessage: string;
  redirectUrl: string;
  products?: [] | null;
  onButtonClick?: (() => void) | null;
}
const ErrorModal = ({
  errorMessage,
  redirectMessage,
  buttonMessage,
  redirectUrl,
  products = null,
  onButtonClick = null,
}: IErrorModal) => {
  const { pathRedirect } = useStageRedirect();

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.error}>{errorMessage}</div>
        {products && (
          <div className={styles.product}>
            {products.map((product) => {
              return <div>{product}</div>;
            })}
          </div>
        )}
        <div className={styles.redirect}>{redirectMessage}</div>
        <Button
          onClick={
            onButtonClick
              ? () => onButtonClick()
              : () => pathRedirect(redirectUrl)
          }
        >
          {buttonMessage}
        </Button>
      </div>
    </div>
  );
};

export default ErrorModal;
