import { useField } from "formik";
import React, { ChangeEvent, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import ErrorComponent from "./PlaceholderComponents/Error";
import Input from "./PlaceholderComponents/TextInput";
import styles from "./InputWrap.module.scss";
import Tooltip from "./Tooltip";
import { useValidRef } from "../../utils/helpers";

interface Props {
  name: string;
  id?: string;
  label: string;
  type?: string;
  placeholder?: string;
  autocomplete?: string;
  autoCapitalize?: string;
  autocorrect?: string;
  value?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement | null>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement | null>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement | null>) => void;
  pattern?: string;
  maxlength?: number;
  hidden?: boolean;
  ref?: MutableRefObject<HTMLInputElement | null>;
  tooltipText?: string;
  iconPath?: string;
}

const InputWrap = ({
  name,
  label,
  id = name,
  type = "text",
  placeholder = "",
  autocomplete = "on",
  autoCapitalize = "none",
  autocorrect = "off",
  value,
  required = false,
  onChange,
  onBlur,
  onFocus,
  pattern = "",
  maxlength,
  hidden,
  ref,
  tooltipText,
  iconPath,
}: Props) => {
  const { t } = useTranslation();

  const [field, { error, touched }] = useField({
    name: name,
    type: name,
    value: value,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | null>) => {
    // Call the Formik onBlur event before your custom stuff
    field.onChange(e);

    if (onChange) {
      onChange(e);
    }
  };

  let finalRef = useValidRef(ref);

  return (
    <div className="input-wrap">
      <div className={styles.labelContainer}>
        {required && (
          <label htmlFor={id}>
            {label}
          </label>
        )}
        {!required && (
          <label htmlFor={id}>
            {label} {t("Optional")}
          </label>)
        }
        {tooltipText && <Tooltip text={tooltipText} />}
      </div>

      <Input
        name={field.name}
        type={type}
        id={id}
        pattern={pattern}
        onBlur={onBlur ?? field.onBlur}
        onFocus={onFocus}
        onChange={(e) => handleChange(e)}
        value={field.value}
        placeholder={placeholder}
        autoComplete={autocomplete}
        autoCapitalize={autoCapitalize}
        autocorrect={autocorrect}
        maxlength={maxlength}
        hidden={hidden}
        error={error ? true : false}
        ref={finalRef}
        iconPath={iconPath}
      />

      {error && touched && <ErrorComponent>{error}</ErrorComponent>}
    </div>
  );
};

export default InputWrap;
