if (!Object.fromEntries) {
  Object.defineProperty(Object, "fromEntries", {
    value(entries) {
      if (!entries || !entries[Symbol.iterator]) {
        throw new Error(
          "Object.fromEntries() requires a single iterable argument"
        );
      }

      const o = {};

      Object.keys(entries).forEach((key) => {
        const [k, v] = entries[key];

        o[k] = v;
      });

      return o;
    },
  });
}

if (!Array.prototype.at) {
  // taken from https://github.com/tc39/proposal-relative-indexing-method#polyfill
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, "at", {
    value(n) {
      // ToInteger() abstract op
      n = Math.trunc(n) || 0;
      // Allow negative indexing from the end
      if (n < 0) n += this.length;
      // OOB access is guaranteed to return undefined
      if (n < 0 || n >= this.length) return undefined;
      // Otherwise, this is just normal property access
    },
  });
}
