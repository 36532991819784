import { IShippingOption } from "../types/shipping";

const isMethodEligibleForComplimentary = (
  shipMethodId: number,
  option: IShippingOption
): boolean => {
  const allMethodsEligible =
    (option.complimentaryDetails?.restrictedIds ?? []).length === 0;

  return (
    (option.complimentaryDetails?.available || option.complimentaryAvailable) &&
    (allMethodsEligible ||
      option.complimentaryDetails?.restrictedIds?.includes(shipMethodId) ===
        true)
  );
};

export { isMethodEligibleForComplimentary };
