import React from "react";
import { useTranslation } from "react-i18next";
import ErrorModal from "./Index";

interface IOverBasketThreshold{
  isOverThreshold : boolean
}

const OverBasketThreshold = ({isOverThreshold} : IOverBasketThreshold) => {
  const { t } = useTranslation();

  const redirectToBasket = () => {
    window.location.href =
      process.env.REACT_APP_WEBSITE_HOST + t("paths:/redirectToBasket");
  };

  if(!isOverThreshold) return null;
  
  return (
    <ErrorModal
      errorMessage={t("error:BasketThreshold")}
      redirectMessage={t("RedirectToBasket")}
      buttonMessage={t("GoToBasket")}
      redirectUrl={""}
      products={null}
      onButtonClick={() => redirectToBasket()}
    />
  );
};

export default OverBasketThreshold;
